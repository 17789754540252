import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListagemAreasComponent } from './pages/listagem-areas/listagem-areas.component';
import { FormCadastroAreasComponent } from './pages/form-cadastro-areas/form-cadastro-areas.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NzNotificationModule, NzButtonModule, NzInputModule, NzTableModule, NzIconModule, NzFormModule, NzSelectModule, NzPopoverModule, NzSwitchModule, NzToolTipModule, NzRadioModule, NzModalModule, NzUploadModule, NzListModule, NzPaginationModule, NzDatePickerModule } from 'ng-zorro-antd';
import { CadastroRoutingModule } from './cadastro-routing.module';
import { ListagemPessoasComponent } from './pages/listagem-pessoas/listagem-pessoas.component';
import { FormCadastroPessoasComponent } from './pages/form-cadastro-pessoas/form-cadastro-pessoas.component';
import { ListagemContratosComponent } from './pages/listagem-contratos/listagem-contratos.component';
import { BeneficiaryPaymentsComponent } from './components/beneficiary-payments/beneficiary-payments.component';
import { FormCadastroProjetosComponent } from './pages/form-cadastro-projetos/form-cadastro-projetos.component';
import { ListagemProjetosComponent } from './pages/listagem-projetos/listagem-projetos.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import pt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';

registerLocaleData(pt);
const maskConfigFunction: Partial<IConfig> = {
  thousandSeparator: '.',
  decimalMarker: ',',
};

const currencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [ListagemAreasComponent, FormCadastroAreasComponent, ListagemPessoasComponent, FormCadastroPessoasComponent, ListagemContratosComponent, BeneficiaryPaymentsComponent, FormCadastroProjetosComponent, ListagemProjetosComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzNotificationModule,
    NzDatePickerModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    NzIconModule,
    NzFormModule,
    NzRadioModule,
    NzSwitchModule,
    NzToolTipModule,
    NzSelectModule,
    NzPopoverModule,
    NzModalModule,
    NzUploadModule,
    NzListModule,
    CadastroRoutingModule,
    NzPaginationModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxCurrencyModule.forRoot(currencyMaskConfig),
  ]
})
export class CadastroModule { }
