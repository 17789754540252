import { Component, OnInit } from '@angular/core';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { PermissionService } from 'src/app/shared/services/permission.service';
import Permissions from '../../../../app/shared/enums/permissions.enum';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { PlanningPeriodService } from '../../services/planning-period.service';
import { PlanningService } from '../../services/planning.service';
const FileSaver = require('file-saver');
@Component({
  selector: 'app-planejamento-anual',
  templateUrl: './planejamento-anual.component.html',
  styleUrls: ['./planejamento-anual.component.scss']
})
export class PlanejamentoAnualComponent implements OnInit {
  //não mudar a ordem
  months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio','Junho', 'Julho', 'Agosto','Setembro','Outubro','Novembro','Dezembro']
  //não mudar a ordem
  planning_months = ['planned_january', 'financial_planned_january','planned_february', 'financial_planned_february','planned_march', 'financial_planned_march','planned_april', 'financial_planned_april', 'planned_may', 'financial_planned_may','planned_june', 'financial_planned_june', 'planned_july', 'financial_planned_july', 'planned_august','financial_planned_august','planned_september','financial_planned_september','planned_october','financial_planned_october','planned_november','financial_planned_november','planned_december', 'financial_planned_december']
  constructor(private planningService: PlanningService,
    private planningPeriodService: PlanningPeriodService,
    private permissionService: PermissionService,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private accountingEntryService :AccountingEntryService) { }

  attachment
  listPlanning
  periodoStatus
  year
  planningIsValidated
  runningYear

  counst_centers = []
  nofs = []
  responsibles = []
  providers = []
  coligates = []

  cost_center_filter
  nof_filter
  responsible_filter
  date_filter
  document_number_filter
  observations_filter
  coligate_filter

  query_filters = ''

  panels = [
    {
      active: false,
      name: 'Filtros',
      disabled: false
    }
  ];

  permissionsForPlanning= [
    Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO
  ];

  ngOnInit(): void {
    const hasPermission = this.permissionService.canAny(this.permissionsForPlanning);
    this.getPlanning()
  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  getPlanning() {
    this.planningPeriodService.getRunningPeriod().subscribe( (response: any) => {
      this.runningYear = response.data.year
      this.getFilters()
      this.planningService.getPlanning(this.runningYear, this.query_filters).subscribe( (response: any) => {
        if (response.data.length > 0) {
          this.periodoStatus = response.data[0].planning_period.status_id;
          this.planningIsValidated = response.data[0].planning ? response.data[0].planning.validated : false
          this.year = response.data[0].year;
        }
        this.listPlanning = response.data;
      })
    })
  }

  postPlaning(year) {
    const formData = new FormData();
    formData.append('file', this.attachment[0]);
    this.planningService.uploadPlanning(year, formData).subscribe((response: any) => {
      this.getPlanning()
    });
  }

  downloadPlaning() {
    this.planningService.downloadPlanning(this.year).subscribe((response: any) => {
      const csvName = 'TEMPLATE_PLANEJAMENTO.xlsx';
      FileSaver.saveAs(response, csvName);
    });
  }

  beforeUploadAnexo = (file: any): boolean => {
    if (!file.name.includes('.xlsx')) {
      this.notification.warning("Arquivo não possui formato valido","")
    } else {
      this.attachment = [file];
      this.postPlaning(this.runningYear)
      return false;
    }
  }

  getValueOfPlanningMonth(plannings, month) {
    if (plannings) {
      if (plannings[month] == 0)
        return "-";
      let valorStr = String(plannings[month] * 100)
      let valor = parseInt(valorStr.substring(0, valorStr.length - 2));
      var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 0});
      return formatter.format(valor);
    }
    return '-'
  }

  validatePlaning() {
    this.modal.confirm({
      nzTitle: 'Apos validar não será possivel reverter, Tem certeza que deseja aplicar o planejamento ?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.planningService.validatePlanning(this.runningYear, {}).subscribe( (response: any) => {
          this.getPlanning()
        })
      },
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  getFilters() {
    this.accountingEntryService.getHierarchyColigates(this.runningYear).subscribe( (response : any) => {
      this.coligates = response.data;
    })

    this.accountingEntryService.getHierarchyCostCenters(this.runningYear).subscribe( (response : any) => {
      this.counst_centers = response.data;
    })

    this.accountingEntryService.getHierarchyNofs(this.runningYear).subscribe( (response : any) => {
      this.nofs = response.data;
    })

    this.accountingEntryService.getHierarchyResponsibles(this.runningYear).subscribe( (response : any) => {
      this.responsibles = response.data;
    })
  }

  filter() {
    this.query_filters = ''

    if (this.cost_center_filter) {
      this.query_filters += '&cost_center='+this.cost_center_filter
    }

    if (this.nof_filter) {
      this.query_filters += '&nof='+this.nof_filter
    }

    if (this.responsible_filter) {
      this.query_filters += '&responsible='+this.responsible_filter
    }

    if (this.coligate_filter) {
      this.query_filters += '&colligate='+this.coligate_filter
    }

    this.getPlanning()
  }

  clearFilter() {
    this.query_filters = ''
    this.cost_center_filter = undefined
    this.nof_filter = undefined
    this.responsible_filter = undefined
    this.coligate_filter = undefined

    this.getPlanning()
  }
}
