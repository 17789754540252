import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { FormGroup } from '@angular/forms';
import { PessoasService } from '../../services/pessoas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterPersonComponent } from 'src/app/shared/components/register-person/register-person.component';
import * as moment from 'moment';
moment.locale('pt-br');

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-cadastro-pessoas',
  templateUrl: './form-cadastro-pessoas.component.html',
  styleUrls: ['./form-cadastro-pessoas.component.scss']
})
export class FormCadastroPessoasComponent implements OnInit {
  @ViewChild('registerPersonComponent', {static: true}) registerPersonComponent: RegisterPersonComponent;
  form: FormGroup;
  action = '';

  constructor(private service: PessoasService,
              private route: ActivatedRoute,
              private router: Router,
              private notification: NzNotificationService) {
  }

  ngOnInit(): void {
    const urlParams = this.getParamsUrl(this.route);
    const userId = urlParams.id;
    if (urlParams.urlSegment) {
      /*
      if (urlParams.urlSegment.path === 'view') {
        this.registerPersonComponent.visible = true;
      } else {
        this.registerPersonComponent.visible = false;
      }
      */

    }
    if (urlParams.id && urlParams.urlSegment.path === 'view') {
      this.action = '';
    } else if (urlParams.id && urlParams.urlSegment.path !== 'view') {
      this.action = '';
    } else {
      this.action = 'Cadastrar nova';
    }

    /*
    if (userId) {
      this.service.GetOnlyPerson(userId).subscribe((response: any) => {
        setTimeout(() => {
          this.registerPersonComponent.setValues(response);
        });
      });
    }
    */
  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;

    route.params.subscribe(params => {
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

  OnSubmit() {
    /*
    if (!this.form.invalid && this.registerPersonComponent.validateConjunge()) {
      const result = this.form.getRawValue();
      if (result.address.complement == null) {
        delete result.address.complement;
      }
      delete result.person.isalive;
      delete result.person.isdeath;

      if (result.person.deceased === '1') {
        result.person.deceased = true;
      } else {
        result.person.deceased = false;
      }
      const dateTypeCorrect = moment(result.person.birth_date.replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD');
      if (result.person.deceased_date != null && result.person.deceased === true) {
        result.person.deceased_date = moment(result.person.deceased_date.replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD');
      } else {
        delete result.person.deceased_date;
      }
      result.person.birth_date = dateTypeCorrect;

      if (result.person.marital_status_id === 3) {
        result.person.spouse_id = null;
      }

      if (result.person.spouse_id == null) {
        delete result.person.spouse_id;
      }
      if (this.registerPersonComponent.id) {
        this.service.PutPerson(this.registerPersonComponent.id, result).subscribe((response: any) => {
          if (response.success === true) {
            this.router.navigateByUrl('/cadastro/listagem-pessoas');
          } else {
            this.notification.blank(response.data, '');
          }
        });
      } else {
        this.service.PostPerson(result).subscribe((response: any) => {
          if (response.success === true) {
            this.router.navigateByUrl('/cadastro/listagem-pessoas');
          } else {
            this.notification.blank(response.data, '');
          }
        });
      }
    } else {
      if (this.form.invalid) {
        this.notification.error('Confira os campos obrigatórios', '');
      }
      Object.keys(this.form.controls).forEach(key => {
        this.registerPersonComponent.validateForms(key);
      });
    }
  */
  }
}
