import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjetosService {

  constructor(private http: HttpClient, private utils: UtilsService) {}


  GetProjects(page, filter?) {
    return this.http.get(environment.baseApiUrl + 'project/' + this.utils.getQueryString({ ...page, ...filter }));
  }

  GetProject(id) {
    return this.http.get(environment.baseApiUrl + 'project/' + id);
  }

  CreateProject(data) {
    return this.http.post(environment.baseApiUrl + 'project/create', data);
  }

  UpdateProject(data, id) {
    return this.http.put(environment.baseApiUrl + 'project/' + id, data);
  }

  DeleteProject(id) {
    return this.http.delete(environment.baseApiUrl + 'project/' + id);
  }


}
