import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { AreasService } from '../../services/areas.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
import { ComboService } from 'src/app/shared/services/combo.service';
import * as moment from 'moment';
import { AuthState } from 'src/app/auth/auth.reducer';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { ProjetosService } from '../../services/projetos.service';
import { radioRequiredValidator, requiredIfParentIfdiferentFromNull,  } from '../../../shared/validators/customvalidator.validator';
import { requiredChildPreOperationRemunerationValidator, requiredChildCommercialOperationRemunerationValidator } from 'src/app/solicitacao/validators/customvalidator.validator';
import { ReasonNewContractType, RemunerationPreOperationType, RemunerationCommercialOperationType, ApportionmentType } from 'src/app/solicitacao/enums/contract.enum';
import { ComboFrequency, ComboInflationIndex, ComboRemunerationCommercialOperation, ComboRemunerationPreOperation } from 'src/app/shared/models/combo.model';


@Component({
  selector: 'app-form-cadastro-projetos',
  templateUrl: './form-cadastro-projetos.component.html',
  styleUrls: ['./form-cadastro-projetos.component.scss']
})
export class FormCadastroProjetosComponent implements OnInit {


  form: FormGroup;
  id:any;
  loggedUserId: number;

  nome_projeto = this.fb.control(null, [Validators.required]);
  contract_deadline = this.fb.control(null, [Validators.required]);
  inflationIndex =this.fb.control(null, [Validators.required]);
  remuneration_type_prazo = this.fb.control(null, [Validators.required]);
  frequency_prazo = this.fb.control(null, null);
  value_per_period = this.fb.control(null, null);
  value_per_hectare_prazo = this.fb.control(null, null);
  remuneration_anemometric_tower = this.fb.control(null, null);
  frequency_anemometric_tower = this.fb.control(null, [Validators.required]);
  value_per_anemometric_tower = this.fb.control(null, null);
  observation = this.fb.control(null, null);
  remuneration_type = this.fb.control(null, [Validators.required]);
  frequency = this.fb.control(null, null);
  value_per_wind_generator = this.fb.control(null, null);
  percentage_over_generation = this.fb.control(null, null);
  value_per_hectare = this.fb.control(null, null);
  observation1 = this.fb.control(null, null);

  remunerationPreOperationType = RemunerationPreOperationType;
  remunerationCommercialOperationType = RemunerationCommercialOperationType;
  listRemunerationPreOperationType: Array<ComboRemunerationPreOperation>;
  listInflationIndex: Array<ComboInflationIndex>;
  listFrequencyType: Array<ComboFrequency>;
  listRemunerationCommercialOperationType: Array<ComboRemunerationCommercialOperation>;

  listOfOption: { label: string; value: string; }[];
  inputValue: string | null = null;
  textValue: string | null = null;
  listProjects: any;
  listAreaType: any;

  visible = false;
  action: string;
  result: any;

  showUser$ = this.state.pipe(select(selectAuthUser));


  bloquearTudo = false;

  constructor(private service: ProjetosService,
              private fb: FormBuilder,
              private state: Store<AuthState>,
              private sanitizer: DomSanitizer,
              private router: Router,
              private notification: NzNotificationService,
              private serviceShared: ComboService,
              private route: ActivatedRoute) {

              this.form = this.fb.group({

                business_conditions: this.fb.group({
                  desc: this.fb.control(null, [Validators.required]),
                  inflationIndex: this.fb.control(null, [Validators.required]),
                  pre_operation: this.fb.group({
                    remuneration_type: this.fb.control(null, [Validators.required]),
                    contract_deadline: this.fb.control(null, [Validators.required]),
                    frequency: this.fb.control(null, [Validators.required]),
                    value_per_period: this.fb.control(null, [requiredChildPreOperationRemunerationValidator('value_per_period')]),
                    value_per_hectare: this.fb.control(null, [requiredChildPreOperationRemunerationValidator('value_per_hectare')]),
                    remuneration_anemometric_tower: this.fb.control(false, [Validators.required]),
                    value_per_anemometric_tower: this.fb.control(null, [radioRequiredValidator('remuneration_anemometric_tower', true)]),
                    frequency_anemometric_tower: this.fb.control(null, [radioRequiredValidator('remuneration_anemometric_tower', true)]),
                  }),
                  commercial_operation: this.fb.group({
                    remuneration_type: this.fb.control(null, [Validators.required]),
                    // tslint:disable-next-line: max-line-length
                    value_per_wind_generator: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('value_per_wind_generator')]),
                    // tslint:disable-next-line: max-line-length
                    percentage_over_generation: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('percentage_over_generation')]),
                    value_per_hectare: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('value_per_hectare')]),
                    frequency: this.fb.control(null, [Validators.required]),
                  }),
                }),

            });
          }


  ngOnInit(): void
  {
    const urlParams = this.getUrlSendId(this.route);
    const id = urlParams[0];
    this.id = id;


    if (urlParams[1]) {

      if (urlParams[0] && urlParams[1].path === 'view') {
        this.action = 'Visualizar';
        this.form.disable();
        this.visible = true;
      }
      if (urlParams[0] && urlParams[1].path !== 'view') {
        this.action = 'Editar';
      }
    }
    else
    {
      this.action = 'Cadastrar Novo';
    }

    this.onChanges();
    this.showUser$.subscribe((user: any) => {
      this.loggedUserId = user.id;
      this.loadCombos();
    });

    if(this.id)
    {
      this.service.GetProject(this.id).subscribe((response: any) => {
        this.setFormData(response.data);
      })
    }

  }

  public getUrlSendId(route: ActivatedRoute) {
    let id;
    let operation;

    route.params.subscribe(params => {
      id = params['id'];
    });

    operation = route.url['_value'][1];

    return [id, operation];
  }

  get business_conditions(): AbstractControl {
    return this.form.get('business_conditions');
  }

  get pre_operation(): AbstractControl {
    return this.business_conditions.get('pre_operation');
  }

  get commercial_operation(): AbstractControl {
    return this.business_conditions.get('commercial_operation');
  }

  get approval(): AbstractControl{
    return this.business_conditions.get('aprovacao');
  }

  get area_list(): FormArray {
    return this.property_information.get('area_list') as FormArray;
  }

  get property_information(): AbstractControl {
    return this.form.get('property_information');
  }


  validateFormGroup(formGroup: AbstractControl): void {
    for (const fieldName in formGroup['controls']) {
      if (formGroup.get(fieldName).value == null) {
        formGroup.get(fieldName).markAsDirty();
        formGroup.get(fieldName).updateValueAndValidity();
      }
    }

    this.listInvalidControls(formGroup);

  }

  listInvalidControls(form: AbstractControl): void {
    const invalid: string[] = [];
    Object.keys(form['controls']).forEach((key: string) => {
      if (form.get(key).invalid) {
        invalid.push(key);

      }
    })
  }

  mapPayloadCreateRequisition(data) {
    try {
      const response = {
        desc:data.business_conditions.desc,
        trc_id: data.business_conditions.pre_operation.remuneration_type,
        prj_prazo_anos: Number(data.business_conditions.pre_operation.contract_deadline),
        per_id_valor: data.business_conditions.pre_operation.frequency,
        prj_remun_por_torre_anemometrica: data.business_conditions.pre_operation.remuneration_anemometric_tower,
        prj_valor_por_torre_anenometrica: data.business_conditions.pre_operation.value_per_anemometric_tower != null ? Number(data.business_conditions.pre_operation.value_per_anemometric_tower) : null,
        per_id_torre_anemometrica: data.business_conditions.pre_operation.frequency_anemometric_tower,
        prj_valor: Number(data.business_conditions.pre_operation.value_per_period ? data.business_conditions.pre_operation.value_per_period : data.business_conditions.pre_operation.value_per_hectare),

        tra_id: data.business_conditions.commercial_operation.remuneration_type,
        prj_valor_aerogerador: Number(data.business_conditions.commercial_operation.value_per_wind_generator),
        per_id_aerogerador: data.business_conditions.commercial_operation.frequency,
        prj_porc_sobre_geracao: Number(this.toFloat(data.business_conditions.commercial_operation.percentage_over_generation)),
        prj_valor_ha: Number(data.business_conditions.commercial_operation.value_per_hectare),
        tdc_id: data.business_conditions.inflationIndex,

      };

      return response;
    } catch (error) {
      this.notification.error('Um erro ocorreu', 'Verifique todos os campos do formulario.');
    }
  }


  formatNumberBR(value: number | string) : string {
    if (value != null &&  isNaN(Number(value)) == false )  {
      return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
    return "";
  }



  toFloat(number) {
    if (number && typeof(number) == "string") {
      if (number.indexOf(",") > 0)
        return parseFloat(number.replace(".","").replace(",","."))
      else
        return parseFloat(number)
    }

    if (number)
      return parseFloat(number);
    return number;
  }

  setFormData(data) {

    this.business_conditions.get('desc').setValue(data.desc);
    this.business_conditions.get('inflationIndex').setValue(data.tdc_id);
    this.pre_operation.get('remuneration_type').setValue(data.trc_id);
    this.pre_operation.get('frequency').setValue(data.per_id_valor);
    this.pre_operation.get('contract_deadline').setValue(data.prj_prazo_anos);
    this.pre_operation.get('remuneration_anemometric_tower').setValue(data.prj_remun_por_torre_anemometrica);
    this.pre_operation.get('value_per_anemometric_tower').setValue((data.prj_valor_por_torre_anenometrica));
    this.pre_operation.get('frequency_anemometric_tower').setValue(data.per_id_torre_anemometrica);
    this.pre_operation.get('value_per_period').setValue((data.prj_valor));
    this.pre_operation.get('value_per_hectare').setValue((data.prj_valor));

    this.commercial_operation.get('remuneration_type').setValue(data.tra_id);
    this.commercial_operation.get('value_per_wind_generator').setValue((data.prj_valor_aerogerador));
    this.commercial_operation.get('percentage_over_generation').setValue(this.formatNumberBR(data.prj_porc_sobre_geracao));
    this.commercial_operation.get('value_per_hectare').setValue((data.prj_valor_ha));
    this.commercial_operation.get('frequency').setValue(data.per_id_aerogerador);
  }

  loadCombos(): void {

    this.serviceShared.ComboRemunerationPreOperation().subscribe((response: any) => this.listRemunerationPreOperationType = response.data);
    this.serviceShared.ComboInflationIndex().subscribe((response: any) => this.listInflationIndex = response.data);
    this.serviceShared.ComboFrequency().subscribe((response: any) => this.listFrequencyType = response.data);
    // tslint:disable-next-line: max-line-length
    this.serviceShared.ComboRemunerationCommercialOperation().subscribe((response: any) => this.listRemunerationCommercialOperationType = response.data);
  }

  onChanges(): void {
    this.pre_operation.get('remuneration_type').valueChanges.subscribe(val => {
      this.pre_operation.get('value_per_period').setValue(null);
      this.pre_operation.get('value_per_hectare').setValue(null);
    });

    this.commercial_operation.get('remuneration_type').valueChanges.subscribe(val => {
      this.commercial_operation.get('value_per_wind_generator').setValue(null);
      this.commercial_operation.get('percentage_over_generation').setValue("0,00");
      this.commercial_operation.get('value_per_hectare').setValue(null);
    });
  }

  formIsValid(): boolean {
    if (
      this.pre_operation.invalid ||
      this.commercial_operation.invalid
      ) {
      this.validateFormGroup(this.pre_operation);
      this.validateFormGroup(this.commercial_operation);
      this.validateFormGroup(this.business_conditions);
      return false;
    } else {
      return true;
    }
  }


  back(): void {
    setTimeout(() => {
      this.router.navigate(['/cadastro/listagem-projetos']);
    });
  }


  OnSubmit()
  {


    if (this.formIsValid())
    {
      const data = this.mapPayloadCreateRequisition(this.form.getRawValue());

      if (this.id)
      {
        this.service.UpdateProject(data, this.id).subscribe({
          next:(data) => {
            this.back();
          },
          error:(err) => {
            console.log(err)
          },
        })
      }
      else
      {
        this.service.CreateProject(data).subscribe({
          next:(data) => {
            this.back();
          },
          error:(err) => {
            console.log(err)
          },
        })
      }

    }
    else
    {
      this.notification.error('Campos obrigatórios pendentes de preenchimento.', '');
    }
  }

}
