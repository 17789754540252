import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
moment.locale('pt-br');
const FileSaver = require('file-saver');

@Component({
  selector: 'app-aditivo-contratual',
  templateUrl: './aditivo-contratual.component.html',
  styleUrls: ['./aditivo-contratual.component.scss']
})
export class AditivoContratualComponent implements OnInit {

  blockForm = false;
  isVisible = false;
  requisitionid

  form: FormGroup;
  previousPage = '';

  previewVisible: boolean = false;


  constructor(private dialogService: DialogService,
              private router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute) {

    this.form = this.fb.group({});

    const urlParams = this.getParamsUrl(this.route);
    this.requisitionid = urlParams.id;

    if (urlParams.urlSegment && urlParams.urlSegment.path === 'view') {
      this.blockForm = true;
      this.isVisible = true;
    }

    if(this.requisitionid === undefined)
    {
      this.router.navigate(['/cadastro/listagem-contratos']);
    }
  }

  ngOnInit(): void {
    //this.GetCombos();
  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;

    route.params.subscribe(params => {
      if (history.state.previousPage) {
        this.previousPage = history.state.previousPage
      }
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }


}
