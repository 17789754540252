import { Component, EventEmitter, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { AreasService } from 'src/app/cadastro/services/areas.service';
import { ComboService } from '../../services/combo.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-register-area',
  templateUrl: './register-area.component.html',
  styleUrls: ['./register-area.component.scss']
})
export class RegisterAreaComponent implements OnInit {
  //@Input() data: any = { };
  @Input() form: FormGroup;
  @Input() aditivo:boolean = false;
  //@Input() visible = false;
  //@Output() dataChange: EventEmitter<FormGroup> = new EventEmitter();
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter();

  //formDefault: FormGroup;
  //blockInputs = true;

  listSituation: any;
  listCompetitor: any;
  listAreaStates: any;
  listAreaCity: any;
  listTypeDocument = [];

  hasGravame = false;
  hasCompetitor = false;
  constructor(private service: AreasService,
              private fb: FormBuilder,
              private msg: NzMessageService,
              private notification: NzNotificationService,
              private serviceShared: ComboService,
              private utilsService: UtilsService) {

  }

  /*
  ngAfterViewInit(): void {
    this.functionsChangesForm();
  }
  */

  ngOnInit(): void {
    this.getCombos();


    /*
    if (!this.form) {
      this.form = this.formDefault;
    }
    */

    /*
    setTimeout(() => {
      this.formChange.emit(this.form);
      this.inicialize = true;
    });
    */

  }

  /*
  functionsChangesForm() {
    this.form.valueChanges.subscribe(() => {
      this.formChange.emit(this.form);
    });
  }
  */


  /*      state_area_id: this.fb.control(data.state_area_id, [Validators.required]),
      city_area_id: this.fb.control(data.city_area_id, [Validators.required]),
*/

  getCombos() {
    this.serviceShared.ComboAreaSituation().subscribe((response: any) => {
      this.listSituation = response.data;
    });

    this.serviceShared.ComboCompetitor().subscribe((response: any) => {
      this.listCompetitor = response.data;
    });

    this.serviceShared.ComboDocImovel().subscribe((response: any) => {
      this.listTypeDocument = response.data;
    });

    this.serviceShared.ComboState().subscribe((response: any) => {
      // console.log(response.data);
      this.listAreaStates = response.data;
      this.searchState(this.form.controls.state_area_id.value);
    });

  }

  searchState(id) {
    if (!id) {
      return;
    }
    this.serviceShared.ComboCity(id).subscribe((res: any) => {
      this.listAreaCity = res.data;
    });
  }

  public resetForm() {
    this.form.reset();
  }





  public setCompetitor(data) {
    this.hasCompetitor = data;
  }


  public setGravame(data) {
    this.hasGravame = data;
  }



  _beforeUploadAnexo = (file: any, tipo:string): boolean => {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

        if(tipo == 'ccir') {
          this.form.controls['attachment_last_issue_text'].setValue(reader.result.toString());
        } else
        if(tipo == 'property') {
          this.form.controls['attachment_property_text'].setValue(reader.result.toString());
        } else
        if(tipo == 'nirf') {
          this.form.controls['attachment_last_decl_text'].setValue(reader.result.toString());
        }

    };

   return false;
  }


  beforeUploadNirf = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'nirf');
  }

  beforeUploadProperty = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'property');
  }

  beforeUploadCcir = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'ccir');
  }



  listTypeDocumentFiltered(filter) {
    if (filter == 1) {
      return this.listTypeDocument.filter(x => x.id == 1);
    } else {
      return this.listTypeDocument
    }
  }

  GetDocumento(id, tipo) {
    var retorno = null;

    if(tipo == 'ultima-emissao-area') {
      retorno = this.form.controls['attachment_last_issue_text'].value;
    } else
    if(tipo == 'documento-area') {
      retorno = this.form.controls['attachment_property_text'].value;
    } else
    if(tipo == 'ultima-declaracao-area') {
      retorno = this.form.controls['attachment_last_decl_text'].value;
    }

    if (retorno) {
          
      const downloadLink = document.createElement('a');
      const fileName = tipo;
      downloadLink.href = retorno;
      downloadLink.download = fileName;
      downloadLink.click();

    } else if (id) {
      this.utilsService.GetDocumento(id, tipo).subscribe((res: any) => {
        let data = res.data

        if (data) {
          const downloadLink = document.createElement('a');
          const fileName = data.nome_arquivo;

          downloadLink.href = data.base64_arquivo;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
    }

  }

}
