import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getAllContracts(page, filter: any) {
    return this.http.get(environment.baseApiUrl + 'contract/' + this.utils.getQueryString({ ...page, ...filter }));
  }

  getDetails(id) {
    return this.http.get(environment.baseApiUrl + 'contract/' + id);
  }


  startBilling(contract) {
    return this.http.put(environment.baseApiUrl + 'contract/startBilling', contract);
  }

  endBilling(contract) {
    return this.http.put(environment.baseApiUrl + 'contract/endBilling', contract);
  }


  createContract(contract) {
    return this.http.post(environment.baseApiUrl + 'contract', contract);
  }

  postUpload(file) {
    return this.http.post(environment.baseApiUrl + 'file/upload', file);
  }

  dowloadCSV() {
    return this.http.get(environment.baseApiUrl + 'contract/report', { responseType: 'blob' });
  }

  downloadContract(id) {
    return this.http.get(environment.baseApiUrl + 'contract/' + id + '/report', { responseType: 'blob' });
  }
}
