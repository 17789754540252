import { AbstractControl, Validators, ValidationErrors, ValidatorFn, FormArray } from "@angular/forms";
import { ReasonNewContractType, RemunerationPreOperationType, RemunerationCommercialOperationType, ApportionmentType } from "../enums/contract.enum";
import { MaritalType } from 'src/app/shared/enums/person.enum';

// Validator sem parâmetros Adicionais
export function reasonRequiredValidator(control: AbstractControl) {
    if (!control.parent) {
        return null;
    }

    if (control.parent.get('reason_new_contract').value == ReasonNewContractType.Outros && !control.value) {
        return { required: true }; 
    }

    return null;
}


// Validator com parâmetros adicionais
// Caso for necessário passar parâmetros adicionais para o validator, é necessário criar uma função normal com os parâmetros que precisa e que retorne uma função Validator
export function requiredChildPreOperationRemunerationValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (fieldName == 'value_per_period') {
            if (control.parent.get('remuneration_type').value == RemunerationPreOperationType.ValorFixoPorPeriodo && !control.value) {
                return { required: true }; 
            }
        } else if (fieldName == 'value_per_hectare'){
            if (control.parent.get('remuneration_type').value == RemunerationPreOperationType.ValorPorHectare && !control.value) {
                return { required: true }; 
            }
        }

        return null;
    }
}

export function requiredChildCommercialOperationRemunerationValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if(fieldName == 'value_per_wind_generator') {
            if ((control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.FixaParaAerogerador || control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.FixaParaAerogeradorValorPorHectare) && !control.value) {
                return { required: true };
            }
        } else if (fieldName == 'percentage_over_generation') {
            if (control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.RelativaGeracao && !control.value) {
                return { required: true };
            }
        } else if (fieldName == 'value_per_hectare') {
            if ((control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.FixaParaAerogeradorValorPorHectare) && !control.value) {
                return { required: true };
            }
        } else if (fieldName == 'frequency') {
            if ((control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.FixaParaAerogerador || control.parent.get('remuneration_type').value == RemunerationCommercialOperationType.RelativaGeracao) && !control.value) {
                return { required: true };
            }
        }

        return null;
    }
}

export function maritalRequiredValidator(conditionValue: MaritalType): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (control.parent.parent.get('person').get('marital_status_id').value == conditionValue && !control.value) {
            return { required: true }; 
        }

        return null;
    }
}

export function partnerDocumentsRequiredValidator(control: AbstractControl) {
    if (!control.parent) {
        return null;
    }

    if (control.parent.parent.get('person').get('spouse_id').value && !control.value) {
        return { required: true }; 
    }

    return null;
}

export function heirListRequiredValidator(control: FormArray) {
    if (!control.parent) {
        return null;
    }

    if (control.parent.parent.get('general').get('heritage').value == true && control.value.length == 0) {
        return { required: true }; 
    }

    return null;
}

export function ownerRequiredInHeirListValidator(control: FormArray) {
    if (!control.parent) {
        return null;
    }

    let selectedOwnerList = control.parent.parent.parent.parent.parent.parent.get('general')['controls']['owner_list'].getRawValue();
    let heirId = control.parent.parent.parent.get('heir').get('person').get('id').value;

    let heirIsOwner = selectedOwnerList.some((selectedOwner) => {
        return selectedOwner.person.id == heirId;
    });

    if(!heirIsOwner && !control.value) {
        return { required: true };
    }

    return null;
}

export function beneficiaryListValidator(control: FormArray) {
    if (!control.parent) {
        return null;
    }

    const beneficiaryList = control.getRawValue();
    const contractValue = control.parent.get('total_value');

    if (beneficiaryList.length && !control.parent.get('beneficiary_equal_apportionment').value) {
        const total = beneficiaryList.reduce((sum, item) => {
            const values = {
                totalValue: sum.totalValue + parseFloat(item.apportionment_value),
                totalPercent: sum.totalPercent + parseFloat(item.apportionment_percent)
            };

            return values;
        }, { totalValue: 0, totalPercent: 0 });

        if (control.parent.get('beneficiary_apportionment_type').value === ApportionmentType.Porcentagem) {
            if (total.totalPercent !== 100) {
                return { invalidPercent: true };
            }
        } else {
            if (total.totalValue !== contractValue) {
                return { invalidValue: true };
            }
        }
    }

    return null;
}
export function beneficiaryApportionmentListValidator(control: FormArray) {
    if (!control.parent) {
        return null;
    }

    let preOperationContractValue = control.parent.parent.parent.get('business_conditions').get('pre_operation').get('pre_operation_contract_value').value;
    let beneficiaryApportionmentList = control.getRawValue();

    if (beneficiaryApportionmentList.length && control.parent.parent.get('payment_information').get('equal_apportionment').value == false) {
        let total = beneficiaryApportionmentList.reduce((sum, item) => {
            let total = {
                totalValue: sum.totalValue + parseFloat(item.apportionment_value), 
                totalPercent: sum.totalPercent + parseFloat(item.apportionment_percent)
            }

            return total;
        }, {totalValue: 0, totalPercent: 0});

        if(control.parent.parent.get('payment_information').get('apportionment').value == ApportionmentType.Porcentagem) {
            if(total.totalPercent != 100) {
                return { invalidPercent: true };
            }
        } else {
            if(total.totalValue != preOperationContractValue) {
                return { invalidValue: true };
            }
        }
    }

    return null;
}

export function requiredChildHasCompetitorValidator(control: AbstractControl) {
    if (!control.parent) {
        return null;
    }
    if (control.parent.get('has_competition').value == true && !control.value) {
        return { required: true }; 
    }
}

export function requiredChild(control: AbstractControl) {
    if (!control.parent) {
        return null;
    }
    if (control.parent.get('correct_documentation').value == false && control.value == null) {
        return { required: true }; 
    }
}

export function requiredFile(control: AbstractControl) {
    if (!control.parent) {
        return null;
    }
    if (control.parent.get('approved').value == false) {
        return { required: true }; 
    }
}