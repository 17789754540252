import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators,  } from '@angular/forms';
import { AreasService } from '../../services/areas.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
import { ComboService } from 'src/app/shared/services/combo.service';
import * as moment from 'moment';
import { RegisterAreaComponent } from 'src/app/shared/components/register-area/register-area.component';
import { radioRequiredValidator, requiredIfParentIfdiferentFromNull } from '../../../shared/validators/customvalidator.validator';

@Component({
  selector: 'app-form-cadastro-areas',
  templateUrl: './form-cadastro-areas.component.html',
  styleUrls: ['./form-cadastro-areas.component.scss']
})
export class FormCadastroAreasComponent implements OnInit {

  @ViewChild('registerAreaComponent', { static: true }) registerAreaComponent: RegisterAreaComponent;

  dataRegisterArea = {
    areaId: null,
    previewVisible: false,
    preview: '',
  };

  public iframeMaps: any;
  public linkMaps: any;
  showList = false;
  previewImage: string | undefined = '';

  fileMaps: any;
  form: FormGroup;
  id:any;

  prj_id = this.fb.control(null, [Validators.required]);
  are_codigo = this.fb.control(null, [Validators.required]);
  are_nome = this.fb.control(null, [Validators.required]);
  are_link_maps = this.fb.control(null, null);
  are_nome_doc_aprovacao = this.fb.control(null, null);
  are_doc_aprovado = this.fb.control(null, null);
  contrato_ativo = this.fb.control(null, null);
  sta_id = this.fb.control(null, [Validators.required]);
  arq_imagem = this.fb.control(null, null);
  are_observacao = this.fb.control(null, null);

  listAreaByCode = [];
  listOfStatus = [];
  fileListArea = [];
  fileDoc = [];

  listOfOption: { label: string; value: string; }[];
  inputValue: string | null = null;
  textValue: string | null = null;
  listProjects: any;
  listAreaType: any;

  urlImage: any;
  filename;
  visible = false;
  action: string;
  showArea = false;
  codeArea = '';
  result: any;

  bloquearTudo = false;

  constructor(private service: AreasService,
              private fb: FormBuilder,
              private sanitizer: DomSanitizer,
              private router: Router,
              private notification: NzNotificationService,
              private serviceShared: ComboService,
              private route: ActivatedRoute) {

    this.form = this.fb.group({
      prj_id: this.prj_id,
      are_codigo: this.are_codigo,
      are_nome: this.are_nome,
      are_link_maps: this.are_link_maps,
      are_doc_aprovado: this.are_doc_aprovado,
      are_nome_doc_aprovacao: this.are_nome_doc_aprovacao,
      sta_id: this.sta_id,
      contrato_ativo: this.contrato_ativo,
      arq_imagem: this.arq_imagem,
      are_observacao: this.are_observacao
    });

  }


  ngOnInit(): void
  {
    const urlParams = this.getUrlSendId(this.route);
    const id = urlParams[0];
    this.id = id;

    if (urlParams[1]) {

      if (urlParams[0] && urlParams[1].path === 'view') {
        this.action = 'Visualizar';
        this.form.disable();
        this.visible = true;
      }
      if (urlParams[0] && urlParams[1].path !== 'view') {
        this.action = 'Editar';
      }
    }
    else
    {
      this.action = 'Cadastrar Nova';
    }

    if (id)
    {
      this.dataRegisterArea.areaId = id;
      this.service.GetOnlyOneArea(id).subscribe((response: any) => {
        this.SetValues(response);
      });
    }

    this.serviceShared.ComboProject().subscribe((response: any) => {
      this.listProjects = response.data;
    });

    this.service.GetComboStatus().subscribe((response:any) => {
      this.listOfStatus = response.data;
    })
  }

  public getUrlSendId(route: ActivatedRoute) {
    let id;
    let operation;

    route.params.subscribe(params => {
      id = params['id'];
    });

    operation = route.url['_value'][1];

    return [id, operation];
  }

  receiverAreaCodes(event) {
    this.listAreaByCode = event;
  }


  SetValues(response) {
    const area = response.data;
    this.form.controls.prj_id.setValue(area.prj_id);
    this.form.controls.are_codigo.setValue(area.are_codigo);
    this.form.controls.are_nome.setValue(area.are_nome);
    this.form.controls.are_link_maps.setValue(area.are_link_maps);
    this.form.controls.are_doc_aprovado.setValue(area.are_doc_aprovado);
    this.form.controls.are_nome_doc_aprovacao.setValue(area.are_nome_doc_aprovacao);
    this.form.controls.sta_id.setValue(area.sta_id);
    this.form.controls.arq_imagem.setValue(area.arq_imagem);
    this.form.controls.contrato_ativo.setValue(area.contrato_ativo);
    this.form.controls.are_observacao.setValue(area.are_observacao);

  }

  setMaps(link) {
    this.iframeMaps = link;
    this.linkMaps = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }


  createFilePayload(base64 : string | null, dadosArquivo: any) : any {
    if (base64 == null)
      return null;

    var temp = base64.split(",");

    var tempExt = temp[0].replace("data:","").replace(";base64","");
    var ext = "";
    var file_id = null

    if (tempExt.indexOf("png") >= 0)
      ext = ".png"
    else if (tempExt.indexOf("jpeg") >= 0)
      ext = ".jpeg"
    else if (tempExt.indexOf("jpg") >= 0)
      ext = ".jpg"
    else if (tempExt.indexOf("pdf") >= 0)
      ext = ".pdf"

      if (dadosArquivo != null)
        file_id = dadosArquivo.id

    return {
      file : temp[1],
      extension : ext,
      id: file_id
    }
  }


  beforeUploadDocument = (file: any): boolean =>
  {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.controls['are_doc_aprovado'].setValue(reader.result.toString());
    };

   return false;
  }


  beforeUploadFile = (file: any): boolean =>
  {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.controls['arq_imagem'].setValue(reader.result.toString());
    };

   return false;
  }

  GetArea() {
    if (this.form.value.project_id) {
      this.showArea = false;
      setTimeout(() =>{
        this.showArea = true;
      }, 200);
    }
  }

  /*
  onChangeArea(area) {
    this.codeArea = area;
    this.service.getAreaByCode(this.codeArea).subscribe(response => {
      this.SetValues(response);
    },
     error => {
      this.notification.warning('Area não possui dados cadastrados', '');
      this.id = null;
      this.registerAreaComponent.resetForm();
      this.formArea.controls.code.setValue(this.codeArea);
      this.attachment_image = null;
      this.attachment_kmz = [];
      this.attachment_last_decl = null;
      this.attachment_last_issue = null;
      this.attachment_nirf = null;
      this.attachment_property = null;
    });
  }

  SetCodeArea(codeArea) {
    this.formArea.controls.code.setValue(codeArea);
  }

  ConvertToInt() {
    if (this.formArea.value.hectare_document) {
      this.formArea.value.hectare_document = parseInt(this.formArea.value.hectare_document);
    }

    if (this.formArea.value.hectare_ccir) {
      this.formArea.value.hectare_ccir = parseInt(this.formArea.value.hectare_ccir);
    }

    if (this.formArea.value.hectare_receita_federal) {
      this.formArea.value.hectare_receita_federal = parseInt(this.formArea.value.hectare_receita_federal);
    }

    if (this.formArea.value.hectare_incra) {
      this.formArea.value.hectare_incra = parseInt(this.formArea.value.hectare_incra);
    }
  }*/

  OnSubmit() {
    const urlParams = this.getUrlSendId(this.route);
    const id = urlParams[0];

    if (this.form.valid) {

      this.result = this.form.getRawValue();
      if (id)
      {
        //this.result['id'] = id;
        this.id = id;

        this.service.PutArea(id, this.result).subscribe((response: any) => {
          if (response.success === true) {
            this.router.navigateByUrl('/cadastro/listagem-areas');
          } else {
            this.notification.error(response.date, '');
          }
        });
      } else {

        delete this.result.contrato_ativo;
        this.service.PostArea(this.result).subscribe((response: any) => {
          if (response.success === true) {
            this.id = response.data.id;
            //this.dataRegisterArea.areaId = this.id;
            this.router.navigateByUrl('/cadastro/listagem-areas');
          } else {
            this.notification.error(response.date[0][0], '');
          }
        });
      }
    } else {

      if (this.form.valid) {
        this.notification.error('Confira os campos obrigatórios', '');
      }

      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      });
    }
  }

  RemoveValues() {
    if (this.result.has_competitor === false) {
      delete this.result.type_competing_company;
      delete this.result.competing_company;
    }

    if (this.result.gravame === false) {
      delete this.result.gravame_value;
    }

    if (this.result.owner_document === false) {
      delete this.result.owner_registration;
    }

    if (this.result.pendencies_regularize === null || this.result.pendencies_regularize === '') {
      delete this.result.pendencies_regularize;
    }

    if (this.result.time_regularize === null || this.result.time_regularize === '') {
      delete this.result.time_regularize;
    }

    if (this.result.code_incra === null || this.result.code_incra === '') {
      delete this.result.code_incra
      delete this.result.hectare_incra
    }

    if (this.result.ccir === null || this.result.ccir === '') {
      delete this.result.ccir
      delete this.result.hectare_ccir
    }

    if (this.result.nirf === null || this.result.nirf === '') {
      delete this.result.nirf
      delete this.result.hectare_receita_federal
    }

    Object.keys(this.result).forEach( key => {
      if (this.result[key] == null) {
        delete this.result[key];
      }
    })
  }
}
