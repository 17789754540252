import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ComboService } from 'src/app/shared/services/combo.service';
import * as moment from 'moment';
import { UtilsService } from '../../services/utils.service';
import { ActivatedRoute } from '@angular/router';
moment.locale('pt-br');

@Component({
  selector: 'app-register-person',
  templateUrl: './register-person.component.html',
  styleUrls: ['./register-person.component.scss']
})
export class RegisterPersonComponent implements OnInit{
  @Input() form: FormGroup;
  @Input() heritage: boolean = false;
  @Input() aditivo:boolean = false;
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter();

  listStates: any;
  listGender: any;
  listMatiral: any;
  listBank: any;
  listCity: any;
  listOfOption: { label: string; value: string; }[];
  account_type = 1;

  userId:any = null;


  constructor(private fb: FormBuilder,
              private serviceShared: ComboService,
              private route: ActivatedRoute,
              private notification: NzNotificationService,
              private utilsService: UtilsService) {


  }


  ngOnInit() {
    this.getCombos();
  }

  getCombos() {
    this.serviceShared.ComboState().subscribe((response: any) => {
      // console.log(response.data);
      this.listStates = response.data;
      this.searchState(this.form.value.address.state_id);
    });

    this.serviceShared.ComboMarital().subscribe((response: any) => {
      // console.log(response.data);
      this.listMatiral = response.data;
    });

    this.serviceShared.ComboGender().subscribe((response: any) => {
      // console.log(response.data);
      this.listGender = response.data;
    });

    this.serviceShared.ComboBank().subscribe((response: any) => {
      // console.log(response.data);
      this.listBank = response.data;
    });
  }

  public resetForm() {
    this.form.reset();
  }

  public getUrlSendId(route: ActivatedRoute) {
    let id;
    let operation;

    route.params.subscribe(params => {
      id = params['id'];
    });

    operation = route.url['_value'][1];

    return [id, operation];
  }

  getCfpInfo()
  {
    const urlParams = this.getUrlSendId(this.route);

    if(urlParams[0] === undefined || this.aditivo)
    {
      let cpf = this.form.get('person').get('cpf').value;

      this.utilsService.VerificaContratoPorCPF(cpf).subscribe((response: any) => {

        let data = response.data
        this.userId = data.id;
        //this.listMatiral = response.data;
        this.form.get('person').get('name').setValue(data.name);
        this.form.get('person').get('rg').setValue(data.rg);
        this.form.get('person').get('issuing_agency').setValue(data.issuing_agency);
        this.form.get('person').get('issuing_agency').setValue(data.issuing_agency);
        this.form.get('person').get('owner').setValue(data.owner);
        this.form.get('person').get('is_heir').setValue(data.is_heir);
        this.form.get('person').get('consenting').setValue(data.consenting);
        this.form.get('person').get('beneficiary').setValue(data.beneficiary);
        this.form.get('person').get('main_contact').setValue(data.main_contact);
        this.form.get('person').get('birth_date').setValue(data.birth_date);
        this.form.get('person').get('nationality').setValue(data.nationality);
        this.form.get('person').get('job').setValue(data.job);
        this.form.get('person').get('gender_id').setValue(data.gender_id);
        this.form.get('person').get('telephone').setValue(data.telephone);
        this.form.get('person').get('cellphone').setValue(data.cellphone);
        this.form.get('person').get('deceased').setValue(data.deceased);
        this.form.get('person').get('marital_status_id').setValue(data.marital_status_id);
        this.form.get('person').get('deceased_date').setValue(data.deceased_date);
        this.form.get('address').get('zip_code').setValue(data.zip_code);
        this.form.get('address').get('state_id').setValue(data.state_id);
        this.searchState(data.state_id);
        this.form.get('address').get('city_id').setValue(data.city_id);
        this.form.get('address').get('neighborhood').setValue(data.neighborhood);
        this.form.get('address').get('number').setValue(data.number);
        this.form.get('address').get('public_place').setValue(data.public_place);
        this.form.get('address').get('complement').setValue(data.complement);
        this.form.get('bank').get('bank_id').setValue(data.bank_id);
        this.form.get('bank').get('account').setValue(data.account);
        this.form.get('bank').get('agency').setValue(data.agency);
        this.form.get('attachments').get('owner_cpf_frente_text').setValue(data.cpf_file_front);
        this.form.get('attachments').get('owner_cpf_verso_text').setValue(data.cpf_file_back);
        this.form.get('attachments').get('owner_rg_frente_text').setValue(data.rg_file_front);
        this.form.get('attachments').get('owner_rg_verso_text').setValue(data.rg_file_back);
        this.form.get('attachments').get('proof_address_text').setValue(data.residence_file);
        this.form.get('attachments').get('death_text').setValue(data.death_file);
        this.form.get('attachments').get('marriage_text').setValue(data.marriage_file);

        let tipo_conta = (data.checking_account) ? 1 : 2;
        this.setTipoConta(tipo_conta)

      });
    }

  }



  syncBeneficiary() {
    if (this.form.get('person').get('beneficiary').value == true) {
      this.form.get('bank').get('bank_id').setValidators(Validators.required);
      this.form.get('bank').get('account').setValidators(Validators.required);
      this.form.get('bank').get('agency').setValidators(Validators.required);

    } else {
      this.form.get('bank').get('bank_id').setValidators(null);
      this.form.get('bank').get('account').setValidators(null);
      this.form.get('bank').get('agency').setValidators(null);
    }
    this.form.get('bank').updateValueAndValidity();

  }


  _beforeUploadAnexo = (file: any, tipo:string): boolean => {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

        if(tipo == 'cpf_frente') {
          this.form.get('attachments').get('owner_cpf_frente_text').setValue(reader.result.toString());
          this.form.get('attachments').get('owner_cpf_frente_text').updateValueAndValidity();
        } else if(tipo == 'cpf_verso') {
          this.form.get('attachments').get('owner_cpf_verso_text').setValue(reader.result.toString());
          this.form.get('attachments').get('owner_cpf_verso_text').updateValueAndValidity();
        } else if(tipo == 'rg_frente') {
          this.form.get('attachments').get('owner_rg_frente_text').setValue(reader.result.toString());
          this.form.get('attachments').get('owner_rg_frente_text').updateValueAndValidity();
        } else if(tipo == 'rg_verso') {
          this.form.get('attachments').get('owner_rg_verso_text').setValue(reader.result.toString());
          this.form.get('attachments').get('owner_rg_verso_text').updateValueAndValidity();
        } else if(tipo == 'cep') {
          this.form.get('attachments').get('proof_address_text').setValue(reader.result.toString());
          this.form.get('attachments').get('proof_address_text').updateValueAndValidity();
        } else if(tipo == 'death') {
          this.form.get('attachments').get('death_text').setValue(reader.result.toString());
          this.form.get('attachments').get('death_text').updateValueAndValidity();
        } else if(tipo == 'marriage') {
          this.form.get('attachments').get('marriage_text').setValue(reader.result.toString());
          this.form.get('attachments').get('marriage_text').updateValueAndValidity();
        }

    };

   return false;
  }


  beforeUploadCpfFrente = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'cpf_frente');
  }
  beforeUploadCpfVerso = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'cpf_verso');
  }

  beforeUploadRgFrente = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'rg_frente');
  }
  beforeUploadRgVerso = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'rg_verso');
  }

  beforeUploadCep = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'cep');
  }
  beforeUploadDeath = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'death');
  }
  beforeUploadMarriage = (file: any): boolean => {
    return this._beforeUploadAnexo(file, 'marriage');
  }



  searchState(id) {
    if (!id) {
      return;
    }
    this.serviceShared.ComboCity(id).subscribe((res: any) => {
      this.listCity = res.data;
    });
  }


  errorMesage(group, field, minlength) {
    return 'Campo obrigatorio';
  }

  public setTipoConta(data) {
    this.account_type = data;
  }

  GetDocumento(id, tipo) {

    var retorno = null;
    if(tipo == 'cpf-frente') {
      retorno = this.form.get('attachments').get('owner_cpf_frente_text').value;
    } else if(tipo == 'cpf-verso') {
      retorno = this.form.get('attachments').get('owner_cpf_verso_text').value;
    } else if(tipo == 'rg-frente') {
      retorno = this.form.get('attachments').get('owner_rg_frente_text').value;
    } else if(tipo == 'rg-verso') {
      retorno = this.form.get('attachments').get('owner_rg_verso_text').value;
    } else if(tipo == 'comprovante-residencia') {
      retorno = this.form.get('attachments').get('proof_address_text').value;
    } else if(tipo == 'certidao-obito') {
      retorno = this.form.get('attachments').get('death_text').value;
    } else if(tipo == 'certidao-casamento') {
      retorno = this.form.get('attachments').get('marriage_text').value;
    }
    if (retorno) {
          
      const downloadLink = document.createElement('a');
      const fileName = tipo;
      downloadLink.href = retorno;
      downloadLink.download = fileName;
      downloadLink.click();

    } else if (id) {

      this.utilsService.GetDocumento(id, tipo).subscribe((res: any) => {
        let data = res.data
        if (data) {
          const downloadLink = document.createElement('a');
          const fileName = data.nome_arquivo;

          downloadLink.href = data.base64_arquivo;
          downloadLink.download = fileName;
          downloadLink.click();
        }

      });
    }

  }

}
