export enum ContractType {
    CessaoDeUso = 1,
    ContratoDeArrendamento = 2
}

export enum ReasonNewContractType  {
    MudançaParaCessao = 1,
    CessaoEntreEmpresas = 2,
    Outros = 3
}

export enum SituationType {
    Propriedade = 1,
    Posse = 2
}

export enum CompetitionType {
    Contrato = 1,
    Intenções = 2,
    TorreAnemometrica = 3
}

export enum RemunerationPreOperationType {
    ValorFixoPorPeriodo = 1,
    ValorPorHectare = 2,
}



export enum FrequencyType {
    Mensal = 1,
    Trimestral = 2,
    Semestral = 3,
    Anual = 4
}

export enum RemunerationCommercialOperationType {
    FixaParaAerogerador = 1,
    RelativaGeracao = 2,
    FixaParaAerogeradorValorPorHectare = 3
}

export enum ApportionmentType {
    Porcentagem = 1,
    Valor = 2,
}