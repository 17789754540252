import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-resilicao',
  templateUrl: './resilicao.component.html',
  styleUrls: ['./resilicao.component.scss']
})
export class ResilicaoComponent implements OnInit {

  form: FormGroup;

  previousPage = '';
  id: any;

  blockFields = false;
  isVisible = false;
  requisitionData = null;

  constructor(private fb: FormBuilder,
              private notification: NzNotificationService,
              private route: ActivatedRoute,
              private router: Router) {

    this.form = this.fb.group({

    });


    const urlParams = this.getParamsUrl(this.route);
    this.id = urlParams.id;
    if (urlParams.urlSegment && urlParams.urlSegment.path === 'view') {
      this.blockFields = true;
      this.isVisible = true;
    }

    if(this.id === undefined)
    {
      this.router.navigate(['/cadastro/listagem-contratos']);
    }
  }

  ngOnInit(): void {

  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;
    route.params.subscribe(params => {
      if (history.state.previousPage) {
        this.previousPage = history.state.previousPage
      }
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

}
