import { Component, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { radioRequiredValidator } from 'src/app/shared/validators/customvalidator.validator';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-listagem-de-notas',
  templateUrl: './listagem-de-notas.component.html',
  styleUrls: ['./listagem-de-notas.component.scss']
})
export class ListagemDeNotasComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  form: FormGroup;
  modalData;
  @Input() showJustification = false;
  @Input() listData = [];

  @Output() sentAnInconsistency = new EventEmitter();

  constructor(private fb: FormBuilder,
    private notification: NzNotificationService,
    private accountingEntryService: AccountingEntryService) {
    this.form = this.fb.group({
      commercialInvoices: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.listData.forEach((data: any) => {
      let commercialInvoice = this.createFormGroup(data)
      this.commercialInvoices.push(commercialInvoice);
    })
  }

  get commercialInvoices(): FormArray {
    return this.form.get('commercialInvoices') as FormArray;
  }

  createFormGroup(data): FormGroup {
    return this.fb.group({
      colligate: this.fb.control({ value: data.colligate, disabled: false }, [Validators.nullValidator]),
      cost_center: this.fb.control({ value: data.cost_center_name, disabled: false }, [Validators.nullValidator]),
      nof: this.fb.control({ value: data.nof, disabled: false }, [Validators.nullValidator]),
      provider: this.fb.control({ value: data.provider, disabled: false }, [Validators.nullValidator]),
      hierarchy_id: this.fb.control({ value: data.hierarchy_id, disabled: false }, [Validators.nullValidator]),
      value: this.fb.control({ value: data.value, disabled: false }, [Validators.nullValidator]),
      release_number: this.fb.control({ value: data.release_number, disabled: false }, [Validators.nullValidator]),
      responsible: this.fb.control({ value: data.responsible ? data.responsible : data.user.name, disabled: false }, [Validators.nullValidator]),
      responsible_id: this.fb.control({ value: data.responsible_id, disabled: false }, [Validators.nullValidator]),
      date: this.fb.control({ value: moment(data.date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY'), disabled: false }, [Validators.nullValidator]),
      id: this.fb.control({ value: data.id, disabled: false }, [Validators.nullValidator]),
      document_number: this.fb.control({ value: data.document_number, disabled: false }, [Validators.nullValidator]),
      observation: this.fb.control({ value: data.observation, disabled: false }, [Validators.nullValidator]),
      responsible_email: this.fb.control({ value: data.responsible_email, disabled: false }, [Validators.nullValidator]),
      inconsistency: this.fb.control({ value: false, disabled: this.showJustification ? false : true }, [Validators.nullValidator]),
      message: this.fb.control({ value: null, disabled: this.showJustification ? false : true }, [radioRequiredValidator('inconsistency', true)] ),
    });
  }

  sendInconsistency() {
    if (!this.form.invalid) {
      let data = { 'invoices': [] }
      this.form.getRawValue().commercialInvoices.forEach(item => {
        if (item.inconsistency) {
          data.invoices.push({
            message: item.message,
            colligate: item.colligate,
            cost_center_name: item.cost_center,
            document_number: item.document_number,
            date: moment(item.date.replace(/-/g,''), 'DDMMYYYY').format('YYYY-MM-DD'),
            nof: item.nof,
            provider: item.provider,
            hierarchy_id: item.hierarchy_id,
            observation: item.observation,
            responsible_id: item.responsible_id,
            value: item.value,
            release_number: item.release_number
          })
        }
      })

      this.accountingEntryService.postinvalidInvoices(data).subscribe((response: any) => {
        this.sentAnInconsistency.emit(true)
      })
    } else {
      for (const i in this.commercialInvoices['controls']) {
        for (const fieldName in this.commercialInvoices['controls'][i]['controls']) {
          if (this.commercialInvoices['controls'][i]['controls'][fieldName].value == null) {
            this.commercialInvoices['controls'][i]['controls'][fieldName].markAsDirty();
            this.commercialInvoices['controls'][i]['controls'][fieldName].updateValueAndValidity();
          }
        }
      }

      this.notification.warning("Verifique os campos Obrigatórios", "")
    }
  }


  viewDetails(invoice) {
    this.modalData = invoice;

    this.modal.showModal();
  }

  hideModal() {
    this.modal.hideModal();
  }


  hasDivergence() {
    let result = false
    this.form.getRawValue().commercialInvoices.forEach(item => {
      if (item.inconsistency) {
        result = true;
      }
    });
    return result;
  }

  formatFloatNumber(value) {
    if (value == 0)
      return "-";
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2});
    return formatter.format(value);
  }


}
