import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AccountingEntryService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getAccountingEntry(query) {
      return this.http.get(environment.baseApiUrl + 'accounting-entry/historic?page=1' + query)
  }

  getAccountingEntryByHierarchyId(page, hierarchy_list, query_filters) {
    var query = ''
    if (hierarchy_list.length > 0) {
      query = '&hierarchy_id=['+hierarchy_list+']'
    }

    if (query_filters != '') {
      if (query != '') {
        query += query_filters
      } else {
        query = query_filters.replace('?','&')
      }
    }

    return this.http.get(environment.baseApiUrl + 'accounting-entry/hierarchy?page='+page + query)
  }

  download() {
    return this.http.get(environment.baseApiUrl + 'accounting-entry/download', { responseType: 'blob' })
  }


  getAccountingEntryByContractId(contractId){
    return this.http.get(environment.baseApiUrl + `accounting-entry/contract?contract_id=${contractId}`)
  }

  getInvalidInvoices(data) {
    return this.http.post(environment.baseApiUrl + 'accounting-entry/invalid-invoices', data)
  }

  postinvalidInvoices(data) {
    return this.http.post(environment.baseApiUrl + 'accounting-entry/invalid-invoices', data)
  }

  updateinvalidInvoices(data) {
    return this.http.put(environment.baseApiUrl + 'accounting-entry/invalid-invoices', data)
  }

  getInvoiceCostCenters(){
    return this.http.get(environment.baseApiUrl + 'cost-center-type/invoice/combo');
  }

  getHierarchyCostCenters(year){
    return this.http.get(environment.baseApiUrl + 'cost-center-type/hierarchy/combo/'+year);
  }

  getInvoiceNofs() {
    return this.http.get(environment.baseApiUrl + 'nof-type/invoice/combo');
  }

  getHierarchyNofs(year) {
    return this.http.get(environment.baseApiUrl + 'nof-type/hierarchy/combo/'+year);
  }

  getExceptionNofs() {
    return this.http.get(environment.baseApiUrl + 'nof-type/exception/combo');
  }


  getInvoiceResponsibles() {
    return this.http.get(environment.baseApiUrl + 'responsible-type/invoice/combo');
  }

  getHierarchyResponsibles(year) {
    return this.http.get(environment.baseApiUrl + 'responsible-type/hierarchy/combo/'+year);
  }

  getProviders() {
    return this.http.get(environment.baseApiUrl + 'provider-type/combo');
  }

  getInvoiceColigates() {
    return this.http.get(environment.baseApiUrl + 'coligate-type/invoice/combo');
  }

  getHierarchyColigates(year) {
    return this.http.get(environment.baseApiUrl + 'coligate-type/hierarchy/combo/'+year);
  }
}
