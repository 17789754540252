import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


// Esse Validator funciona para validar se o radio(Sim/Não) respeita a condição booleana passada por parâmetro
// OBS: Isso só funciona nos casos em que o campo dependente está no mesmo nível que o campo que está sendo validado
export function radioRequiredValidator(radioName: string, conditionValue: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (control.parent.get(radioName).value == conditionValue) {
            if((Array.isArray(control.value) && control.value.length == 0) || !control.value) {
                return { required: true }; 
            }
        }

        return null;
    }
}

export function requiredIfParentIfdiferentFromNull(parentComponentName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (control.parent.get(parentComponentName).value != null && control.parent.get(parentComponentName).value != "") {
            if((Array.isArray(control.value) && control.value.length == 0) || !control.value) {
                return { required: true }; 
            }
        }
        return null;
    }
}
