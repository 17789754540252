import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import LoginResponse from '../models/login-response.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  login(email: string, password: string) {
    return this.http.post<LoginResponse>(environment.baseApiUrl + 'auth/', { email, password });
  }

  forgotMyPassword(email: string) {
    return this.http.put(environment.baseApiUrl + 'auth/forgot_my_password', {'email': email});
  }
}
