import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlterationBankdataService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  updateAlteration(data, id, draft) {
    return this.http.put(environment.baseApiUrl + `alteration-bankdata-requisition/${id}?draft=${draft}`, data);
  }

  save(data, draft) {
    return this.http.post(environment.baseApiUrl + 'alteration-bankdata-requisition/?draft=' + draft + '', data);
  }

  approvalAlteration(data, id, step) {
    return this.http.post(environment.baseApiUrl + 'alteration-bankdata-requisition/approve/' + id + '?step=' + step, data);
  }

  cancelRequisition(id) {
    return this.http.put(environment.baseApiUrl + `requisition/${id}/cancel`, { id });
  }

  requisitionDetails(id) {
    return this.http.get(environment.baseApiUrl + 'requisition/' + id);
  }

  alterationDetails(id) {
    return this.http.get(environment.baseApiUrl + 'alteration-bankdata-requisition/' + id);
  }

  uploadAnexo(file, requisition_id) {
    return this.http.post(environment.baseApiUrl + 'alteration-bankdata-requisition/upload/'+requisition_id, file);
  }

  downloadFile(name, uuid) {
    return this.http.post(environment.baseApiUrl + 'alteration-bankdata-requisition/download/'+name, {'uuid': uuid}, { responseType: 'blob' });
  }

  reintegrate(id) {
    return this.http.get(environment.baseApiUrl + 'additive-requisition/run-integration/'+id);
  }
}
