import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControleDeNotasComponent } from './pages/controle-de-notas/controle-de-notas.component';
import { GestaoDeOrcamentoComponent } from './pages/gestao-de-orcamento/gestao-de-orcamento.component';
import { RouterModule } from '@angular/router';
import { OrcamentoRoutingModule } from './orcamento-routing.module'
import { SharedModule } from '../shared/shared.module';
import { PlanejamentoAnualComponent } from './pages/planejamento-anual/planejamento-anual.component';
import { OrcamentoCorrenteComponent } from './pages/orcamento-corrente/orcamento-corrente.component';
import { OrcamentoCorrenteHierarquiaComponent } from './pages/orcamento-corrente-hierarquia/orcamento-corrente-hierarquia.component';
import { NzNotificationModule, NzRadioModule, NzButtonModule, NzInputModule, NzTableModule, NzIconModule, NzFormModule, NzTabsModule, NzToolTipModule, NzResultModule, NzSelectModule, NzPopoverModule, NzPaginationModule, NzModalModule, NzUploadModule, NzCollapseModule, NzDropDownModule, NzCheckboxModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ListagemDeCustosComponent } from './components/listagem-de-custos/listagem-de-custos.component';
import { ListagemDeCustosHierarquiaComponent } from './components/listagem-de-custos-hierarquia/listagem-de-custos-hierarquia.component';
import { ListagemDeNotasComponent } from './components/listagem-de-notas/listagem-de-notas.component';
import { ListagemDeNotasHistoricoComponent } from './components/listagem-de-notas-historico/listagem-de-notas-historico.component';
@NgModule({
  declarations: [ControleDeNotasComponent, GestaoDeOrcamentoComponent, PlanejamentoAnualComponent, OrcamentoCorrenteComponent, OrcamentoCorrenteHierarquiaComponent, ListagemDeCustosComponent, ListagemDeCustosHierarquiaComponent, ListagemDeNotasComponent, ListagemDeNotasHistoricoComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    OrcamentoRoutingModule,
    SharedModule,
    NzNotificationModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    NzIconModule,
    NzFormModule,
    NzToolTipModule,
    NzResultModule,
    NzSelectModule,
    NzPopoverModule,
    NzPaginationModule,
    NzModalModule,
    NzUploadModule,
    NzRadioModule,
    NzCollapseModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzTabsModule,
    NgxMaskModule.forRoot()
  ]
})
export class OrcamentoModule { }
