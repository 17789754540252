import { Component, ViewChild, OnDestroy } from '@angular/core';
import Dialog from '../../models/dialog.model';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnDestroy {

  dialog: Dialog = {
    title: '',
    message: '',
    success: () => {}
  };

  subscription: Subscription;

  @ViewChild('notificationTemplate', { static: true }) notificationTemplate;

  constructor(private dialogService: DialogService, private notification: NzNotificationService) {
    this.subscription = dialogService.data$.subscribe(dialog => {
      this.dialog = dialog;
      this.notification.template(this.notificationTemplate);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
