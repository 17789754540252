import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { PermissionService } from '../../../shared/services/permission.service';
import Permissions from '../../../../app/shared/enums/permissions.enum';

@Component({
  selector: 'app-controle-de-notas',
  templateUrl: './controle-de-notas.component.html',
  styleUrls: ['./controle-de-notas.component.scss']
})
export class ControleDeNotasComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  showJustificationInInvoiceControl = true;
  showHistoricOfInconsistency = true;
  listInvoices = []
  historicInvoices = []
  showInInvoiceControl = false
  showInInvoiceHistoric = false
  totalItens: any;
  totalItensHistoric: any;
  page: number = 1;
  pageHistoric: number = 1;
  counst_centers = []
  nofs = []
  responsibles = []
  providers = []
  coligates = []
  historicInvoicesAll = []
  cost_center_filter
  nof_filter
  responsible_filter
  provider_filter
  date_filter
  document_number_filter
  observations_filter
  coligate_filter

  query_filters = ''

  panels = [
    {
      active: false,
      name: 'Filtros',
      disabled: false
    }
  ];
  constructor(private accountingEntryService :AccountingEntryService,
    private permissionService: PermissionService,) {
  }

  ngOnInit(): void {
    this.getFilters()
    this.getListInvoices();
    this.getListHistoric();
  }

  getListInvoices() {
    this.showInInvoiceControl = false
    this.accountingEntryService.getAccountingEntryByHierarchyId(this.page, [], this.query_filters).subscribe( (response : any) => {
      this.totalItens = response.total;
      this.listInvoices = response.data;
      this.showInInvoiceControl = true
    })
  }


  getListHistoric() {
    this.accountingEntryService.getAccountingEntry(this.query_filters).subscribe( (response : any) => {
      this.historicInvoices = response.data;
      this.totalItensHistoric = response.total;
      this.showInInvoiceHistoric = true
    })
  }

  showResponsibleFilter(){
    return this.permissionService.canAny([Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO, Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO])
  }

  canEditSolution(){
    return this.permissionService.canAny([Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO]);
  }


  getFilters() {
    this.accountingEntryService.getInvoiceColigates().subscribe( (response : any) => {
      this.coligates = response.data;
    })

    this.accountingEntryService.getInvoiceCostCenters().subscribe( (response : any) => {
      this.counst_centers = response.data;
    })

    this.accountingEntryService.getInvoiceNofs().subscribe( (response : any) => {
      this.nofs = response.data;
    })

    this.accountingEntryService.getProviders().subscribe( (response : any) => {
      this.providers = response.data;
    })

    this.accountingEntryService.getInvoiceResponsibles().subscribe( (response : any) => {
      this.responsibles = response.data;
    })
  }



  setPage(page) {
    this.page = page
    this.getListInvoices();
  }
  setPageHistoric(page) {
    this.pageHistoric = page
    this.getListHistoric();
  }

  filter() {
    this.query_filters = ''

    if (this.cost_center_filter) {
      this.query_filters += '&cost_center='+this.cost_center_filter
    }

    if (this.nof_filter) {
      this.query_filters += '&nof='+this.nof_filter
    }

    if (this.responsible_filter) {
      this.query_filters += '&responsible='+this.responsible_filter
    }

    if (this.provider_filter) {
      this.query_filters += '&provider='+this.provider_filter
    }

    if (this.date_filter) {
      this.query_filters += '&date='+ encodeURI(this.date_filter)
    }

    if (this.document_number_filter) {
      this.query_filters += '&document_number='+this.document_number_filter
    }

    if (this.observations_filter) {
      this.query_filters += '&observation='+this.observations_filter
    }

    if (this.coligate_filter) {
      this.query_filters += '&colligate='+this.coligate_filter
    }

    this.getListInvoices()
    this.getListHistoric()
  }

  clearFilter() {
    this.query_filters = ''

    this.cost_center_filter = undefined;
    this.nof_filter = undefined;
    this.responsible_filter = undefined;
    this.provider_filter = undefined;
    this.date_filter = undefined;
    this.document_number_filter = undefined;
    this.observations_filter = undefined;
    this.coligate_filter = undefined;

    this.getListInvoices()
    this.getListHistoric()
  }





}
