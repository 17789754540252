import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from '../shared/pages/master-page/master-page.component';
import { ListagemAreasComponent } from './pages/listagem-areas/listagem-areas.component';
import { FormCadastroAreasComponent } from './pages/form-cadastro-areas/form-cadastro-areas.component';
import { ListagemPessoasComponent } from './pages/listagem-pessoas/listagem-pessoas.component';
import { FormCadastroPessoasComponent } from './pages/form-cadastro-pessoas/form-cadastro-pessoas.component';
import { FormCadastroProjetosComponent } from './pages/form-cadastro-projetos/form-cadastro-projetos.component';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ListagemContratosComponent } from './pages/listagem-contratos/listagem-contratos.component';
import { ListagemProjetosComponent } from './pages/listagem-projetos/listagem-projetos.component';
import { AnyPermissionsGuard } from '../shared/guards/any-permissions.guard';
import Permissions from '../shared/enums/permissions.enum';


const routes: Routes = [
  {
    path: 'cadastro',
    component: MasterPageComponent,
    children: [
      {
        path: 'listagem-areas',
        component: ListagemAreasComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Áreas',
          permissions: [
            Permissions.CADASTROS_AREAS,
            Permissions.CADASTROS_AREAS_APROVACAO,
            Permissions.CADASTROS_AREAS_VISUALIZACAO
          ]
        }
      },
      {
        path: 'form-cadastro-areas',
        component: FormCadastroAreasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Áreas',
          permissions: [Permissions.CADASTROS_AREAS]
        }
      },
      {
        path: 'form-cadastro-areas/:id',
        component: FormCadastroAreasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Áreas',
          permissions: [Permissions.CADASTROS_AREAS]
        }
      },
      {
        path: 'form-cadastro-areas/view/:id',
        component: FormCadastroAreasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Visualização de Área',
          permissions: [Permissions.CADASTROS_AREAS_VISUALIZACAO]
        },
      },
      {
        path: 'listagem-pessoas',
        component: ListagemPessoasComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Pessoas',
          permissions: [
            Permissions.CADASTROS_PESSOAS,
            Permissions.CADASTROS_PESSOAS_VISUALIZACAO
          ]
        }
      },
      {
        path: 'form-cadastro-pessoas',
        component: FormCadastroPessoasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Pessoas',
          permissions: [Permissions.CADASTROS_PESSOAS]
        },
      },
      {
        path: 'form-cadastro-pessoas/:id',
        component: FormCadastroPessoasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Pessoas',
          permissions: [Permissions.CADASTROS_PESSOAS]
        },
      },
      {
        path: 'form-cadastro-pessoas/view/:id',
        component: FormCadastroPessoasComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Pessoas',
          permissions: [Permissions.CADASTROS_PESSOAS_VISUALIZACAO]
        },
      },
      {
        path: 'listagem-contratos',
        component: ListagemContratosComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Contratos',
          permissions: [
            Permissions.CADASTROS_CONTRATO,
            Permissions.CADASTROS_CONTRATO_VISUALIZACAO,
            Permissions.CADASTROS_CONTRATO_CARGA
          ]
        }
      },
      {
        path: 'form-cadastro-projetos',
        component: FormCadastroProjetosComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Projetos',
          permissions: [Permissions.CADASTROS_CONTRATO]
        },
      },
      {
        path: 'form-cadastro-projetos/:id',
        component: FormCadastroProjetosComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro de Projetos',
          permissions: [Permissions.CADASTROS_AREAS]
        }
      },
      {
        path: 'form-cadastro-projetos/view/:id',
        component: FormCadastroProjetosComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Visualização de Projeto',
          permissions: [Permissions.CADASTROS_AREAS_VISUALIZACAO]
        },
      },
      {
        path: 'listagem-projetos',
        component: ListagemProjetosComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Projetos',
          permissions: [
            Permissions.CADASTROS_CONTRATO,
            Permissions.CADASTROS_AREAS_VISUALIZACAO
          ]
        }
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastroRoutingModule { }
