import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'src/app/auth/auth.reducer';
import { selectAuthUser } from 'src/app/auth/auth.selectors';

@Component({
  selector: 'app-form-cadastro-usuario',
  templateUrl: './form-cadastro-usuario.component.html',
  styleUrls: ['./form-cadastro-usuario.component.scss']
})
export class FormCadastroUsuarioComponent implements OnInit {

  @Input() edit: boolean;
  public showUser$ = this.state.pipe(select(selectAuthUser));
  private emailPattern = '[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+';
  form: FormGroup;
  name = this.fb.control('', [Validators.required]);
  email = this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)]);
  cpf = this.fb.control('', [Validators.required, this.isCpf.bind(this)]);
  rg = this.fb.control('', [Validators.required]);
  pos_id = this.fb.control(null, null);
  telefone = this.fb.control('', [Validators.required]);
  projeto = this.fb.control('', [Validators.required]);
  permissoes = this.fb.control([], [Validators.required]);

  checked = false;
  idEdit = false;
  id = null;
  onlineUser = null

  listProjects: [] = [];
  listOfTagOptions = [];
  listPermissions: any;
  arrayPermissions = [];
  projectsUser = [];
  projectEmpty: boolean;
  permissoesEmpty: boolean;
  visible = false;

  positions: any[] = [];
  page = 1;

  constructor(private fb: FormBuilder,
              private service: UsuarioService,
              private route: ActivatedRoute,
              private router: Router,
              private notification: NzNotificationService,
              private state: Store<AuthState>) {

    this.form = this.fb.group({
      name: this.name,
      email: this.email,
      cpf: this.cpf,
      rg: this.rg,
      telefone: this.telefone,
      projeto: this.projeto,
      permissoes: this.permissoes,
      pos_id: this.pos_id
    });
  }

  ngOnInit(): void {
    this.showUser$.subscribe((user: any) => {
      this.onlineUser = user.id;
    });


    this.service.GetTablePermission().subscribe((response: any) => {
      this.listPermissions = response.data;
    });

    const urlParams = this.getUrlSendId(this.route);

    if (urlParams.id) {
      this.service.GetOnlyOneUser(urlParams.id).subscribe((response: any) => {
        this.SetValues(response);
      });
    }
    if (urlParams.urlSegment) {
      if (urlParams.urlSegment.path === 'view') {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }

    this.service.GetCombo().subscribe((response: any) => {
      this.listProjects = response.data;
    });
  }

  public getUrlSendId(route: ActivatedRoute) {
    let id;
    route.params.subscribe(params => {
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

  SetValues(listUser) {

    const formUser = listUser.data;
    const projetos = [];
    const permissions = [];

    this.id = formUser.id;

    this.form.controls['name'].setValue(formUser.name);
    this.form.controls['email'].setValue(formUser.email);
    this.form.controls['rg'].setValue(formUser.rg);
    this.form.controls['cpf'].setValue(formUser.cpf);
    this.form.controls['telefone'].setValue(formUser.phone);
    this.form.controls['pos_id'].setValue(formUser.pos_id);

    listUser.data.user_permissions.forEach(item => {
      this.Permission(item.permission_id);
    });

    formUser.user_projects.forEach(item => projetos.push(item.project_id));
    this.projeto.setValue(projetos);
    this.projectsUser = projetos;

  }

  SetPermissions(data) {
    const checked = this.permissoes.value.findIndex(permission => permission === data) !== -1;
    return checked;
  }

  Permission(id) {
    if (this.permissoes.value.findIndex(permission => permission === id) !== -1) {
      this.permissoes.setValue(this.permissoes.value.filter((data) => data !== id));
    } else {
      this.permissoes.value.push(id);
      if (id == 3) {
        this.permissoes.value.push(4);
      }
      if (id == 5) {
        this.permissoes.value.push(6);
      }
      if (id == 8) {
        this.permissoes.value.push(7);
      }
    }
  }

  formReset() {
    this.form.reset()
  }

  blockButton() {
    return this.onlineUser != this.id
  }

  cpfMessageValidation() {
    if (this.visible) {
      return '';
    }
    if(this.cpf.errors) {
      if (this.cpf.errors.required) {
        return "Campo obrigatório"
      } else {
        return "Digite um CPF válido"
      }
    }
  }

  MessageValidation(field) {
    if (this.visible) {
      return '';
    }
    if(this[field].errors) {
      if (this[field].errors.required) {
        return "Campo obrigatório"
      } else {
        return field + " inválido"
      }
    }
  }

  isCpf(control: FormControl) {
    if (control.value) {
      let cpf = control.value.replace(/[^\d]+/g,'');
      if(cpf == '') return false;
      // Elimina CPFs invalidos conhecidos
      if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
          return false;
      // Valida 1o digito
      let add = 0;
      for (let i=0; i < 9; i ++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
          rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
          return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i ++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11)
        rev = 0;
      if (rev != parseInt(cpf.charAt(10)))
        return { invalidcpf: true };
      return true;
    }
    return true;
  }

  ValidateProjects() {
    if (this.projectsUser.length > 0) {
      this.projectEmpty = false;
    }
  }

  removeNullInForm() {
    const values = this.form.value;

    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });

    return values;
  }

  OnSubmit() {
    this.form.controls.projeto.setValue(this.projectsUser);
    this.form.controls.permissoes.updateValueAndValidity();
    if (!this.form.invalid || !this.form.controls) {

      const result = this.removeNullInForm();

      if (this.id) {
        this.service.PutUser(this.id, result).subscribe((response: any) => {
          this.formReset();
          this.router.navigateByUrl('/admin/listagem-usuarios');
        }, (error : any) => {
          this.notification.error(error.error.data, '');
        });
      } else {
        this.service.PostUser(result).subscribe((response: any) => {
          this.formReset();
          this.router.navigateByUrl('/admin/listagem-usuarios');
        }, (error : any) => {
          this.notification.error(error.error.data, '');
        });
      }

    } else {
      if (this.projectsUser.length === 0) {
        this.projectEmpty = true;
      } else {
        this.projectEmpty = false;
      }

      if (this.permissoes.value.length === 0) {
        this.permissoesEmpty = true;
      } else {
        this.permissoesEmpty = false;
      }

      // tslint:disable-next-line: forin
      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }

      this.notification.error('Confira os campos obrigatórios', '');

    }
  }
}
