import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { HistoricoPendenciaService } from '../../services/historico-pendencia.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as moment from 'moment';

@Component({
  selector: 'app-historico-pendencia',
  templateUrl: './historico-pendencia.component.html',
  styleUrls: ['./historico-pendencia.component.scss']
})
export class HistoricoPendenciaComponent implements OnInit, OnChanges {

  @Input() valor: boolean = false;
  @Input() history;
  listHistoric: any;
  statusResponse: any;
  department: any;
  idStatus: any;

  constructor(private service: HistoricoPendenciaService,  private serviceUtils: UtilsService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.valor === false) {
      this.valor = !this.valor;
    }

    if (this.history) {
      this.service.Historic(this.history).subscribe((response: any) => {
        this.listHistoric = response.data;
      });
    }

    if (this.history) {
      this.serviceUtils.OneRequisition(this.history).subscribe((response: any) => {
        this.statusResponse = response.data.status ? response.data.status.description : response.data.state.description;
        this.department = response.data.current_department;
        this.idStatus = response.data.status ? response.data.status.id : response.data.state.id;
      });
    }
  }

  handleCancelMiddle(): void {
    this.valor = !this.valor;
  }

  convertDates(date) {
    if (date == '-') {
      return date
    }

    if(date)
    {
      return moment(date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY');
    }
    else
    {
      return '-';
    }

  }
}
