import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { PlanningPeriodService } from '../../services/planning-period.service';
import { RealizedBudgetService } from '../../services/realized-budget.service';

@Component({
  selector: 'app-orcamento-corrente',
  templateUrl: './orcamento-corrente.component.html',
  styleUrls: ['./orcamento-corrente.component.scss']
})
export class OrcamentoCorrenteComponent implements OnInit {
  items = [ {id:1, text: 'Contabil'},
   {id:2,text: 'Financeiro'}
  ]

  atualYear = parseInt(moment(new Date(), 'YYYY/MM/DD').format('Y'));
  atualMonth = parseInt(moment(new Date(), 'YYYY/MM/DD').format('M'));

  users = []

  synchronizedYearFilter

  //não mudar a ordem
  months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  //não mudar a ordem
  monthspt = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  //não mudar a ordem
  cols_contabil = ['planned', 'planned_realized_deviation', 'planned_replanning_deviation', 'replanning_realized_deviation', 'replanning', 'assertiveness', 'realized']
  //não mudar a ordem
  cols_financial = ['financial_planned', 'planned_realized_financial_deviation', 'planned_replanning_financial_deviation', 'replanning_realized_financial_deviation', 'financial_replanning', 'assertiveness_financial', 'financial_realized']
  cols = []
  //não mudar a ordem
  siglas = ['P', 'D', 'DPP', 'DPR', 'PR', 'A', 'R']

  itemIndex = 0
  list = {'contabil': [], 'financeiro': []}
  group_nofs = []
  constructor(private accountingEntryService: AccountingEntryService,
    private realizedBudgetService: RealizedBudgetService,
    private planningPeriodService: PlanningPeriodService) { }

  ngOnInit(): void {
    this.accountingEntryService.getExceptionNofs().subscribe( (response: any) => {
      this.group_nofs = response.data.map(x => x.desc)
    })

    this.getRealizedBudget(this.atualYear)
    this.planningPeriodService.GetPlaningPeriods().subscribe((response: any) => {
      var atual_year = moment().format('YYYY');
      var lastYear = "0"
      response.data.forEach(item => {
        if(item.status_id != 1) {
          if (parseInt(item.year) > parseInt(lastYear) && item.year <= atual_year) {
            lastYear = item.year
          }
        }
      });
    });
  }

  getRealizedBudget(year) {
    this.realizedBudgetService.getrealizedBudget(year).pipe(take(1)).subscribe( (response: any) => {
      this.list = {'contabil': [], 'financeiro': []}
      response.data.forEach(hierarchy => {
        Object.keys(hierarchy.planning).forEach(key => {
          if (hierarchy.planning[key] == null) {
            hierarchy.planning[key] = 0;
          }
        } )
      });

      this.groupUsers(response.data)

      this.synchronizedYearFilter = year
    })
  }

  groupUsers(data) {
    Object.keys(this.list).forEach( aba => {
      data.forEach(hierarchy => {
        if (this.users.includes(hierarchy.user_id) == false) {
          this.users.push(hierarchy.user_id)
        }
        var userExists = false
        this.list[aba].forEach(item => {
          if (item.user.name == hierarchy.user.name) {
            this.list[aba][this.list[aba].length - 1]['user']['hierarchyIds'].push(hierarchy.id)
            userExists = true
          }
        })
        if (!userExists) {
          this.list[aba].push({'user': {'name': hierarchy.user.name, 'hierarchyIds': []}})
          this.list[aba][this.list[aba].length - 1]['items'] = []
          this.list[aba][this.list[aba].length - 1]['user']['hierarchyIds'].push(hierarchy.id)
        }
      })
    })

    this.setMontsOfUser(data)
  }

  setMontsOfUser(data) {
    Object.keys(this.list).forEach( aba => {
      if (aba == 'financeiro') {
        this.cols = this.cols_financial
      } else {
        this.cols = this.cols_contabil
      }
      data.forEach(hierarchy => {
        this.list[aba].forEach( (item, index) => {
          if (item.user.name == hierarchy.user.name ) {
            this.months.forEach( month => {
              if (!this.list[aba][index]['user'][month]) {
                this.list[aba][index]['user'][month] = {}
              }
              this.cols.forEach( col => {

                var colAndMonth = [col]+'_'+[month]
                if (this.list[aba][index]['user'][month][colAndMonth]) {
                  this.list[aba][index]['user'][month][colAndMonth] = this.list[aba][index]['user'][month][colAndMonth] + hierarchy.planning[colAndMonth]
                } else {
                  this.list[aba][index]['user'][month][colAndMonth] = hierarchy.planning[colAndMonth]
                }
              })
            })
          }
        })
      })
    })

    this.setCostCenterOfUser(data)
  }

  setCostCenterOfUser(data) {
    Object.keys(this.list).forEach( aba => {
      if (aba == 'financeiro') {
        this.cols = this.cols_financial
      } else {
        this.cols = this.cols_contabil
      }
      data.forEach(hierarchy => {
        this.list[aba].forEach( (user, index) => {
          if (user.user.name == hierarchy.user.name) {
            var costCenterExists = false
            user['items'].forEach( (item, i) => {
              if (item.title == hierarchy.cost_center) {
                costCenterExists = true
                user['items'][i]['hierarchyIds'].push(hierarchy.id)
                this.months.forEach( month => {
                  this.cols.forEach( col => {
                    var colAndMonth = [col]+'_'+[month]
                    user['items'][i][colAndMonth] = user['items'][i][colAndMonth] + hierarchy.planning[colAndMonth]
                  })
                })
              }
            });

            if (!costCenterExists) {
              this.itemIndex += 1
              user['items'].push({'title': hierarchy.cost_center, 'id': this.itemIndex, 'hierarchyIds': []})
              user['items'][user['items'].length - 1]['hierarchyIds'].push(hierarchy.id)
              this.months.forEach( month => {
                this.cols.forEach( col => {
                  var colAndMonth = [col]+'_'+[month]
                  user['items'][user['items'].length - 1][colAndMonth] = hierarchy.planning[colAndMonth]
                })
              })

            }
          }
        })
      })
    })

    this.setGroupNOFOfUser(data)
  }

  setGroupNOFOfUser(data) {
    Object.keys(this.list).forEach( aba => {
      if (aba == 'financeiro') {
        this.cols = this.cols_financial
      } else {
        this.cols = this.cols_contabil
      }
      data.forEach(hierarchy => {
        this.list[aba].forEach( (user, index) => {
          user['items'].forEach(item => {
            if (!item['subitems']) {
              item['subitems'] = []
            }

            if (user.user.name == hierarchy.user.name) {
              if(item.title == hierarchy.cost_center){
                var nofGroupExists = false
                item['subitems'].forEach( (subitem, s) => {
                  if (subitem.title == hierarchy.nof_groupers) {
                    nofGroupExists = true
                    item['subitems'][s]['hierarchyIds'].push(hierarchy.id)
                    this.months.forEach( month => {
                      this.cols.forEach( col => {
                        var colAndMonth = [col]+'_'+[month]
                        item['subitems'][s][colAndMonth] = item['subitems'][s][colAndMonth] + hierarchy.planning[colAndMonth]
                      })
                    })

                  }
                })

                if (!nofGroupExists) {
                  item['subitems'].push({'title': hierarchy.nof_groupers, 'hierarchyIds': []})
                  item['subitems'][item['subitems'].length - 1]['hierarchyIds'].push(hierarchy.id)
                  this.months.forEach( month => {
                    this.cols.forEach( col => {
                      var colAndMonth = [col]+'_'+[month]
                      item['subitems'][item['subitems'].length - 1][colAndMonth] = hierarchy.planning[colAndMonth]
                    })
                  })
                }
              }
            }
          });
        })
      })
    })

    this.setDescriptionNOFOfUser(data)
  }

  setDescriptionNOFOfUser(data) {
    Object.keys(this.list).forEach( aba => {
      if (aba == 'financeiro') {
        this.cols = this.cols_financial
      } else {
        this.cols = this.cols_contabil
      }
      data.forEach((hierarchy, z)=> {
        this.list[aba].forEach( (user, index) => {
          user['items'].forEach(item => {
            if (user.user.name == hierarchy.user.name) {
              if(item.title == hierarchy.cost_center){
                item['subitems'].forEach(subitem => {
                  if (!subitem['nofs']) {
                    subitem['nofs'] = []
                  }
                  if (subitem.title == hierarchy.nof_groupers) {
                    var nofDescriptionExists = false
                    subitem['nofs'].forEach( (nof, n) => {

                      if (nof.title == hierarchy.nof_description) {
                        subitem['nofs'][n]['hierarchyIds'].push(hierarchy.id)
                        nofDescriptionExists = true
                        this.months.forEach( month => {
                          this.cols.forEach( col => {
                            var colAndMonth = [col]+'_'+[month]
                            subitem['nofs'][n][colAndMonth] = subitem['nofs'][n][colAndMonth] + hierarchy.planning[colAndMonth]
                          })
                        })
                      }
                    });

                    if (!nofDescriptionExists) {
                      subitem['nofs'].push({'title': hierarchy.nof_description, 'hierarchyIds': []})
                      subitem['nofs'][subitem['nofs'].length - 1]['hierarchyIds'].push(hierarchy.id)
                      this.months.forEach( month => {
                        this.cols.forEach( col => {
                          var colAndMonth = [col]+'_'+[month]
                          subitem['nofs'][subitem['nofs'].length - 1][colAndMonth] = hierarchy.planning[colAndMonth]
                        })
                      })
                    }

                  }
                });
              }
            }
          });
        })
      })
    })

    this.recalcularMedias()
    this.mapList()
  }

  assertiveness_calc(replanning, realized){
    var abs_realized = Math.abs(realized)
    var abs_replanning = Math.abs(replanning)

    if (!replanning) {
      replanning = 0;
    }

    if (!realized) {
      realized = 0;
    }

    if (replanning > 0 && realized < 0) {
      return 0;
    }

    if (replanning < 0 && realized > 0) {
      return 0;
    }

    if (abs_replanning < abs_realized ) {
      return ( abs_replanning / abs_realized) * 100  ;
    }

    if (abs_replanning > abs_realized ) {
      return (abs_realized / abs_replanning) * 100;
    }

    if (abs_replanning == abs_realized && abs_replanning != 0) {
      return 100;
    }
    else {
      return 0;
    }
  }


  recalcularMedias() {
    Object.keys(this.list).forEach( aba => {
      var col_assertiveness = ""
      var col_replanning = ""
      var col_realized = ""

      if (aba == 'financeiro') {
        col_assertiveness = "assertiveness_financial"
        col_replanning = "financial_replanning"
        col_realized = "financial_realized"
      } else {
        col_assertiveness = "assertiveness"
        col_replanning = "replanning"
        col_realized = "realized"
      }

      this.list[aba].forEach( user => {
        user.items.forEach(item => {
          item.subitems.forEach(subitem => {
            if (this.group_nofs.includes(subitem.title.toUpperCase())) {

              this.months.forEach((month, m) => {
                subitem[col_assertiveness +'_'+ month] = this.assertiveness_calc(subitem[col_replanning+'_'+ month],  subitem[col_realized+'_'+ month])
              })
            }
          })

          this.months.forEach((month, m) => {
            item[col_assertiveness +'_'+ month] = 0
            item.subitems.forEach(subitem => {
              item[col_assertiveness +'_'+ month] += subitem[col_assertiveness +'_'+ month]
            })
          })

        })

        this.months.forEach((month, m) => {
          user.user[month][col_assertiveness +'_'+ month] = 0
          user.items.forEach(item => {
            user.user[month][col_assertiveness +'_'+ month] += item[col_assertiveness +'_'+ month]
          })
        })
      })
    })


  }

  group_nofs_includes(item, line) {
    return this.group_nofs.includes(item.title.toUpperCase()) && line == "A"
  }

  mapList() {
    Object.keys(this.list).forEach( aba => {
      if (aba == 'financeiro') {
        this.cols = this.cols_financial
      } else {
        this.cols = this.cols_contabil
      }
      this.list[aba].forEach( user => {
        user['user']['AC'+this.monthspt[this.atualMonth - 2]] = {}
        user['user']['ACAno'] = {}

        this.months.forEach( (month, m) => {
          user['user'][this.monthspt[m]] = {}
          this.cols.forEach( (col, c) => {
            var colAndMonth = [col]+'_'+[month]
            user['user'][this.monthspt[m]][this.siglas[c]] = user['user'][month][colAndMonth]

            if (m <= this.atualMonth - 2) {
              if (!user['user']['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]]) {
                user['user']['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = user['user'][month][colAndMonth]
              } else {
                user['user']['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = user['user']['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] + user['user'][month][colAndMonth]
              }
            }

            if (!user['user']['ACAno'][this.siglas[c]]) {
              user['user']['ACAno'][this.siglas[c]] = user['user'][month][colAndMonth]
            } else {
              user['user']['ACAno'][this.siglas[c]] = user['user']['ACAno'][this.siglas[c]] + user['user'][month][colAndMonth]
            }
          })

          delete user['user'][month]
        })


      user['items'].forEach(item => {
        item['AC'+this.monthspt[this.atualMonth - 2]] = {}
        item['ACAno'] = {}
        this.months.forEach( (month, m) => {
          item[this.monthspt[m]] = {}
          this.cols.forEach( (col, c) => {
            var colAndMonth = [col]+'_'+[month]
            item[this.monthspt[m]][this.siglas[c]] = item[colAndMonth]

            if (m <= this.atualMonth - 2) {
              if (!item['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]]) {
                item['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = item[colAndMonth]
              } else {
                item['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = item['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] + item[colAndMonth]
              }
            }

            if (!item['ACAno'][this.siglas[c]]) {
              item['ACAno'][this.siglas[c]] = item[colAndMonth]
            } else {
              item['ACAno'][this.siglas[c]] = item['ACAno'][this.siglas[c]] + item[colAndMonth]
            }

            delete item[colAndMonth]

          })
        })
        item['subitems'].forEach(subitem => {
          subitem['AC'+this.monthspt[this.atualMonth - 2]] = {}
          subitem['ACAno'] = {}
          this.months.forEach( (month, m) => {
            subitem[this.monthspt[m]] = {}
            this.cols.forEach( (col, c) => {
              var colAndMonth = [col]+'_'+[month]
              subitem[this.monthspt[m]][this.siglas[c]] = subitem[colAndMonth]
              if (m <= this.atualMonth - 2) {
                if (!subitem['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]]) {
                  subitem['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = subitem[colAndMonth]
                } else {
                  subitem['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = subitem['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] + subitem[colAndMonth]
                }
              }

              if (!subitem['ACAno'][this.siglas[c]]) {
                subitem['ACAno'][this.siglas[c]] = subitem[colAndMonth]
              } else {
                subitem['ACAno'][this.siglas[c]] = subitem['ACAno'][this.siglas[c]] + subitem[colAndMonth]
              }
              delete subitem[colAndMonth]
            })
          })

          subitem['nofs'].forEach( nof => {
            nof['AC'+this.monthspt[this.atualMonth - 2]] = {}
            nof['ACAno'] = {}
            this.months.forEach( (month, m) => {
              nof[this.monthspt[m]] = {}
              this.cols.forEach( (col, c) => {
                var colAndMonth = [col]+'_'+[month]
                nof[this.monthspt[m]][this.siglas[c]] = nof[colAndMonth]
                if (m <= this.atualMonth - 2) {
                  if (!nof['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]]) {
                    nof['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = nof[colAndMonth]
                  } else {
                    nof['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] = nof['AC'+this.monthspt[this.atualMonth - 2]][this.siglas[c]] + nof[colAndMonth]
                  }
                }
                if (!nof['ACAno'][this.siglas[c]]) {
                  nof['ACAno'][this.siglas[c]] = nof[colAndMonth]
                } else {
                  nof['ACAno'][this.siglas[c]] = nof['ACAno'][this.siglas[c]] + nof[colAndMonth]
                }
                delete nof[colAndMonth]
              })
            })
          });
        });
      })
    })
    })

    // calculo de media ponderada
    this.items.forEach(aba => {
      this.list[aba.text.toLowerCase()].forEach(user => {
        user.items.forEach(centroDeCusto => {
          centroDeCusto.subitems.forEach(agrupadorNof => {
            if (!this.group_nofs.includes(agrupadorNof.title.toUpperCase())){
              Object.keys(agrupadorNof).forEach(key => {
                if (key != 'title' && key != 'nofs' && key != 'hierarchyIds') {

                  var soma_absolutoXasertividade = 0
                  var calculo = 0

                  agrupadorNof.nofs.forEach(nof => {
                    nof[key]['A'] = this.assertiveness_calc(nof[key]['PR'], nof[key]['R'])
                    var absolute = Math.abs(nof[key]['R']) > Math.abs(nof[key]['PR']) ? Math.abs(nof[key]['R']) : Math.abs(nof[key]['PR'])
                    calculo += (absolute * nof[key]['A'])
                    soma_absolutoXasertividade += Math.abs(nof[key]['R']) > Math.abs(nof[key]['PR']) ? Math.abs(nof[key]['R']) : Math.abs(nof[key]['PR'])
                  })
                  agrupadorNof[key]['A'] = isNaN(calculo / soma_absolutoXasertividade ) ? 0 : calculo / soma_absolutoXasertividade
                }
              })
            } else {
              Object.keys(agrupadorNof).forEach(key => {
                if (key != 'title' && key != 'nofs' && key != 'hierarchyIds') {
                  agrupadorNof.nofs.forEach(nof => {
                    nof[key]['A'] = this.assertiveness_calc(nof[key]['PR'], nof[key]['R'])
                  })
                  agrupadorNof[key]['A'] = this.assertiveness_calc(agrupadorNof[key]['PR'], agrupadorNof[key]['R'])
                }
              })
            }
          });

          Object.keys(centroDeCusto).forEach(key => {
            if (key != 'id' && key != 'title' && key != 'subitems' && key != 'hierarchyIds') {
              var soma_absolutoXasertividade = 0
              var calculo = 0

              centroDeCusto.subitems.forEach(agrupadorNof => {
                  if (key != 'title' && key != 'nofs' && key != 'hierarchyIds') {
                    var absolute = Math.abs(agrupadorNof[key]['R']) > Math.abs(agrupadorNof[key]['PR']) ? Math.abs(agrupadorNof[key]['R']) : Math.abs(agrupadorNof[key]['PR'])
                    calculo += (absolute * agrupadorNof[key]['A'])
                    soma_absolutoXasertividade += Math.abs(agrupadorNof[key]['R']) > Math.abs(agrupadorNof[key]['PR']) ? Math.abs(agrupadorNof[key]['R']) : Math.abs(agrupadorNof[key]['PR'])

                  }
              });

              centroDeCusto[key]['A'] = isNaN(calculo / soma_absolutoXasertividade ) ? 0 : calculo / soma_absolutoXasertividade

            }
          })

        });

        Object.keys(user.user).forEach(key => {
          if (key != 'name' && key != 'hierarchyIds') {
            var soma_absolutoXasertividade = 0
            var calculo = 0

            user.items.forEach(centroDeCusto => {
              if (key != 'id' && key != 'title' && key != 'subitems' && key != 'hierarchyIds'){
                var absolute = Math.abs(centroDeCusto[key]['R']) > Math.abs(centroDeCusto[key]['PR']) ? Math.abs(centroDeCusto[key]['R']) : Math.abs(centroDeCusto[key]['PR'])
                calculo += (absolute * centroDeCusto[key]['A'])
                soma_absolutoXasertividade += Math.abs(centroDeCusto[key]['R']) > Math.abs(centroDeCusto[key]['PR']) ? Math.abs(centroDeCusto[key]['R']) : Math.abs(centroDeCusto[key]['PR'])
              }
            })

            user.user[key]['A'] = isNaN(calculo / soma_absolutoXasertividade ) ? 0 : calculo / soma_absolutoXasertividade
          }
        })
      });
    })
  }

  getList(index: number) {
    if(index === 0) {
      return this.list['contabil'];

    } else if (index === 1) {
      return this.list['financeiro'];

    }
  }

}
