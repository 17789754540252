import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { id_ID } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class RealizedBudgetHierarchyService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getRealizedBudgetHierarchy(year){
    return this.http.get(environment.baseApiUrl + 'realized-budget-hier/'+year);
  }

  Download(year, title) {
    return this.http.get(environment.baseApiUrl + 'realized-budget-hier/'+ year + '/report/'+title, { responseType: 'blob' })
  }
}
