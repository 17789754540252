import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedEffects } from './shared.effects';
import { reducer as SharedReducer } from './shared.reducer';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasAnyPermissionDirective } from './directives/has-any-permission.directive';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { NgZorroAntdModule, NzBreadCrumbModule } from 'ng-zorro-antd';
import { DialogComponent } from './components/dialog/dialog.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AreaMapsComponent } from './components/area-maps/area-maps.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import { RegisterAreaComponent } from './components/register-area/register-area.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { RegisterPersonComponent } from './components/register-person/register-person.component';
import { ModalComponent } from './components/modal/modal.component';
import { DisabledFieldDirective } from './directives/disabled-field.directive';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';

const maskConfigFunction: Partial<IConfig> = {
    thousandSeparator: '.',
    decimalMarker: ',',
};

const currencyMaskConfig = {
    align: "left",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: ".",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};
@NgModule({
  declarations: [
    HasPermissionDirective,
    HasAnyPermissionDirective,
    LoadingComponent,
    DialogComponent,
    MasterPageComponent,
    BreadcrumbComponent,
    AreaMapsComponent,
    RegisterAreaComponent,
    RegisterPersonComponent,
    ModalComponent,
    DisabledFieldDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzMenuModule,
    NgZorroAntdModule,
    NzBreadCrumbModule,
    EffectsModule.forFeature([ SharedEffects ]),
    StoreModule.forFeature('shared', SharedReducer),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxCurrencyModule.forRoot(currencyMaskConfig),
  ],
  exports: [
    HasPermissionDirective,
    HasAnyPermissionDirective,
    DisabledFieldDirective,
    LoadingComponent,
    DialogComponent,
    AreaMapsComponent,
    RegisterAreaComponent,
    RegisterPersonComponent,
    ModalComponent
  ]
})
export class SharedModule { }
