import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PessoasService } from '../../services/pessoas.service';
import { ComboService } from 'src/app/shared/services/combo.service';
import { NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { PermissionService } from '../../../shared/services/permission.service'
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-listagem-pessoas',
  templateUrl: './listagem-pessoas.component.html',
  styleUrls: ['./listagem-pessoas.component.scss']
})
export class ListagemPessoasComponent implements OnInit {

  switchValue = false;
  idPerson;
  filter

  typeFiltersList = [];
  option = 0;
  mask = "000.000.000.00"

  page = 1;
  listPerson: any;
  listComboPerson: any;
  id: any;
  totalitens: any;

  constructor(private router: Router,
    private service: PessoasService,
    private serviceShared: ComboService,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.getCombos();
    this.service.GetPerson(this.page).subscribe((response: any) => {
      this.listPerson = response.data;
      this.totalitens = response.total;
    });
  }

  getCombos() {
    this.typeFiltersList =  [
      {id: 0, desc: 'Selecione...'},
      {id: 1, desc: 'Nome'},
      {id: 2, desc: 'CPF'},
      {id: 3, desc: 'Contrato Vinculado'}
    ];
    this.serviceShared.ComboPerson().subscribe((response: any) => {
      this.listComboPerson = response.data;
    });
  }

  getFirstContractOfPerson(person) {
    return person.contracts ? person.contracts[0] : null;
  }

  checkPermission(operation) {
    return this.permissionService.checkPermission('people', operation)
  }

  downloadCSV() {
    this.service.Download().subscribe( (response: any) => {
      const csvName = 'Relatorio_pessoas.csv';
      var blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(blob, csvName);
    });
  }

  setPage(page) {
    this.page = page;
    this.SearchPerson();
  }

  SearchPerson() {
    const filter = this.getFilter();
    const page = this.GetPage();

    this.service.GetPerson(page, filter).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listPerson = response.data;
        this.totalitens = response.total;
      } else {
        this.listPerson = [];
        this.totalitens = 0;
        this.page = 1;
        this.notification.blank('Pessoa não encontrada', '');
      }
    });
  }

  GetPage() {
    return {
      page: this.page
    };
  }

  getFilter() {
    if (!this.filter) {
      return { };
    }
    if (this.option === 1) {
      return {
        name: this.filter
      };
    }
    if (this.option === 2) {
      return {
        cpf: this.filter
      };
    }
    if (this.option === 3) {
      return {
        contract_code: this.filter
      };
    }
  }

  GetId(id) {
    this.id = id;
  }

  View() {
    this.router.navigateByUrl('cadastro/form-cadastro-pessoas/view/' + this.id);
  }

  Editar(): void {
    this.router.navigateByUrl('cadastro/form-cadastro-pessoas/' + this.id);
  }

  Deletar() {
    this.modal.confirm({
      nzTitle: 'Deseja excluir o usuário?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => this.service.DeletePerson(this.id).subscribe((response: any) => {
        if (response.success === true) {
          this.notification.success('Usuário deletado com sucesso', '');
          window.location.reload();
        }
      }),
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
