import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private tokenService: TokenService) {}

  getPermissions() {
    const payload = this.tokenService.decodePayloadJWT();

    if (!payload) {
      return [];
    }

    return payload.permissions || [];
  }

  can(checkPermissions) {

    if (!Array.isArray(checkPermissions)) {
      checkPermissions = [checkPermissions];
    }

    let userPermissions = this.getPermissions();
    if (!Array.isArray(userPermissions)) {
      userPermissions = [userPermissions];
    }

    let permitido = false;
    checkPermissions.forEach(c => {
      userPermissions.forEach(u => {
        if (c == u) {
          permitido = true;
        }
      })
    })


    return permitido;
  }
  canAny(checkPermissions) {
    if (!Array.isArray(checkPermissions)) {
      checkPermissions = [checkPermissions];
    }
    const userPermissions = this.getPermissions();
    return !!checkPermissions.find(permission => userPermissions.includes(permission));
  }

  hasHierarchyInTheCurrentBudget() {
    //const payload = this.tokenService.decodePayloadJWT();
    //return payload.hasHierarchyInTheCurrentBudget;
    return true;
  }

  checkPermission(page, operation) {
    const usertoken = this.tokenService.decodePayloadJWT();
    const userpermissions = usertoken.permissions;
    const pagepermissions = {
                              'user': {'view': 9, 'edit': 9, 'delete': 9, 'register': 9},
                              'position': {'view': 9, 'edit': 9, 'delete': 9, 'register': 9},
                              'people': {'view': 6, 'edit': 5, 'delete': 5, 'register': 5},
                              'area': {'view': 4, 'edit': 3, 'delete': 3, 'register': 3, 'pre_approval': 2},
                              'contract': {'view': 7,'load': 23}
                            }
    return userpermissions.includes(pagepermissions[page][operation])
  }








}
