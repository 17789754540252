export enum Status {
    Pendente = 1,
    Aprovado = 2,
    Reprovado = 3,
    Cancelado = 4
}

export enum StatusIntegracao {
    Andamento = 'Em Andamento',
    Erro = 'Erro',  
    Finalizada = 'Finalizada',
}
