import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoricoMinhaSolicitacaoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  Historic(id) {
    return this.http.get(environment.baseApiUrl + 'requisition/history/' + id);
  }
}