import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { YearPanelComponent } from 'ng-zorro-antd/date-picker/lib/year/year-panel.component';

@Injectable({
  providedIn: 'root'
})
export class PlanningPeriodService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  GetPlaningPeriod(page) {
    return this.http.get(environment.baseApiUrl + 'planning-period/?page='+page);
  }

  GetPlaningPeriods() {
    return this.http.get(environment.baseApiUrl + 'planning-period/all');
  }

  startPeriod(year, data) {
    return this.http.put(environment.baseApiUrl + 'planning-period/start/'+year, data);
  }

  restartPeriod(year, data) {
    return this.http.put(environment.baseApiUrl + 'planning-period/reopen/'+year, data);
  }

  getRunningPeriod() {
    return this.http.get(environment.baseApiUrl + 'planning-period/running');
  }
}
