import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendenciaRoutingModule } from './pendencia-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NzNotificationModule, NzButtonModule, NzInputModule, NzTableModule, NzIconModule, NzFormModule, NzToolTipModule, NzResultModule, NzSelectModule, NzPopoverModule, NzPaginationModule, NzModalModule } from 'ng-zorro-antd';
import { ListagemPendenciaComponent } from './pages/listagem-pendencia/listagem-pendencia.component';
import { HistoricoPendenciaComponent } from './components/historico-pendencia/historico-pendencia.component';



@NgModule({
  declarations: [ListagemPendenciaComponent, HistoricoPendenciaComponent],
  imports: [
    CommonModule,
    PendenciaRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzNotificationModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    NzIconModule,
    NzFormModule,
    NzToolTipModule,
    NzResultModule,
    NzSelectModule,
    NzPopoverModule,
    NzPaginationModule,
    NzModalModule,
  ]
})
export class PendenciaModule { }
