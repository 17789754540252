import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import Permissions from '../../../../app/shared/enums/permissions.enum';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { HierarchiService } from '../../services/hierarchi.service';
import { PlanningPeriodService } from '../../services/planning-period.service';
const FileSaver = require('file-saver');
@Component({
  selector: 'app-gestao-de-orcamento',
  templateUrl: './gestao-de-orcamento.component.html',
  styleUrls: ['./gestao-de-orcamento.component.scss']
})
export class GestaoDeOrcamentoComponent implements OnInit {
  permissionsForPlanning= [
    Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO
  ];

  periodStatus = { 1: 'Aberto', 2: 'Fechado', 3: 'Em execução' };
  listOfData = [];
  attachment = []
  page = 1;
  totalitens: any;
  year
  yearPosition
  startDate = [];
  endDate = [];
  atualyear = parseInt(moment(new Date(), 'YYYY/MM/DD').format('y'));

  constructor(private hierarchiService: HierarchiService,
    private planningPeriodService: PlanningPeriodService,
    private notification: NzNotificationService,
    private modal: NzModalService) { }

  ngOnInit(): void {
    this.getPlanings();
  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  getPlanings() {
    this.planningPeriodService.GetPlaningPeriod(this.page).subscribe((response: any) => {
      this.totalitens = response.total;
      response.data.forEach( item => {
        this.attachment.push(undefined)
        if (item.start_date) {
          item.start_date = moment(item.start_date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY')
        }
        if (item.final_date) {
          item.final_date = moment(item.final_date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY')
        }
      })
      this.listOfData = response.data;
    })
  }

  setYear(year, i) {
    this.year = year;
    this.yearPosition = i;
  }

  startPeriod(year, i) {
    this.modal.confirm({
      nzTitle: 'Deseja Iniciar o Periodo ?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => {
        if (!this.listOfData[i]['have_hierarchy']) {
          this.notification.warning('é necessario o upload da estrutura orçamentaria', '');
        }
        if (this.startDate[i] == undefined || this.endDate[i] == undefined) {
          this.notification.warning('Todas as datas do ano '+ year +' precisam estar preenchidas', '');
        } else if (moment(this.startDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD') == "Invalid date" || moment(this.endDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD') == "Invalid date") {
          this.notification.warning('Existem datas invalidas', '');
        } else if (moment(moment(this.startDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD')).isAfter(moment(this.endDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD'))) {
          this.notification.warning('A data de inicio não pode ser superior a do fim do periodo', '');
        } else {
          let data = {
            'start_date': moment(this.startDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD'),
            'final_date': moment(this.endDate[i].replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD'),
          }
          this.planningPeriodService.startPeriod(year, data).subscribe((response: any) => {
            this.notification.success(response.data, '');
            this.page = 1
            this.getPlanings()
          })
        }
      },
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  restartPeriod(year) {
    this.modal.confirm({
      nzTitle: 'Deseja Reiniciar o Periodo ?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.planningPeriodService.restartPeriod(year, {}).subscribe( (response: any) => {
          this.page = 1
          this.getPlanings()
        })
      },
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  postPlaning(year) {
    const formData = new FormData();
    formData.append('file', this.attachment[this.yearPosition][0]);
    this.hierarchiService.UploadPlaningPerios(formData, year).subscribe((response: any) => {
      this.page = 1
      this.getPlanings()
    });
  }

  downloadPlaning(year) {
    this.hierarchiService.DownloadPlaningPerios(year).subscribe((response: any) => {
      const csvName = 'ESTRUTURA_ORCAMENTARIA_'+ year + '.xlsx';
      FileSaver.saveAs(response, csvName);
    });
  }

  beforeUploadAnexo = (file: any): boolean => {
    if (!file.name.includes('.xlsx')) {
      this.notification.warning("Arquivo não possui formato valido","")
    } else {
      this.attachment[this.yearPosition] = [file];
      this.postPlaning(this.year)
      return false;
    }
  }

  setPage(page) {
    this.page = page;
    this.getPlanings();
  }

  canRestart(i) {
    this.atualyear
    var lineYear = parseInt(this.listOfData[i].year)
    if (lineYear == this.atualyear || lineYear == this.atualyear + 1) {
      return true;
    }
    return false;
  }

  showyear(year) {
    var atualYear = moment().year()
    if (parseInt(year) <= atualYear + 1) {
      return true
    } 
    return false
  }
}
