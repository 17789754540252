import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectLoading, selectShowAlert } from '../../shared.selectors';
import { Logout } from 'src/app/auth/auth.actions';
import { AuthState } from 'src/app/auth/auth.reducer';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { UtilsService } from '../../services/utils.service';
import Permissions from '../../enums/permissions.enum';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent implements OnInit {

  public showLoading$ = this.state.pipe(select(selectLoading));
  public showAlert$ = this.state.pipe(select(selectShowAlert));
  public showUser$ = this.state.pipe(select(selectAuthUser));
  isCollapsed = false;
  nameSplited = null;
  user = null;
  notification = 0;
  showLogo: boolean = true;

  permissionsForPendencies = [
    Permissions.APROVACAO_ENGENHARIA_CONTRATO,
    Permissions.APROVACAO_ENGENHARIA_RESILICAO,
    Permissions.APROVACAO_DIRETORIA_COMERCIAL_CONTRATO,
    Permissions.APROVACAO_JURIDICO_CONTRATO,
    Permissions.APROVACAO_JURIDICO_ADTIVO_CONTRATUAL,
    Permissions.APROVACAO_JURIDICO_DADOS_BANCARIOS,
    Permissions.APROVACAO_JURIDICO_RESILICAO
  ];

  constructor(private state: Store<AuthState>,
              private permissionService: PermissionService,
              private serviceUtils: UtilsService) { }

  ngOnInit() {
    this.showUser$.subscribe((user: any) => {
      this.user = user;
      this.user.nameSplited = (this.user.name || ' ').split(' ')[0];
    });

    const hasPermission = this.permissionService.canAny(this.permissionsForPendencies);
    if (hasPermission) {
      this.serviceUtils.Pendencies().subscribe((response: any) => {
        this.notification = response.data.pendent;
      });
    }

  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  logout() {
    this.state.dispatch(new Logout({ error: '' }));
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.showLogo = !this.showLogo;
  }

  getNumOfPendencies() {
    return this.notification != 0 ? 'Pendências ('+this.notification+')' : 'Pendências'
  }

  hasHierarchyInTheCurrentBudget() {
    return this.permissionService.hasHierarchyInTheCurrentBudget()
  }

}
