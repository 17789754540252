import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public user: string = 'user/';

  constructor(private http: HttpClient, private utils: UtilsService) {}

  GetAllUser(params?, filters?) {
    return this.http.get(environment.baseApiUrl + 'user/' + this.utils.getQueryString({ ...params, ...filters }));
  }

  GetOnlyOneUser(id) {
    return this.http.get(environment.baseApiUrl + this.user + id);
  }

  PostUser(data) {
    return this.http.post(environment.baseApiUrl + 'user/', data);
  }

  PutUser(id, data) {
    return this.http.put(environment.baseApiUrl + 'user/' + id, data);
  }

  DeleteUser(id) {
    return this.http.delete(environment.baseApiUrl + 'user/' + id);
  }

  GetTablePermission() {
    return this.http.get(environment.baseApiUrl + 'permission/');
  }

  GetCombo() {
    return this.http.get(environment.baseApiUrl + 'project/combo');
  }
}
