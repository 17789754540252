import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { id_ID } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  constructor(private http: HttpClient, private utils: UtilsService) { }
  getPlanning(year, query) {
    query = query.replace(query.charAt(0),'?')
    return this.http.get(environment.baseApiUrl + 'planning/'+year + query);
  }

  uploadPlanning(year, data) {
    return this.http.post(environment.baseApiUrl + 'planning/upload/'+year, data);
  }

  downloadPlanning(year) {
    return this.http.get(environment.baseApiUrl + 'planning/download/'+year, { responseType: 'blob' });
  }

  validatePlanning(id, data) {
    return this.http.put(environment.baseApiUrl + 'planning/validate/'+id, data);
  }

  getBussinesDay(){
    return this.http.get(environment.baseApiUrl + 'planning/projected-values');
  }

  sendProjectedEmail() {
    return this.http.post(environment.baseApiUrl + 'planning/projected-values-email', {})
  }
}
