import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NovoContratoService } from '../../services/novo-contrato.service';
import { ReplanningRequisitionService } from '../../services/replanning-requisition.service';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-replanning',
  templateUrl: './replanning.component.html',
  styleUrls: ['./replanning.component.scss']
})
export class ReplanningComponent implements OnInit {
  requisitionId
  listOfData
  file 

  constructor(private replanningRequisitionService: ReplanningRequisitionService,
    private novoContratoService: NovoContratoService,
    private router: Router,
    private route: ActivatedRoute,) { 
      const urlParams = this.getParamsUrl(this.route);
      this.requisitionId = urlParams.id;
    }

  ngOnInit(): void {
    this.getRequisition()
  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;
    route.params.subscribe(params => {
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

  getRequisition() {
    this.replanningRequisitionService.getReplanningRequisition(this.requisitionId).subscribe( (response: any) => {
      this.file = response.data.file;
    })
    this.novoContratoService.requisitionDetails('S', this.requisitionId).subscribe((response : any) => {
      this.listOfData = response.data
      this.listOfData.date = moment(this.listOfData.date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY')
    })
  }

  approveRequisition() {
    this.replanningRequisitionService.ReplanningAprove(this.requisitionId, this.listOfData.actual_step, {approved: true, justification: 'aprovacao automatica'}).subscribe( (response: any) => {
      this.router.navigate(['/solicitacao/minhas-solicitacoes/']);
    })
  }

  repproveRequisition() {
    this.replanningRequisitionService.ReplanningAprove(this.requisitionId, this.listOfData.actual_step, {approved: false, justification: 'reprovação automatica'}).subscribe( (response: any) => {
      this.router.navigate(['/solicitacao/minhas-solicitacoes/']);
    })
  }

  closeWindow() {
    this.router.navigate(['/orcamento/orcamento-corrente']);
  }

  downloadProjected() {
    this.replanningRequisitionService.DownloadReplanningArchive(this.file.filename, {uuid: this.file.uuid_file}).subscribe( (response : any) => {
      const csvName = 'TEMPLATE_PROJEÇAO.xlsx';
      FileSaver.saveAs(response, csvName);
    })
  }

  getUserInfo(field) {
    if (field == 'name') {
      return  this.listOfData ? this.listOfData.user.name :  ''
    }
    if (field == 'email') {
      return  this.listOfData ? this.listOfData.user.email :  ''
    }
    if (field == 'date') {
      return  this.listOfData ? this.listOfData.date :  ''
    }
  }

}
