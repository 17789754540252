import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComboService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  ComboState() {
    return this.http.get(environment.baseApiUrl + 'state/combo');
  }

  ComboCity(id) {
    return this.http.get(environment.baseApiUrl + 'city/combo/' + id);
  }

  ComboGender() {
    return this.http.get(environment.baseApiUrl + 'gender/combo');
  }

  ComboBank() {
    return this.http.get(environment.baseApiUrl + 'bank/combo');
  }

  ComboMarital() {
    return this.http.get(environment.baseApiUrl + 'marital-status/combo');
  }

  ComboAreaStatus() {
    return this.http.get(environment.baseApiUrl + 'area-status/combo');
  }

  ComboAreaSituation() {
    return this.http.get(environment.baseApiUrl + 'area-situation/combo');
  }

  ComboProject(filter?) {
    return this.http.get(environment.baseApiUrl + 'project/combo' + this.utils.getQueryString({ ...filter }));
  }

  ComboPerson(filter?) {
    if(filter) {
      return this.http.get(environment.baseApiUrl + 'contract-person/combo' + this.utils.getQueryString({ ...filter }));
    } else {
      return this.http.get(environment.baseApiUrl + 'contract-person/combo');
    }
  }

  ComboArea() {
    return this.http.get(environment.baseApiUrl + 'area/combo');
  }

  ComboAreaSemContrato() {
    return this.http.get(environment.baseApiUrl + 'area/combo-sem-contrato');
  }

  ComboCompetitor() {
    return this.http.get(environment.baseApiUrl + 'competitor-type/combo');
  }

  ComboContract() {
    return this.http.get(environment.baseApiUrl + 'contract-type/combo');
  }

  comboContractStatus() {
    return this.http.get(environment.baseApiUrl + 'contract-status/combo');
  }

  ComboReasonNewContract() {
    return this.http.get(environment.baseApiUrl + 'contract-reason/combo');
  }

  ComboSituation() {
    return this.http.get(environment.baseApiUrl + 'area-situation/combo');
  }

  ComboCompetitionType() {
    return this.http.get(environment.baseApiUrl + 'competitor-type/combo');
  }

  ComboRemunerationPreOperation() {
    return this.http.get(environment.baseApiUrl + 'remuneration-type/combo');
  }

  ComboInflationIndex() {
    return this.http.get(environment.baseApiUrl + 'inflation-index/combo');
  }

  ComboFrequency() {
    return this.http.get(environment.baseApiUrl + 'frequency/combo');
  }

  ComboRemunerationCommercialOperation() {
    return this.http.get(environment.baseApiUrl + 'remuneration-wind-turbine-type/combo');
  }

  ComboStateRequisition() {
    return this.http.get(environment.baseApiUrl + 'requisitionstate/combo');
  }

  ComboRequisitionSituation() {
    return this.http.get(environment.baseApiUrl + 'requisition-situation/combo');
  }

  ComboRequisitionState() {
    return this.http.get(environment.baseApiUrl + 'requisition-state/combo')
  }

  ComboTypeRequisition() {
    return this.http.get(environment.baseApiUrl + 'requisition-type/combo');
  }

  ComboContrato() {
    return this.http.get(environment.baseApiUrl + 'contract/combo?rescission');
  }

  comboContractBankData() {
    return this.http.get(environment.baseApiUrl + 'contract/combo?bank_data');
  }

  ComboReasonResilicao() {
    return this.http.get(environment.baseApiUrl + 'rescission-reason/combo');
  }

  ComboDocImovel() {
    return this.http.get(environment.baseApiUrl + 'area-type-document/combo');
  }

  ComboAditiveReason() {
    return this.http.get(environment.baseApiUrl + 'additive-reason/combo')
  }

  getContracts() {
    return this.http.get(environment.baseApiUrl + 'contract/combo');
  }

  getPerson(id) {
    return this.http.get(environment.baseApiUrl + 'contract-person/'+id)
  }
}
