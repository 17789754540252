import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  constructor(private http: HttpClient, private utils: UtilsService) {}
  getAreaByCode(code) {
    return this.http.get(environment.baseApiUrl + 'area/code/'+code);
  }

  GetArea(page, filter?) {
    return this.http.get(environment.baseApiUrl + 'area/' + this.utils.getQueryString({ ...page, ...filter }));
  }

  SearchArea(filter) {
    return this.http.get(environment.baseApiUrl + 'area/combo' + this.utils.getQueryString({ ...filter }));
  }

  GetOnlyOneArea(id) {
    return this.http.get(environment.baseApiUrl + 'area/' + id);
  }


  GetComboStatus() {
    return this.http.get(environment.baseApiUrl + 'area-status/combo');
  }

  PostArea(data) {
    return this.http.post(environment.baseApiUrl + 'area/', data);
  }

  PutArea(id, data) {
    return this.http.put(environment.baseApiUrl + 'area/' + id, data);
  }

  DeleteArea(id) {
    return this.http.delete(environment.baseApiUrl + 'area/' + id);
  }

  GetReport() {
    return this.http.get(environment.baseApiUrl + 'area/report');
  }

  PermissionArea(id, status_id) {
    return this.http.put(environment.baseApiUrl + 'area/status/' + id, status_id);
  }

  PostAreaUpload(code, data) {
    return this.http.post(environment.baseApiUrl + 'area/upload/' + code, data);
  }

  PostDownload(filename, uuid) {
    return this.http.post(environment.baseApiUrl + 'area/download/' + filename, uuid, { responseType: 'blob' });
  }

  Download() {
    return this.http.get(environment.baseApiUrl + 'area/report', { responseType: 'blob' })
  }

  GetMap(id_project) {
    return this.http.get(environment.baseApiUrl + 'area/geofile/download/' + id_project, { responseType: 'blob' });
  }

  PostUpload(file) {
    return this.http.post(environment.baseApiUrl + 'file/upload', file);
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

}
