import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NzNotificationModule, NzButtonModule, NzInputModule, NzTableModule, NzIconModule, NzPopoverModule, NzFormModule, NzSelectModule, NzToolTipModule, NzPaginationModule, NzModalModule, NzResultModule } from 'ng-zorro-antd';
import { CadastroUsuarioComponent } from './pages/cadastro-usuario/cadastro-usuario.component';
import { AdministrativoRoutingModule } from './administrativo-routing.module';
import { FormCadastroUsuarioComponent } from './pages/form-cadastro-usuario/form-cadastro-usuario.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ListagemSolicitacaoComponent } from './pages/listagem-solicitacao/listagem-solicitacao.component';
import { HistoricoAprovacaoComponent } from './components/historico-aprovacao/historico-aprovacao.component';



@NgModule({
  declarations: [CadastroUsuarioComponent, FormCadastroUsuarioComponent, ListagemSolicitacaoComponent, HistoricoAprovacaoComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzNotificationModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    NzIconModule,
    NzFormModule,
    NzToolTipModule,
    NzResultModule,
    NzSelectModule,
    NzPopoverModule,
    NzPaginationModule,
    NzModalModule,
    AdministrativoRoutingModule,
    NgxMaskModule.forRoot()
  ]
})
export class AdministrativoModule { }
