import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from '../shared/pages/master-page/master-page.component';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ListagemPendenciaComponent } from './pages/listagem-pendencia/listagem-pendencia.component';
import { AnyPermissionsGuard } from '../shared/guards/any-permissions.guard';
import Permissions from '../shared/enums/permissions.enum';

const routes: Routes = [
  {
    path: 'pendencia',
    component: MasterPageComponent,
    children: [
      {
        path: 'pendencias',
        component: ListagemPendenciaComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Pendências',
          permissions: [
                        Permissions.APROVACAO_ENGENHARIA_CONTRATO,
                        Permissions.APROVACAO_ENGENHARIA_RESILICAO,
                        Permissions.APROVACAO_DIRETORIA_COMERCIAL_CONTRATO,
                        Permissions.APROVACAO_JURIDICO_CONTRATO,
                        Permissions.APROVACAO_JURIDICO_ADTIVO_CONTRATUAL,
                        Permissions.APROVACAO_JURIDICO_DADOS_BANCARIOS,
                        Permissions.APROVACAO_JURIDICO_RESILICAO,
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO
                      ]
        }
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PendenciaRoutingModule { }