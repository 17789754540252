import { Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild ,AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { radioRequiredValidator } from 'src/app/shared/validators/customvalidator.validator';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-listagem-de-notas-historico',
  templateUrl: './listagem-de-notas-historico.component.html',
  styleUrls: ['./listagem-de-notas-historico.component.scss']
})
export class ListagemDeNotasHistoricoComponent implements OnInit,AfterViewChecked, OnChanges {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  form;
  modalData;
  filtro = "A";
  @Output() sentAnInconsistency = new EventEmitter();
  @Input() listData = [];
  @Input() canEditSolution = false;

  constructor(private fb: FormBuilder,
    private notification: NzNotificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private accountingEntryService: AccountingEntryService) {
    this.form = this.fb.group({
      commercialInvoices: this.fb.array([], [Validators.required]),
    });
  }

  ngOnChanges(changes: any) {
    this.filterHistoric(this.filtro);
  }

  download() {
    this.accountingEntryService.download().subscribe( (response: any) => {
      const xlName = 'Notas.xlsx';
      //const blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(response, xlName);
    });
  }


  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.listData.filter(item => item.status == null || item.status == 1).forEach((data: any) => {
      let commercialInvoice = this.createFormGroup(data)
      this.commercialInvoices.push(commercialInvoice);
    })
  }

  get commercialInvoices(): FormArray {
    return this.form.get('commercialInvoices') as FormArray;
  }

  statusValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }

        if (control.parent.get("status").value > 2 && (control.parent.get("message").value == null || control.parent.get("message").value == "")) {
          return { required: true };
        }

        return null;
    }
}



  createFormGroup(data): FormGroup {
    return this.fb.group({
      colligate: this.fb.control({ value: data.colligate, disabled: false }, [Validators.nullValidator]),
      cost_center: this.fb.control({ value: data.cost_center_name, disabled: false }, [Validators.nullValidator]),
      nof: this.fb.control({ value: data.nof, disabled: false }, [Validators.nullValidator]),
      provider: this.fb.control({ value: data.provider, disabled: false }, [Validators.nullValidator]),
      hierarchy_id: this.fb.control({ value: data.hierarchy_id, disabled: false }, [Validators.nullValidator]),
      value: this.fb.control({ value: data.value, disabled: false }, [Validators.nullValidator]),
      release_number: this.fb.control({ value: data.release_number, disabled: false }, [Validators.nullValidator]),
      responsible: this.fb.control({ value: data.responsible ? data.responsible : data.user.name, disabled: false }, [Validators.nullValidator]),
      responsible_id: this.fb.control({ value: data.responsible_id, disabled: false }, [Validators.nullValidator]),
      date: this.fb.control({ value: moment(data.date.replace(/-/g, ''), 'YYYYMMDD').format('DD-MM-YYYY'), disabled: false }, [Validators.nullValidator]),
      id: this.fb.control({ value: data.id, disabled: false }, [Validators.nullValidator]),
      document_number: this.fb.control({ value: data.document_number, disabled: false }, [Validators.nullValidator]),
      observation: this.fb.control({ value: data.observation, disabled: false }, [Validators.nullValidator]),
      responsible_email: this.fb.control({ value: data.responsible_email, disabled: false }, [Validators.nullValidator]),
      status: this.fb.control({ value: data.status? data.status.toString() : "1", disabled: !this.canEdit() }),
      message: this.fb.control({ value: data.solution, disabled: !this.canEdit()}, [this.statusValidator()]),
      justification: this.fb.control({ value: data.justification, disabled: true}),
    });
  }

  sendSolution() {
    var forms = this.form.controls["commercialInvoices"];

    forms.controls.forEach(form2 => {
      Object.keys(form2.controls).forEach(field => {
        form2.get(field).setValue(form2.get(field).value);
      });
    });


    if (!this.form.invalid) {
      let data = { 'invoices': [] }
      this.form.getRawValue().commercialInvoices.forEach(item => {
        if (item.status > 1) {
          data.invoices.push({
            id: item.id,
            solution: item.message,
            status: item.status
          })
        }
      })

      this.accountingEntryService.updateinvalidInvoices(data).subscribe((response: any) => {
        this.sentAnInconsistency.emit(true)
      })

    } else {
      for (const i in this.commercialInvoices['controls']) {
        for (const fieldName in this.commercialInvoices['controls'][i]['controls']) {
          if (this.commercialInvoices['controls'][i]['controls'][fieldName].value == null) {
            this.commercialInvoices['controls'][i]['controls'][fieldName].markAsDirty();
            this.commercialInvoices['controls'][i]['controls'][fieldName].updateValueAndValidity();
          }
        }
      }

      this.notification.warning("Verifique os campos Obrigatórios", "")
    }
  }



  viewDetails(invoice) {


    this.modalData = invoice;
    this.modal.showModal();
  }

  hideModal() {
    this.modal.hideModal();
  }


  hasMessage() {

    if (!this.canEditSolution)
      return false;

    if (this.filtro != "A" )
      return false;

    let result = false
    this.form.getRawValue().commercialInvoices.forEach(item => {
      if (item.status == "2" ||  (item.status == "3")) {
        result = true;
      }
    });
    return result;
  }

  formatFloatNumber(value) {
    if (value == 0)
      return "-";
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2});
    return formatter.format(value);
  }

  filterHistoric(id) {

    this.commercialInvoices.clear();
    this.filtro = id;

    if (id == "A")
      this.listData.filter(item => item.status == null || item.status == 1).forEach((data: any) => {
        let commercialInvoice = this.createFormGroup(data)
        this.commercialInvoices.push(commercialInvoice);
      })
    else if (id == "F")
      this.listData.filter(item => item.status == 3 || item.status == 2).forEach((data: any) => {
        let commercialInvoice = this.createFormGroup(data)
        this.commercialInvoices.push(commercialInvoice);
      })
    else if (id == "T")
      this.listData.forEach((data: any) => {
        let commercialInvoice = this.createFormGroup(data)
        this.commercialInvoices.push(commercialInvoice);
      })


  }


  canEdit() {
    if (!this.canEditSolution)
      return false;

    if (this.filtro == "A")
      return true;
    return false;
  }


}
