import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NovoContratoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getContract(id) {
    return this.http.get(environment.baseApiUrl + 'contract/'+id);
  }

  UpdatePerson(data, id) {
    return this.http.put(environment.baseApiUrl + 'contract-person/' + id, data)
  }

  PostUpload(file, requisition_id) {
    return this.http.post(environment.baseApiUrl + 'new-contract-requisition/upload/'+requisition_id, file);
  }

  downloadFile(name, uuid) {
    return this.http.post(environment.baseApiUrl + 'new-contract-requisition/download/'+name, {'uuid': uuid}, { responseType: 'blob' });
  }

  createContractRequisition(data, is_draft) {
    const draft = '?draft=' + is_draft;
    return this.http.post(environment.baseApiUrl + 'contract/create' + draft, data);
  }

  addAditivo(data, is_draft) {
    const draft = '?draft=' + is_draft;
    return this.http.post(environment.baseApiUrl + 'contract/aditivo' + draft, data);
  }

  addResilicao(data, is_draft) {
    const draft = '?draft=' + is_draft;
    return this.http.post(environment.baseApiUrl + '/contract/resilicao' + draft, data);
  }

  updateContract(id, data, is_draft) {
    const draft = '?draft=' + is_draft;
    return this.http.put(environment.baseApiUrl + 'contract/alter/' + id + draft, data);
  }

  approvalContract(requisition_id, data, is_draft, step) {
    const draft = '&draft=' + is_draft;
    return this.http.post(environment.baseApiUrl + 'new-contract-requisition/approve/' + requisition_id + '?step=' + step + draft, data);
  }

  aprovacao(id, data) {
    return this.http.post(environment.baseApiUrl + 'contract/approve/' + id, data);
  }

  getDetails(id) {
    return this.http.get(environment.baseApiUrl + 'contract/' + id);
  }

  requisitionDetails(tipo, id) {
    return this.http.get(environment.baseApiUrl + 'requisition/' + tipo + "/" + id);
  }

  downloadContract(id) {
    return this.http.get(environment.baseApiUrl + 'new-contract/' + id + '/report', { responseType: 'blob' });
  }

  cancelRequisition(id) {
    return this.http.put(environment.baseApiUrl + 'contract/cancel/' + id, { });
  }

  reintegrate(id) {
    return this.http.get(environment.baseApiUrl + 'additive-requisition/run-integration/'+id);
  }

  getTemplate(id) {
    return this.http.get(environment.baseApiUrl + 'contract/create_contract/' + id);
  }
}
