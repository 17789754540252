import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { LoginRequested, ForgotMyPassword } from '../../auth.actions';
import { selectAuthLoading, selectsendingPassword } from '../../auth.selectors';
import { UtilsService } from '../../../shared/services/utils.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  private emailPattern = '[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+';

  form: FormGroup;

  loading$ = this.store.pipe(select(selectAuthLoading));
  sendingPassword$ = this.store.pipe(select(selectsendingPassword));

  public remember = this.fb.control(null);

  public email = this.fb.control(null, {
    validators: [Validators.required,  Validators.pattern(this.emailPattern)]
  });
  public password = this.fb.control(null, {
    validators: [Validators.required]
  });

  constructor(private fb: FormBuilder, private store: Store<AppState>,  private utilsService: UtilsService, private notification: NzNotificationService) {
    this.form = this.fb.group({
      email: this.email,
      password: this.password,
      remember: false
    });

    const user = this.utilsService.decrypt(localStorage.getItem('login.user'));

    if (user.remember) {
      this.form.controls.email.setValue(user.email);
      this.form.controls.password.setValue(user.password);
    } else {
      localStorage.removeItem('login.user');
    }
  }

  onSubmit() {
    if (!this.form.invalid) {
      this.store.dispatch(new LoginRequested(this.form.value));
    } else {
      this.validationMessages("login")
      Object.keys(this.form.controls).forEach(key => {
        this[key].markAsDirty();
        this[key].updateValueAndValidity();
      });
    }
  }

  forgotMyPassword() {
    if (!this.form.controls.email.invalid) {
      let data = {"email": this.form.controls.email.value}
      this.store.dispatch(new ForgotMyPassword(data))
    } else {
      this.validationMessages("forgotPassword")
      this.form.controls.email.markAsDirty();
      this.form.controls.email.updateValueAndValidity();
    }
  }

  validationMessages(type) {
    if (this.form.controls.password.value == null) {
      this.form.controls.password.setValue("")
    }
    if (this.form.controls.email.value == null) {
      this.form.controls.email.setValue("")
    }
    if(type == "login") {
      if(this.form.controls.password.value.length == 0 && this.form.controls.password.invalid) {
        this.notification.warning("Digite sua senha", "");
      } 
      if("required" in this.form.controls.email.errors  ) {
          this.notification.warning("Digite seu email", "");
      }
    }
    if(type == "login" || type == "forgotPassword") {
      if(this.form.controls.email.value.length > 0 && this.form.controls.email.invalid) {
        this.notification.warning("E-mail possui um formato inválido", "");
      }
    }
    if(type == "forgotPassword") {
      if(this.form.controls.email.value.length == 0 && this.form.controls.email.invalid) {
        this.notification.warning("Preencha o campo e-mail", "");
      }
    }
  }
}
