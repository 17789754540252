export enum MaritalType {
    Solteiro = 1,
    Casado = 2,
    Viuvo = 3
}

export enum GenderType {
    Feminino = 1,
    Masculino = 2
}

export enum AccountType {
    Corrente = 1,
    Poupança = 2
}



