import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HierarchiService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  UploadPlaningPerios(data, year) {
    return this.http.post(environment.baseApiUrl + 'hierarchy/upload/'+ year, data);
  }

  DownloadPlaningPerios(year) {
    return this.http.get(environment.baseApiUrl + 'hierarchy/download/'+ year,  { responseType: 'blob' });
  }
}
