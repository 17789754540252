import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AreasService } from '../../services/areas.service';
import { ComboService } from 'src/app/shared/services/combo.service';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { PreApprovedType } from 'src/app/shared/enums/area.enum';
import { ProjetosService } from '../../services/projetos.service';
declare var require: any
const FileSaver = require('file-saver');

// tslint:disable-next-line: no-unused-expression

@Component({
  selector: 'app-listagem-projetos',
  templateUrl: './listagem-projetos.component.html',
  styleUrls: ['./listagem-projetos.component.scss']
})
export class ListagemProjetosComponent implements OnInit {
  status;
  status_clone;
  switchValue = false;

  visible: boolean;
  listArea: any;
  testando;
  id: any;
  listAreaCombo: any;
  area: any;
  area_clone: any;
  page = 1;
  totalitens: any;
  blob: Blob;

  statusList = [];
  filter: string;


  constructor(private router: Router,
              private service: AreasService,
              private projeto: ProjetosService,
              private serviceShared: ComboService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private permissionService: PermissionService) { }

  ngOnInit(): void {

    this.projeto.GetProjects(this.page).subscribe((response: any) => {
      this.listArea = response.data;
      this.listArea.forEach(area => {
        area['id'] = area.desc ? area.id : null
      });
      this.totalitens = response.total;
    })

  }


  setPage(page) {
    this.page = page;
    this.SearchArea();
  }

  checkPermission(operation) {
    return this.permissionService.checkPermission('area', operation)
  }

  SearchArea() {

    const filter: any = this.GetFilter();
    const filter_clone: any = this.GetFilterClone()

    if(filter_clone.area)
    {
      this.page = 1;
      this.area_clone = this.area;
    }

    const page = this.GetPage();

    if (filter.status !== 0) {
      this.projeto.GetProjects(page, filter).subscribe((response: any) => {
        if (response.data.length > 0) {
          this.listArea = response.data;
          this.listArea.forEach(area => {
            area['id'] = area.desc ? area.id : null
          });
          this.totalitens = response.total;
        } else {
          this.listArea = [];
          this.totalitens = 0;
          this.page = 1;
          this.notification.blank('Projeto não encontrado', '');
        }
      });
    } else {
      this.projeto.GetProjects(this.page).subscribe((response: any) => {
        this.listArea = response.data;
        this.totalitens = response.total;
        this.page = response.pages;
      });
    }

  }


  GetPage() {
    return {
      page: this.page
    };
  }

  GetFilter() {
    return {
      area: this.area,
    };
  }

  GetFilterClone() {
    return {
      area: this.area_clone,
    };
  }

  clearSearch() {
    this.filter = '';
  }

  GetId(id) {
    this.id = id;
  }

  View() {
    this.router.navigateByUrl('cadastro/form-cadastro-projetos/view/' + this.id);
  }

  Editar(): void {
    this.visible = false;
    this.router.navigateByUrl('cadastro/form-cadastro-projetos/' + this.id);
  }

}
