import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { Logout } from 'src/app/auth/auth.actions';
import { AuthState } from 'src/app/auth/auth.reducer';
import { Store, select } from '@ngrx/store';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})


export class CadastroUsuarioComponent implements OnInit {
  public showUser$ = this.state.pipe(select(selectAuthUser));
  edit: boolean = false;
  id: number;
  displayPopover = true;

  logeduser: any
  name = "";
  name_clone = "";
  page = 1;
  totalitens: any;
  listUser: any;

  constructor(private router: Router,
              private service: UsuarioService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private state: Store<AuthState>,
              private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.showUser$.subscribe((user: any) => {
      this.logeduser = user.id;
    });

    this.service.GetAllUser().subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listUser = response.data;
        this.totalitens = response.total;
      } else {
        this.listUser = [];
        this.totalitens = 0;
        this.page = 1;
        this.notification.blank('Solicitação não encontrada', '');
      }
    });
  }

  setPage(page) {
    this.page = page;
    this.SearchUser()
  }

  checkPermission(operation) {
    return this.permissionService.checkPermission('user', operation)
  }

  Pagination() {
    return { page: this.page };
  }

  GetFilter() {
    return { name: this.name }
  }

  GetFilterClone() {
    return { name: this.name_clone }
  }

  SearchUser() {

    const filters = this.GetFilter();
    const filters_clone = this.GetFilterClone();

    if(filters.name !== filters_clone.name)
    {
      this.page = 1;
      this.name_clone = this.name;
    }

    const params = this.Pagination();

    this.service.GetAllUser(params, filters).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listUser = response.data;
        this.totalitens = response.total;
        this.page = response.pages;
      } else {
        this.listUser = [];
        this.totalitens = 0;
        this.page = 1;
        this.notification.blank('Usuário não encontrado', '');
      }
    });

  }

  setId(id) {
    this.id = id;
  }

  chooseDisplayPopover() {
    this.displayPopover = false;
    setTimeout(() => {
      this.displayPopover = true;
    });
  }

  view(): void {
    this.chooseDisplayPopover();
    this.router.navigateByUrl('admin/form-cadastro-usuario/view/' + this.id);
  }

  Editar(): void {
    this.chooseDisplayPopover();
    this.router.navigateByUrl('admin/form-cadastro-usuario/' + this.id);
  }

  logout() {
    this.state.dispatch(new Logout({ error: '' }));
  }

  Deletar(): void {
    this.chooseDisplayPopover();
    this.modal.confirm({
      nzTitle: 'Deseja excluir o usuário?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => this.service.DeleteUser(this.id).subscribe((response:any) => {
        if(response.success === true) {
          this.notification.success('Usuário deletado com sucesso', '');
          if (this.id == this.logeduser) {
            this.logout();
          } else {
            window.location.reload();
          }
        }
      }),
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
