import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AreasService } from '../../../cadastro/services/areas.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { ComboService } from 'src/app/shared/services/combo.service';
// tslint:disable-next-line: max-line-length
import { ReasonNewContractType, RemunerationPreOperationType, RemunerationCommercialOperationType, ApportionmentType } from '../../enums/contract.enum';
import { radioRequiredValidator, requiredIfParentIfdiferentFromNull } from '../../../shared/validators/customvalidator.validator';
// tslint:disable-next-line: max-line-length
import { reasonRequiredValidator, requiredChildPreOperationRemunerationValidator, requiredChildCommercialOperationRemunerationValidator, maritalRequiredValidator, heirListRequiredValidator, partnerDocumentsRequiredValidator, ownerRequiredInHeirListValidator} from '../../validators/customvalidator.validator';
import { AuthState } from 'src/app/auth/auth.reducer';
import { Store, select } from '@ngrx/store';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MaritalType, AccountType } from 'src/app/shared/enums/person.enum';
import { PessoasService } from 'src/app/cadastro/services/pessoas.service';
import { PreApprovedType } from 'src/app/shared/enums/area.enum';
// tslint:disable-next-line: max-line-length
import { ComboDefault, ComboContract, ComboReasonNewContract, ComboRemunerationPreOperation, ComboFrequency, ComboRemunerationCommercialOperation, ComboMarital, ComboGender, ComboAccount, ComboApportionment, ComboInflationIndex } from 'src/app/shared/models/combo.model';
import { MinhaSolicitacaoService } from '../../services/minha-solicitacao.service';
import { NovoContratoService } from '../../services/novo-contrato.service';
import { take } from 'rxjs/operators';
import { RegisterAreaComponent } from 'src/app/shared/components/register-area/register-area.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { RegisterPersonComponent } from 'src/app/shared/components/register-person/register-person.component';
import Permissions from '../../../../app/shared/enums/permissions.enum';
import { PermissionService } from '../../../shared/services/permission.service';
import * as moment from 'moment';
import { stringify } from '@angular/compiler/src/util';
import { AditivoContratualService } from '../../services/aditivo-contratual.service';
import { ProjetosService } from 'src/app/cadastro/services/projetos.service';


const FileSaver = require('file-saver');
moment.locale('pt-br');
@Component({
  selector: 'app-novo-contrato',
  templateUrl: './novo-contrato.component.html',
  styleUrls: ['./novo-contrato.component.scss']
})

export class NovoContratoComponent implements OnInit {
  //@ViewChild('modal', { static: true }) modal: ModalComponent;
  @ViewChild('registerPersonComponent', {static: false}) registerPersonComponent: RegisterPersonComponent;
  @ViewChild('registerAreaComponent', { static: false }) registerAreaComponent: RegisterAreaComponent;



  @Input() idContratoAntigo: number;
  @Input() aditivo: boolean = false;
  @Input() resilicao: boolean = false;

  showUser$ = this.state.pipe(select(selectAuthUser));

  loggedUserId: number;
  form: FormGroup;

  signed_contract_text = null;
  hasSubmitted = false;
  has_prev_contract = false;
  fieldNameUpload: string = null;
  linkMaps: any;
  preview: any;
  previewVisible = false;
  //project_id: number;
  areaId: number;
  areaCode: any;
  indexEdit = null;
  showPopover = true;
  blockForm = false;
  formPerson: FormGroup;
  personDetails: any;
  contract_file: any;
  property_signature_file: any;
  mostrar_area_aprovacao: boolean = false;

  contrato_para_aprovacao:boolean = false;

  totalRateio:number = 0;

  previousPage = '';

  /*
  dataRegisterArea = {
    hiddenHeader: true,
    areaId: null,
    previewVisible: false,
    preview: '',
  };
  */


  showCorrectdocumentationJustification = false;
  subirContratoAssinado = false;
  contract_id: number;
  id: number;
  justificativa_reprovacao: string | null = null;

  reasonNewContractType = ReasonNewContractType;
  remunerationPreOperationType = RemunerationPreOperationType;
  remunerationCommercialOperationType = RemunerationCommercialOperationType;
  maritalType = MaritalType;
  accountType = AccountType;
  apportionmentType = ApportionmentType;
  preApprovedType = PreApprovedType;

  listContractType: Array<ComboContract>;
  listReasonNewContractType: Array<ComboReasonNewContract>;
  listRemunerationPreOperationType: Array<ComboRemunerationPreOperation>;
  listInflationIndex: Array<ComboInflationIndex>;
  listFrequencyType: Array<ComboFrequency>;
  listRemunerationCommercialOperationType: Array<ComboRemunerationCommercialOperation>;
  listMaritalType: Array<ComboMarital>;
  listGenderType: Array<ComboGender>;
  listAccountType: Array<ComboAccount>;
  listApportionmentType: Array<ComboApportionment>;

  listProject: Array<ComboDefault> = [];
  listContract: Array<ComboDefault>;
  listCityOwner: Array<ComboDefault>;
  listCityPrimaryContact: Array<ComboDefault>;
  listState: Array<ComboDefault>;
  listBank: Array<ComboDefault>;
  listSelectedOwner: Array<ComboDefault>;
  personsList: Array<ComboDefault>;

  selectedIndexTableArea: number = null;

  activeTabIndexArea = 0;
  activeTabIndexOwner = 0;

  peopleHeirSearch: any;
  objHeirList: any;
  requisitionData: any;

  actual_step = 0;
  status_id = null;
  bloquearCampoProjeto = false;
  bloquearTudo = false;

  resilicao_motivo_text: string = '';
  ver_resilicao_text: boolean = false;

  codeArea : any= null;
  codeAreas = [];
  areasHaveContractList = [];
  listAreaByCode = [];
  projectId: number;
  type_contract_id: any;
  equal_apportionment: boolean = false;

  contract_load_flow:boolean = false


  constructor(
	      private projetosService: ProjetosService,
              private state: Store<AuthState>,
              private fb: FormBuilder,
              private dialogService: DialogService,
              private router: Router,
              private notification: NzNotificationService,
              private serviceShared: ComboService,
              private route: ActivatedRoute,
              private service: NovoContratoService,              
              private permissionService: PermissionService) {


    

    this.form = this.fb.group({
      general: this.fb.group({
        project: this.fb.control(null, [Validators.required])
      }),
      payment_informations: this.fb.group({
        equal_apportionment: this.fb.control(false, [Validators.required]),
        //apportionment: this.fb.control(false, [radioRequiredValidator('equal_apportionment', false)])
      }),
      polygon_view: this.fb.group({
        area_map: this.fb.control(null, [Validators.nullValidator]),
        contract_type: this.fb.control(null, [Validators.required]),
        contract_list: this.fb.control([], [Validators.nullValidator]),
        reason_new_contract: this.fb.control(null, [this.reasonNewContractRequiredValidator]),
        reason: this.fb.control(null, [reasonRequiredValidator]),
        observation: this.fb.control(null, [Validators.nullValidator]),
        has_prev_contract: this.fb.control(null, [Validators.nullValidator])
      }),
      property_information: this.fb.group({
        area_list: this.fb.array([], [Validators.required]),
      }),
      business_conditions: this.fb.group({
        hectare_total_contract: this.fb.control(0, null),
        inflationIndex: this.fb.control(null, [Validators.required]),
        pre_operation: this.fb.group({
          remuneration_type: this.fb.control(null, [Validators.required]),
          contract_deadline: this.fb.control(null, [Validators.required]),
          frequency: this.fb.control(null, [Validators.required]),
          value_per_period: this.fb.control(null, [requiredChildPreOperationRemunerationValidator('value_per_period')]),
          value_per_hectare: this.fb.control(null, [requiredChildPreOperationRemunerationValidator('value_per_hectare')]),
          remuneration_anemometric_tower: this.fb.control(false, [Validators.required]),
          value_per_anemometric_tower: this.fb.control(null, [radioRequiredValidator('remuneration_anemometric_tower', true)]),
          frequency_anemometric_tower: this.fb.control(null, [radioRequiredValidator('remuneration_anemometric_tower', true)]),
          advance: this.fb.control(false, [Validators.required]),
          value_advance: this.fb.control(null, [radioRequiredValidator('advance', true)]),
          signal: this.fb.control(false, [Validators.required]),
          value_signal: this.fb.control(null, [radioRequiredValidator('signal', true)]),
          observation: this.fb.control(null, [Validators.nullValidator]),
        }),
        commercial_operation: this.fb.group({
          remuneration_type: this.fb.control(null, [Validators.required]),
          // tslint:disable-next-line: max-line-length
          value_per_wind_generator: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('value_per_wind_generator')]),
          // tslint:disable-next-line: max-line-length
          percentage_over_generation: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('percentage_over_generation')]),
          value_per_hectare: this.fb.control(null, [requiredChildCommercialOperationRemunerationValidator('value_per_hectare')]),
          frequency: this.fb.control(null, [Validators.required]),
          observation: this.fb.control(null, [Validators.nullValidator]),
        }),
      }),
      motivo_resilicao: this.fb.control(null, [(this.resilicao) ? Validators.required : Validators.nullValidator]),
      owners: this.fb.group({
        general: this.fb.group({
          heritage: this.fb.control(false, [Validators.required]),
          //ownerId: this.fb.control(null, [Validators.nullValidator]),
          owner_list: this.fb.array([], [Validators.required]),
        }),
      }),
      aprovacao: this.fb.group({
        arquivo_contrato: this.fb.control(null, null),
        assinatura_proprietario: this.fb.control(null, null),
        data_assinatura: this.fb.control(null, null),
        default_draft: this.fb.control(true, null),
        data_recebimento: this.fb.control(null, null),
        localidade: this.fb.control(null, null),
        armario: this.fb.control(null, null),
        caixa: this.fb.control(null, null),
        numero_contrato_totvs: this.fb.control(null, null),
        justificativa: this.fb.control(null, [Validators.required])
      })
  });

    const urlParams = this.getParamsUrl(this.route);
    this.id = urlParams.id;
  }

  ngOnInit(): void {

    const urlParams = this.getParamsUrl(this.route);

    if (urlParams.urlSegment && urlParams.urlSegment.path !== 'view-contract') {
      this.mostrar_area_aprovacao = true;
    }


    this.contract_load_flow = this.permissionService.can(Permissions.CADASTROS_CONTRATO_CARGA);
    /*
    if (this.contract_load_flow) {
      this.form.get("aprovacao").get('data_assinatura').setValidators([Validators.required]);
      this.form.get("aprovacao").get('data_assinatura').updateValueAndValidity();
    }
    */

    //this.onChanges();
    this.showUser$.subscribe((user: any) => {
      this.loggedUserId = user.id;
      this.loadCombos();
    });


    if (this.id) {

      //this.service.requisitionDetails("C", this.id).subscribe((responseRequisition: any) => {

        this.service.getDetails(this.id).subscribe((response: any) => {
          this.requisitionData = response.data;
          let tem_aditivo_resilicao = (this.requisitionData.aditivo_resilicao && this.requisitionData.aditivo_resilicao.length > 0) ? true : false;
          if (!this.aditivo) {
            this.actual_step = this.requisitionData.actual_step;
          } else {
            this.actual_step = 0;
            response.data.actual_step = 0;
          }

          this.status_id = this.requisitionData.status_id;

          if (this.requisitionData.signed_contract == null) {
            this.subirContratoAssinado = true;
          }

          if(this.requisitionData.actual_step === 2)
          {
            this.form.get("aprovacao").get('arquivo_contrato').setValidators([Validators.required]);
            this.form.get("aprovacao").get('default_draft').setValidators([Validators.required]);
            this.form.get("aprovacao").get('arquivo_contrato').updateValueAndValidity();
            this.form.get("aprovacao").get('default_draft').updateValueAndValidity();
          }


          if(this.requisitionData.actual_step === 3)
          {
            this.form.get("aprovacao").get('assinatura_proprietario').setValidators([Validators.required]);
            this.form.get("aprovacao").get('assinatura_proprietario').updateValueAndValidity();
            this.form.get("aprovacao").get('data_assinatura').setValidators([Validators.required]);
            this.form.get("aprovacao").get('data_assinatura').updateValueAndValidity();
          }


          if(this.requisitionData.actual_step === 5)
          {
            this.form.get("aprovacao").get('data_recebimento').setValidators([Validators.required]);
            this.form.get("aprovacao").get('data_recebimento').updateValueAndValidity();
            this.form.get("aprovacao").get('localidade').setValidators([Validators.required]);
            this.form.get("aprovacao").get('localidade').updateValueAndValidity();
            this.form.get("aprovacao").get('armario').setValidators([Validators.required]);
            this.form.get("aprovacao").get('armario').updateValueAndValidity();
            this.form.get("aprovacao").get('caixa').setValidators([Validators.required]);
            this.form.get("aprovacao").get('caixa').updateValueAndValidity();
            this.form.get("aprovacao").get('numero_contrato_totvs').setValidators([Validators.required]);
            this.form.get("aprovacao").get('numero_contrato_totvs').updateValueAndValidity();
          }



          if(this.requisitionData.resilicao || this.requisitionData.status_id === 2 || tem_aditivo_resilicao)
          {
            this.resilicao_motivo_text = (this.requisitionData.motivo_resilicao) ? this.requisitionData.motivo_resilicao : this.requisitionData.aditivo_resilicao[0].con_motivo_resilicao;
            this.ver_resilicao_text = true;
          }

          if(response.data.status_id === 4)
          {
            this.contrato_para_aprovacao === true;
          }

          this.setFormData(response.data);
        });
      //});
    }

  }

  receiverAreaCodes(event) {
    this.listAreaByCode = event.filter((areaKML) => !this.areasHaveContractList.includes(areaKML));
  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;

    route.params.subscribe(params => {
      if (history.state.previousPage) {
        this.previousPage = history.state.previousPage
      }
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

  /*
  onChanges(): void {
    this.pre_operation.get('remuneration_type').valueChanges.subscribe(val => {
      this.pre_operation.get('value_per_period').setValue(null);
      this.pre_operation.get('value_per_hectare').setValue(null);
    });

    this.commercial_operation.get('remuneration_type').valueChanges.subscribe(val => {
      this.commercial_operation.get('value_per_wind_generator').setValue(null);
      this.commercial_operation.get('percentage_over_generation').setValue(null);
      this.commercial_operation.get('value_per_hectare').setValue(null);
    });
  }
  */

  loadCombos(): void {
    this.serviceShared.ComboProject({ user: this.loggedUserId }).subscribe((response: any) => this.listProject = response.data);

    this.serviceShared.ComboContract().subscribe((response: any) => this.listContractType = response.data);

    this.serviceShared.ComboReasonNewContract().subscribe((response: any) => this.listReasonNewContractType = response.data);

    this.serviceShared.ComboRemunerationPreOperation().subscribe((response: any) => this.listRemunerationPreOperationType = response.data);

    this.serviceShared.ComboInflationIndex().subscribe((response: any) => this.listInflationIndex = response.data);

    this.serviceShared.ComboFrequency().subscribe((response: any) => this.listFrequencyType = response.data);

    // tslint:disable-next-line: max-line-length
    this.serviceShared.ComboRemunerationCommercialOperation().subscribe((response: any) => this.listRemunerationCommercialOperationType = response.data);

    //this.serviceShared.ComboState().subscribe((response: any) => this.listState = response.data);

    //this.serviceShared.ComboMarital().subscribe((response: any) => this.listMaritalType = response.data);

    //this.serviceShared.ComboGender().subscribe((response: any) => this.listGenderType = response.data);

    // tslint:disable-next-line: max-line-length
    this.serviceShared.ComboAreaSemContrato().subscribe((response: any) => {
      response.data.forEach(element => {

        this.areasHaveContractList.push(element)
      });
    });

    //this.serviceShared.ComboBank().subscribe((response: any) => this.listBank = response.data);

    /*
    this.listAccountType = [
      { id: AccountType.Corrente, desc: 'Corrente' },
      { id: AccountType.Poupança, desc: 'Poupança' }
    ];

    this.listApportionmentType = [
      { id: ApportionmentType.Porcentagem, desc: 'Porcentagem' },
      { id: ApportionmentType.Valor, desc: 'Valor' }
    ];
    */
  }



  //#region Create/edit person

  createNewPerson() {
    this.owner_list.push(this.createFormGroupOwner(this.nullPerson()));
  }

  mapResultPerson(result) {
    if (result.address.complement == null) {
      delete result.address.complement;
    }
    delete result.person.isalive;
    delete result.person.isdeath;

    if (result.person.deceased === '1') {
      result.person.deceased = true;
    } else {
      result.person.deceased = false;
    }
    const dateTypeCorrect = moment(result.person.birth_date.replace(/-/g, ''), 'DDMMYYYY').format('DD/MM/YYYY');
    if (result.person.deceased_date != null && result.person.deceased === true) {
      result.person.deceased_date = moment(result.person.deceased_date.replace(/-/g, ''), 'DDMMYYYY').format('DD/MM/YYYY');
    } else {
      delete result.person.deceased_date;
    }
    result.person.birth_date = dateTypeCorrect;

    if (result.person.marital_status_id === 3) {
      result.person.spouse_id = null;
    }

    if (result.person.spouse_id == null) {
      delete result.person.spouse_id;
    }
  }

  //#endregion


  addPerson(owners) { 
    owners.forEach( response => {
      const owner = {
        bloquearTudo: this.bloquearTudo,
          person: {
            id: response.id,
            name: response.name,
            cpf: response.cpf,
            rg: response.rg,
            issuing_agency: response.issuing_agency,
            birth_date: response.birth_date,
            nationality: response.nationality,
            telephone: response.telephone,
            cellphone: response.cellphone,
            gender_id: response.gender_id,
            marital_status_id: response.marital_status_id,
            job: response.job,
            spouse_id: response.spouse_id,
            deceased: response.deceased,
            deceased_date: response.deceased_date,
            apportionment_percent: response.apportionment_percent,
            owner: response.owner,
            is_heir: response.is_heir,
            main_contact: response.main_contact,
            beneficiary: response.beneficiary,
            consenting: response.consenting
          },
          address: {
            zip_code: response.zip_code,
            neighborhood: response.neighborhood,
            state_id: response.state_id,
            city_id: response.city_id,
            number: response.number,
            complement: response.complement,
            public_place: response.public_place
          },
          bank: {
            bank_id: response.bank_id,
            account_type: response.checking_account ? AccountType.Corrente : AccountType.Poupança,
            account: response.account,
            agency: response.agency,
            checking_account: response.checking_account,
            savings_account: response.savings_account
          },
          partner: null,
          documents: {
            owner_cpf_frente: response.attachments.owner_cpf_frente ? response.attachments.owner_cpf_frente : null,
            owner_cpf_verso: response.attachments.owner_cpf_verso ? response.attachments.owner_cpf_verso : null,
            owner_rg_frente: response.attachments.owner_rg_frente ? response.attachments.owner_rg_frente : null,
            owner_rg_verso: response.attachments.owner_rg_verso ? response.attachments.owner_rg_verso : null,
            death: response.attachments.death ? response.attachments.death: null,
            marriage: response.attachments.marriage ? response.attachments.marriage: null,
            proof_address: response.attachments.proof_address ? response.attachments.proof_address : null,

            owner_cpf_frente_text: response.attachments.owner_cpf_frente_text ? response.attachments.owner_cpf_frente_text : null,
            owner_cpf_verso_text: response.attachments.owner_cpf_verso_text ? response.attachments.owner_cpf_verso_text : null,
            owner_rg_frente_text: response.attachments.owner_rg_frente_text ? response.attachments.owner_rg_frente_text : null,
            owner_rg_verso_text: response.attachments.owner_rg_verso_text ? response.attachments.owner_rg_verso_text : null,
            death_text: response.attachments.death_text ? response.attachments.death_text: null,
            marriage_text: response.attachments.marriage_text ? response.attachments.marriage_text: null,
            proof_address_text: response.attachments.proof_address_text ? response.attachments.proof_address_text : null,
          }
        }

      /*
      Object.keys(owner.documents).forEach( key => {
        if (owner.documents[key] != null) {
          owner.documents[key][0]['name'] = owner.documents[key][0]['filename']
        }
      })
      if (owner.person.marital_status_id === MaritalType.Casado) {
        this.getConjuge(owner);
      }
      else {
        this.owner_list.push(this.createFormGroupOwner(owner));
        this.onChangeTabIndexOwner(0);
        this.owner_list.updateValueAndValidity();
      }
      */

      console.log(owner);


      this.owner_list.push(this.createFormGroupOwner(owner));
      this.owner_list.updateValueAndValidity();
    });

  }

  nullPerson() {
    const owner = {
      bloquearTudo: this.bloquearTudo,
      person: {
        id: null,
        name: null,
        cpf: null,
        rg: null,
        issuing_agency: null,
        birth_date: null,
        nationality: null,
        telephone: null,
        cellphone: null,
        gender_id: null,
        marital_status_id: null,
        job: null,
        deceased: null,
        deceased_date: null,
        apportionment_percent: null,
      },
      address: {
        zip_code: null,
        neighborhood: null,
        state_id: null,
        city_id: null,
        number: null,
        complement: null,
        public_place: null
      },
      bank: {
        bank_id: null,
        account_type: null,
        account: null,
        agency: null,
        checking_account: null,
        savings_account: null
      },
      documents: {
        owner_cpf_frente: null,
        owner_cpf_verso: null,
        owner_rg_frente: null,
        owner_rg_verso: null,
        death: null,
        marriage: null,
        proof_address: null,
        owner_cpf_frente_text: null,
        owner_cpf_verso_text: null,
        owner_rg_frente_text: null,
        owner_rg_verso_text: null,
        death_text: null,
        marriage_text: null,
        proof_address_text: null,
      }
    }

    return owner;
  }



  setFormData(data) {
    this.justificativa_reprovacao = data.justificativa_reprovacao;
    this.owners_general.get('heritage').setValue((data.espolio) ? true : false);
    this.projectId = data.project_id
    this.actual_step = data.actual_step
    if (data.actual_step > 0 && data.actual_step != 2) {
      this.bloquearTudo = true
    } else if (data.status_id == 6) {
      this.bloquearTudo = true
    }
    this.status_id = data.status_id
    this.onChangeProject(this.projectId)
    this.addPerson(data.people)
    this.addContractAreas(data.areas)
    this.bloquearCampoProjeto = true;
    this.payment_informations.get('equal_apportionment').setValue((data.equal_apportionment) ? data.equal_apportionment : false);
    this.form.get('motivo_resilicao').setValue(data.motivo_resilicao);
    this.contract_id = data.id;
    this.type_contract_id = data.type_contract_id;
    this.signed_contract_text = data.signed_contract_text;
    this.general.get('project').setValue(data.project_id);
    this.polygon_view.get('contract_type').setValue(data.type_contract_id);
    this.polygon_view.get('reason_new_contract').setValue(data.reason_new_contract_id);
    this.polygon_view.get('reason').setValue(data.reason);
    this.polygon_view.get('observation').setValue(data.observation);
    this.business_conditions.get('inflationIndex').setValue(data.inflationIndex_id);
    this.pre_operation.get('remuneration_type').setValue(data.contract_remuneration_type_id);
    this.pre_operation.get('frequency').setValue(data.frequency_id);
    this.pre_operation.get('contract_deadline').setValue(data.deadline_years);
    this.pre_operation.get('remuneration_anemometric_tower').setValue(data.is_remuneration_by_anemometric_tower);
    this.pre_operation.get('value_per_anemometric_tower').setValue((data.value_per_anemometric_tower));
    this.pre_operation.get('frequency_anemometric_tower').setValue(data.anemometric_tower_frequency_id);
    this.pre_operation.get('advance').setValue(data.advance);
    this.pre_operation.get('value_advance').setValue((data.advance_value));
    this.pre_operation.get('signal').setValue(data.signal);
    this.pre_operation.get('value_signal').setValue((data.signal_value));
    this.pre_operation.get('observation').setValue(data.pre_operation_observation);
    this.pre_operation.get('value_per_period').setValue((data.pre_operation_value));
    this.pre_operation.get('value_per_hectare').setValue((data.value_per_hectare_pre_operation_value));

    this.commercial_operation.get('remuneration_type').setValue(data.wind_turbines_remuneration_type_id);
    this.commercial_operation.get('observation').setValue(data.commercial_observation);
    this.commercial_operation.get('value_per_wind_generator').setValue((data.value_per_wind_turbine));
    this.commercial_operation.get('percentage_over_generation').setValue(this.formatNumberBR(data.percentage_over_generation));
    this.commercial_operation.get('value_per_hectare').setValue((data.value_per_hectare_commercial_operation));
    this.commercial_operation.get('frequency').setValue(data.wind_turbine_frequency_id);

    
    this.form.get("aprovacao").get("data_assinatura").setValue(data.data_assinatura_proprietario);
    this.form.get("aprovacao").get("arquivo_contrato").setValue(data.signed_contract_text);
    
    // this.primary_contact.get('is_owner_heir').setValue(data.is_contact_owner_or_heir ? data.is_contact_owner_or_heir : false);
    // this.primary_contact.get('owner_heir').setValue(data.contact_person_id);

    // this.primary_contact.get('name').setValue(data.contact_name);
    // this.primary_contact.get('telephone').setValue(data.contact_phone);
    // this.primary_contact.get('cellphone').setValue(data.contact_cel);
    // this.primary_contact.get('public_place').setValue(data.contact_public_place);
    // this.primary_contact.get('number').setValue(data.contact_number);
    // this.primary_contact.get('complement').setValue(data.contact_complement);
    // this.primary_contact.get('neighborhood').setValue(data.contact_neighborhood);

    // if (data.state_id) {
    //   this.serviceShared.ComboCity(data.state_id).subscribe((res: any) => {
    //     this.listCityPrimaryContact = res.data;
    //     this.primary_contact.get('city_id').setValue(data.city_id);
    //   });
    // }

    if (data.areas && data.areas.length > 0) {
      // this.has_prev_contract = true;
      this.addContractList(data.areas);
      for (var area of data.areas) {
        this.createFormGroupArea(area);
      }

    }

    // this.primary_contact.get('state_id').setValue(data.state_id);
    // this.primary_contact.get('zip_code').setValue(data.contact_zip_code);

  }


  createFormGroupContract(data): FormGroup {
    return this.fb.group({
      id: this.fb.control({ value: data.id, disabled: true }, [Validators.nullValidator]),
      code: this.fb.control({ value: data.code, disabled: true }, [Validators.nullValidator]),
    });
  }

  createFormGroupArea(data): FormGroup {
    var fbArea = this.fb.group({
      id: this.fb.control(data.id, [Validators.nullValidator]),
      name: this.fb.control(data.name, [Validators.required]),
      situation_id: this.fb.control(data.situation_id, [Validators.required]),
      pendencies_regularize: this.fb.control(data.pendencies_regularize, [Validators.nullValidator]),
      time_regularize: this.fb.control(data.time_regularize, [Validators.nullValidator]),
      has_competitor: this.fb.control(data.has_competitor, [Validators.required]),
      observation: this.fb.control(data.observation, [Validators.nullValidator]),
      code: this.fb.control(data.code, [Validators.required]),
      type_document_id: this.fb.control(data.type_document_id, [radioRequiredValidator('situation_id', 1)]),
      registry: this.fb.control(data.registry, [radioRequiredValidator('situation_id', 1)]),
      gravame: this.fb.control(data.gravame, [Validators.required]),
      gravame_value: this.fb.control(data.gravame_value, [radioRequiredValidator('gravame', true)]),
      squatter: this.fb.control(data.squatter, [Validators.required]),
      owner_document: this.fb.control(data.owner_document, [Validators.nullValidator]),
      hectare_document: this.fb.control(data.hectare_document, [radioRequiredValidator('situation_id', 1)]),
      code_incra: this.fb.control(data.code_incra, [Validators.nullValidator]),
      hectare_incra: this.fb.control(data.hectare_incra, [requiredIfParentIfdiferentFromNull('code_incra')]),
      state_area_id: this.fb.control(data.state_area_id, [Validators.required]),
      city_area_id: this.fb.control(data.city_area_id, [Validators.required]),
      nirf: this.fb.control(data.nirf, [Validators.nullValidator]),
      hectare_receita_federal: this.fb.control(data.hectare_receita_federal, [requiredIfParentIfdiferentFromNull('nirf')]),
      ccir: this.fb.control(data.ccir, [Validators.nullValidator]),
      hectare_ccir: this.fb.control(data.hectare_ccir, [requiredIfParentIfdiferentFromNull('ccir')]),
      competing_company: this.fb.control(data.competing_company, [radioRequiredValidator('has_competitor', true)]),
      type_competing_company: this.fb.control(data.type_competing_company, [radioRequiredValidator('has_competitor', true)]),
      area_id: this.fb.control(data.area_id, [Validators.nullValidator]),
      inactive_contract_list: this.fb.control({ value: data.inactive_contract_list, disabled: true }, [Validators.nullValidator]),
      is_pre_approved: this.fb.control(data.is_pre_approved, [Validators.nullValidator]),
      link_maps: this.fb.control({ value: data.link_maps, disabled: true }, [Validators.nullValidator]),
      contract_hectare: this.fb.control(data.contract_hectare, [Validators.required]),
      project_id : this.fb.control(data.project_id, [Validators.required]),
      attachment_image : this.fb.control(data.attachment_image, [Validators.nullValidator]),
      attachment_kmz : this.fb.control(data.attachment_kmz, [Validators.nullValidator]),
      number_property: this.fb.control(data.number_property, [radioRequiredValidator('type_document_id', 1)]),
      registration: this.fb.control(data.registration, [radioRequiredValidator('type_document_id', 1)]),
      date_document: this.fb.control(data.date_document, [radioRequiredValidator('type_document_id', 1)]),
      has_prev_contract: false,
      amount_wind_turbines: this.fb.control(data.amount_wind_turbines, [Validators.required]),

      //attachment_property_id: this.fb.control(data.attachment_property_id, [radioRequiredValidator('situation_id', 1)]), (não existe no html)
      //attachment_last_issue_id : this.fb.control(data.attachment_last_issue_id, [requiredIfParentIfdiferentFromNull('ccir')]), (não existe no html)
      //attachment_last_decl_id : this.fb.control(data.attachment_last_decl_id, [requiredIfParentIfdiferentFromNull('nirf')]), (não existe no html)

      attachment_propertys: this.fb.control(data.attachment_propertys, [Validators.nullValidator]),
      attachment_last_issue : this.fb.control(data.attachment_last_issue, [Validators.nullValidator]),
      attachment_last_decl : this.fb.control(data.attachment_last_decl, [Validators.nullValidator]),
      attachment_property_text: this.fb.control(data.attachment_property_text, [radioRequiredValidator('situation_id', 1)]),
      attachment_last_issue_text : this.fb.control(data.attachment_last_issue_text, [requiredIfParentIfdiferentFromNull('ccir')]),
      attachment_last_decl_text : this.fb.control(data.attachment_last_decl_text, [requiredIfParentIfdiferentFromNull('nirf')]),

      bloquearTudo: data.bloquearTudo,
      downloads: this.fb.control({})
    });

    if (this.contract_load_flow) {
      fbArea.get("attachment_property_text").clearValidators();
      fbArea.get("attachment_last_issue_text").clearValidators();
      fbArea.get("attachment_last_decl_text").clearValidators();
    }
    this.areaCode = data;


    return fbArea;


  }

  createFormGroupOwner(data): FormGroup {
    var fbOwner = this.fb.group({
      bloquearTudo: data.bloquearTudo,
      person: this.fb.group({
        id: this.fb.control({ value: data.person.id, disabled: false }, [Validators.nullValidator]),
        name: this.fb.control({ value: data.person.name, disabled: false }, [Validators.required]),
        cpf: this.fb.control({ value: data.person.cpf, disabled: false }, [Validators.required]),
        rg: this.fb.control({ value: data.person.rg, disabled: false }, [Validators.required]),
        issuing_agency: this.fb.control({ value: data.person.issuing_agency, disabled: false }, [Validators.required]),
        birth_date: this.fb.control({ value: data.person.birth_date, disabled: false }, [Validators.required]),
        nationality: this.fb.control({ value: data.person.nationality, disabled: false }, [Validators.required]),
        telephone: this.fb.control({ value: data.person.telephone, disabled: false }),
        cellphone: this.fb.control({ value: data.person.cellphone, disabled: false }),
        gender_id: this.fb.control({ value: data.person.gender_id, disabled: false }, [Validators.required]),
        marital_status_id: this.fb.control({ value: data.person.marital_status_id, disabled: false }, [Validators.required]),
        job:  this.fb.control({ value: data.person.job, disabled: false }, [Validators.required]),
        deceased: this.fb.control({ value: data.person.deceased ? data.person.deceased : false, disabled: false }, [Validators.required]),
        deceased_date: this.fb.control({ value: data.person.deceased_date, disabled: false }, [Validators.nullValidator]),
        owner: this.fb.control({ value: data.person.owner ? data.person.owner : false , disabled: false }, [Validators.nullValidator]),
        beneficiary: this.fb.control({ value: data.person.beneficiary ? data.person.beneficiary : false , disabled: false }, [Validators.nullValidator]),
        consenting: this.fb.control({ value: data.person.consenting ? data.person.consenting : false , disabled: false }, [Validators.nullValidator]),
        is_heir: this.fb.control({ value: data.person.is_heir ? data.person.is_heir : false , disabled: false }, [Validators.nullValidator]),
        main_contact: this.fb.control({ value: data.person.main_contact ? data.person.main_contact : false , disabled: false }, [Validators.nullValidator]),
        apportionment_percent: this.fb.control({ value: data.person.apportionment_percent, disabled: false }, null),
      }),
      address: this.fb.group({
        zip_code: this.fb.control({ value: data.address.zip_code, disabled: false }, [Validators.required]),
        neighborhood: this.fb.control({ value: data.address.neighborhood, disabled: false }, [Validators.required]),
        state_id: this.fb.control({ value: data.address.state_id, disabled: false }, [Validators.required]),
        city_id: this.fb.control({ value: data.address.city_id, disabled: false }, [Validators.required]),
        number: this.fb.control({ value: data.address.number, disabled: false }, [Validators.required]),
        complement: this.fb.control({ value: data.address.complement, disabled: false }, [Validators.nullValidator]),
        public_place: this.fb.control({ value: data.address.public_place, disabled: false }, [Validators.required])
      }),
      bank: this.fb.group({
        bank_id: this.fb.control({ value: data.bank.bank_id, disabled: false }, [Validators.required]),
        account_type: this.fb.control({ value: data.bank.account_type ? data.bank.account_type : 1, disabled: false }, [Validators.required]),
        account: this.fb.control({ value: data.bank.account, disabled: false }, [Validators.required]),
        agency: this.fb.control({ value: data.bank.agency, disabled: false }, [Validators.required]),
        //checking_account: this.fb.control({ value: data.bank.checking_account, disabled: false }, [Validators.nullValidator]),
        //savings_account: this.fb.control({ value: data.bank.savings_account, disabled: false }, [Validators.nullValidator])
      }),
      attachments: this.fb.group({
        owner_cpf_frente: this.fb.control(data.documents ? data.documents.owner_cpf_frente : null, null),
        owner_cpf_verso: this.fb.control(data.documents ? data.documents.owner_cpf_verso : null, null),
        owner_rg_frente: this.fb.control(data.documents ? data.documents.owner_rg_frente : null, null),
        owner_rg_verso: this.fb.control(data.documents ? data.documents.owner_rg_verso : null, null),
        proof_address: this.fb.control(data.documents ? data.documents.proof_address : null, null),
        death_certificate: this.fb.control(data.documents ? data.documents.death : null, null),
        marriage_certificate: this.fb.control(data.documents ? data.documents.marriage : null, null),

        owner_cpf_frente_text: this.fb.control(data.documents ? data.documents.owner_cpf_frente_text : null, [Validators.required]),
        owner_cpf_verso_text: this.fb.control(data.documents ? data.documents.owner_cpf_verso_text : null, [Validators.required]),
        owner_rg_frente_text: this.fb.control(data.documents ? data.documents.owner_rg_frente_text : null, [Validators.required]),
        owner_rg_verso_text: this.fb.control(data.documents ? data.documents.owner_rg_verso_text : null, [Validators.required]),
        death_text: this.fb.control(data.documents ? data.documents.death_text : null, null),
        marriage_text: this.fb.control(data.documents ? data.documents.marriage_text : null, null),
        proof_address_text: this.fb.control(data.documents ? data.documents.proof_address_text : null, [Validators.required]),
      })
    });

    if (this.contract_load_flow) {
      fbOwner.get("person").get("telephone").clearValidators();
      fbOwner.get("person").get("birth_date").clearValidators();
      fbOwner.get("person").get("cellphone").clearValidators();
      fbOwner.get("attachments").get("owner_cpf_frente_text").clearValidators();
      fbOwner.get("attachments").get("owner_cpf_verso_text").clearValidators();
      fbOwner.get("attachments").get("owner_rg_frente_text").clearValidators();
      fbOwner.get("attachments").get("owner_rg_verso_text").clearValidators();
      fbOwner.get("attachments").get("death_text").clearValidators();
      fbOwner.get("attachments").get("marriage_text").clearValidators();
      fbOwner.get("attachments").get("proof_address_text").clearValidators();
    }

    return fbOwner;

  }

  createFormArea(area) {
    this.area_list.push(this.createFormGroupArea(area));

    this.areaId = null;
    //this.getComboSelectedArea();
    this.business_conditions.get('hectare_total_contract').setValue(this.formatNumberBR(this.calcHectareTotalContract()));

  }


  get general(): AbstractControl {
    return this.form.get('general');
  }

  get polygon_view(): FormGroup {
    return this.form.get('polygon_view') as FormGroup;
  }


  get contract_list(): FormGroup {
    return this.polygon_view.get('contract_list') as FormGroup;
  }

  get property_information(): AbstractControl {
    return this.form.get('property_information');
  }

  get area_list(): FormArray {
    return this.property_information.get('area_list') as FormArray;
  }

  get business_conditions(): AbstractControl {
    return this.form.get('business_conditions');
  }

  get pre_operation(): AbstractControl {
    return this.business_conditions.get('pre_operation');
  }

  get commercial_operation(): AbstractControl {
    return this.business_conditions.get('commercial_operation');
  }

  get approval(): AbstractControl{
    return this.business_conditions.get('aprovacao');
  }

  get owners(): AbstractControl {
    return this.form.get('owners');
  }

  get owners_general(): AbstractControl {
    return this.owners.get('general');
  }

  /*
  get ownerId(): AbstractControl {
    return this.owners_general.get('ownerId');
  }
  */

  get owner_list(): FormArray {
    return this.owners_general.get('owner_list') as FormArray;
  }

  get primary_contact(): AbstractControl {
    return this.owners.get('primary_contact');
  }

  get payment_information(): AbstractControl {
    return this.owners.get('payment_information');
  }

  get payment_informations(): AbstractControl {
    return this.form.get('payment_informations');
  }

  get motivo_resilicao(): AbstractControl{
    return this.form.get('motivo_resilicao');
  }

  getTotalRateio() : number {
    var total = 0.00;
    let dividir_valores_iguais_beneficiarios = this.payment_informations.get('equal_apportionment').value;
    this.onChangeEqualApportionment(dividir_valores_iguais_beneficiarios);

    this.owner_list.controls.forEach(element => {
      if (element.get('person').get("beneficiary").value == true && element.get('person').get("apportionment_percent").value)
        total += this.toFloat(element.get('person').get("apportionment_percent").value);
    });

    this.totalRateio = total;

    return total;
  }


  getBankById(id: number): ComboDefault {
    return this.listBank.find((bankItem) => {
      return bankItem.id === id;
    });
  }




  onChangeEqualApportionment(value) {
    if (value == true) {
      var totalBeneficiarys = 0;

      this.owner_list.controls.forEach(element => {
        if (element.get('person').get("beneficiary").value == true)
          totalBeneficiarys++;
      });

      var total = 0.00;
      var firstElement = null;
      this.owner_list.controls.forEach(element => {
        if (element.get('person').get("beneficiary").value == true) {
          if (!firstElement) firstElement = element;
          element.get('person').get("apportionment_percent").setValue(this.formatNumberBR(100.00/totalBeneficiarys));
          total += this.toFloat(element.get('person').get("apportionment_percent").value);

        }
      });

      if (firstElement && total < 100) {
        firstElement.get('person').get("apportionment_percent").setValue(this.formatNumberBR(100.00 - total + this.toFloat(firstElement.get('person').get("apportionment_percent").value)));
      }
    }

    if(this.owner_list.length == 1)
    {
      this.owner_list.controls.forEach(element => {
        if (element.get('person').get("beneficiary").value == true) {
          if (!firstElement) firstElement = element;
          element.get('person').get("apportionment_percent").setValue(this.formatNumberBR(100.00));
        }
      });
    }

  }

  getAccountById(id: AccountType): ComboAccount {
    return this.listAccountType.find((accountItem) => {
      return accountItem.id === id;
    });
  }

  formatNumberBR(value: number | string) : string {
    if (value != null &&  isNaN(Number(value)) == false )  {
      return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
    return null;
  }


  onChangeProject(value: number): void
  {
    this.general.get('project').setValue(value);
    if (this.projectId != value)
    {
      this.areaId = null;
      this.area_list.clear();
      this.areaCode = null;

    }

    this.listAreaByCode = this.areasHaveContractList.filter((area) => area.project_id == value);
    this.codeAreas = [];
    this.areasHaveContractList.forEach((item: any) => {
      this.codeAreas.push(item.code);
    });

    if (!this.id)
    {
      this.projetosService.GetProject(value).subscribe((response: any) =>
      {
        let data = response.data;

        this.business_conditions.get('inflationIndex').setValue(data.tdc_id);
        this.pre_operation.get('remuneration_type').setValue(data.trc_id);
        this.pre_operation.get('frequency').setValue(data.per_id_valor);
        this.pre_operation.get('contract_deadline').setValue(data.prj_prazo_anos);
        this.pre_operation.get('remuneration_anemometric_tower').setValue(data.prj_remun_por_torre_anemometrica);
        this.pre_operation.get('value_per_anemometric_tower').setValue((data.prj_valor_por_torre_anenometrica));
        this.pre_operation.get('frequency_anemometric_tower').setValue(data.per_id_torre_anemometrica);
        this.pre_operation.get('value_per_period').setValue((data.prj_valor));
        this.pre_operation.get('value_per_hectare').setValue((data.prj_valor));

        this.commercial_operation.get('remuneration_type').setValue(data.tra_id);
        this.commercial_operation.get('value_per_wind_generator').setValue((data.prj_valor_aerogerador));
        this.commercial_operation.get('percentage_over_generation').setValue(this.formatNumberBR(data.prj_porc_sobre_geracao));
        this.commercial_operation.get('value_per_hectare').setValue((data.prj_valor_ha));
        this.commercial_operation.get('frequency').setValue(data.per_id_aerogerador);
      })
    }
  }

  onChangeTabIndexOwner(index: number): void {
    const state_id = this.owner_list.getRawValue()[index].address.state_id;
    this.searchStateOwner(state_id);
  }


  syncHeritage() {

    this.owner_list.controls.forEach(owners => {
      if (this.owners_general.get('heritage')) {
        owners.get('person').get('is_heir').setValue(owners.get('person').get('owner').value);
        owners.get('person').get('owner').setValue(false);
      } else {
        owners.get('person').get('owner').setValue(owners.get('person').get('is_heir').value);
        owners.get('person').get('is_heir').setValue(false);
      }
    });
  }

  onChangeHectareContract(value): void {
    this.business_conditions.get('hectare_total_contract').setValue(this.formatNumberBR(this.calcHectareTotalContract()));
  }


  calcHectareTotalContract(): number {
    let hectare_total_contract = 0;

    this.area_list.controls.forEach(area => {
      const contract_hectare = this.toFloat(area.get('contract_hectare').value || 0);
      hectare_total_contract += contract_hectare;
    });

    return hectare_total_contract;
  }

  addArea(): void {
    if (!this.areaCode) {
      return;
    }

    if (!this.areaIsValid(this.areaCode.code)) {
      return;
    }

    if(this.areaCode.status.id !== 1)
    {
      this.notification.error('Esta área ainda não foi aprovada. Por favor, procurar o departamento técnico.', '');
      return;
    }



    const area = this.nullResponseArea();
    area.code = this.areaCode.code;
    area.is_pre_approved = this.areaCode.status.id == 1 ? true : false
    //this.registerAreasList.push(this.areaCode);
    area.area_id = this.areaCode.id
    area.project_id = this.general.get('project').value;
    this.createFormArea(area);
  }

  addContractAreas(contractAreas) {
    contractAreas.forEach( response => {
      const contractArea = {
        code: response.code,
        is_pre_approved: response.is_pre_approved,
        project_id: response.project_id,
        id: response.id,
        contract_id: response.contract_id,
        area_id: response.area_id,
        is_partial_lease: response.is_partial_lease,
        partial_lease_reason: response.partial_lease_reason,
        contract_hectare: this.formatNumberBR(response.contract_hectare),
        assets: response.assets,
        observation: response.observation,
        deleted: response.deleted,
        name: response.name,
        attachment_image_id: response.attachment_image_id,
        amount_wind_turbines: response.amount_wind_turbines,
        type_document_id: response.type_document_id,
        situation_id: response.situation_id,
        number_property: response.number_property,
        registration: response.registration,
        date_document: response.date_document,
        registry: response.registry,
        gravame: response.gravame,
        gravame_value: response.gravame_value,
        squatter: response.squatter,
        owner_document: response.owner_document,
        pendencies_regularize: response.pendencies_regularize,
        time_regularize: response.time_regularize,
        hectare_document: this.formatNumberBR(response.hectare_document),
        code_incra: response.code_incra,
        hectare_incra: this.formatNumberBR(response.hectare_incra),
        state_area_id: response.state_area_id,
        city_area_id: response.city_area_id,
        nirf: response.nirf,
        hectare_receita_federal: this.formatNumberBR(response.hectare_receita_federal),
        ccir: response.ccir,
        hectare_ccir: this.formatNumberBR(response.hectare_ccir),
        has_competitor: response.has_competitor,
        competing_company: response.competing_company,
        type_competing_company: response.type_competing_company,
        link_maps: response.link_maps,
        //attachment_property_id: response.attachment_property_id,
        attachment_last_issue_id: response.attachment_last_issue_id,
        attachment_last_decl_id: response.attachment_last_decl_id,
        attachment_propertys: response.attachment_propertys,
        attachment_last_issue: response.attachment_last_issue,
        attachment_last_decl: response.attachment_last_decl,
        attachment_property_text: response.attachment_property_text,
        attachment_last_issue_text: response.attachment_last_issue_text,
        attachment_last_decl_text: response.attachment_last_decl_text,
        bloquearTudo: this.bloquearTudo
      }

      this.createFormArea(contractArea);
    });
    // this.createFormArea(contractAreas);
  }

  changeTabArea(index) {
    const formArea: any = { ...this.area_list.controls[index] };
  }

  areaIsValid(code): boolean {
    if (this.areaAlreadyExistsInList(code)) {
      this.notification.error('Está área já foi adicionada', '');
      return false;
    }

    return true;
  }

  areaAlreadyExistsInList(code): boolean {
    return this.area_list.getRawValue().some((areaItem) => {
      return areaItem.code == code;
    })
  }

  setAreaTableIndex(index: number): void {
    this.selectedIndexTableArea = index;
  }

  deleteArea(index: number): void {
    const areaSelected = this.area_list.value[index];
    //this.areaList = this.areaList.filter((area) => area.id !== areaSelected.id);
    this.area_list.removeAt(index);
  }

  addContractList(selectedArea) {
    this.contract_list.setValue(this.createFormGroupContract(selectedArea));
  }

  getAreaById(id: number): any {
    return this.area_list.getRawValue().find(area => {
      return area.id == id;
    })
  }

  /*
  getConjuge(owner) {
    this.personService.GetOnlyPerson(owner.person.spouse_id).subscribe((response: any) => {
      owner.partner = this.mapResponsePeople(response.data);

      this.owner_list.push(this.createFormGroupOwner(owner));

      if (this.owner_list.length === 1) {
        this.onChangeTabIndexOwner(0);
      }
      this.ownerId.setValue(null);
    });
  }
  */

/*
  ownerIsValid(id: number): boolean {
    if (this.ownerAlreadyExistsInList(id)) {
      this.notification.error('Esta pessoa já foi adicionada', '');
      return false;
    }

    return true;
  }

  ownerAlreadyExistsInList(id: number): boolean {
    return this.owner_list.getRawValue().some((ownerItem) => {
      return ownerItem.person.id === id;
    })
  }
*/


/*
  mapListSelectedOwner = (item: any): ComboDefault => {
    return {
      id: item.person.id,
      desc: item.person.name,
    }
  }
  */
/*
  hasOwnerInListSelectedOwner(id: number) {
    return this.getOwnerById(id);
  }

  getOwnerById(id: number): ComboDefault {
    return this.listSelectedOwner.find((item) => {
      return item.id == id;
    })
  }
  */
/*
  getOwnerInOwnerListById(id: number): any {
    return this.owner_list.getRawValue().find((ownerItem) => {
      return ownerItem.person.id == id;
    })
  }
*/






  /*

  pushPersonsSelected(data) {
    this.personsSelected.push(data);
  }

  */




  toFloat(number) {
    if (number && typeof(number) == "string") {
      if (number.indexOf(",") > 0)
        return parseFloat(number.replace(".","").replace(",","."))
      else
        return parseFloat(number)
    }

    if (number)
      return parseFloat(number);
    return number;
  }


  /*
  formatFloatNumber(value) {
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2});
    return formatter.format(value);
  }
  */
  /*
  toMonetaryFormat(value: number): string {
    return 'R$ ' + value.toFixed(2).replace('.', ',');
  }
  */

  searchStateOwner(id: number): void {
    if (id) {
      this.serviceShared.ComboCity(id).subscribe((res: any) => {
        this.listCityOwner = res.data;
      });
    }
  }

  onChangePrimaryContactState(id: number): void {
    this.searchStatePrimaryContact(id);
    this.primary_contact.get('city_id').setValue(null);
  }

  searchStatePrimaryContact(id: number): void {
    if (id) {
      this.serviceShared.ComboCity(id).subscribe((res: any) => {
        this.listCityPrimaryContact = res.data;
      });
    }
  }



  /*
  setFieldNameUpload(fieldName: string): void {
    this.fieldNameUpload = fieldName;
  }


  beforeUploadArea = (file: any): boolean => {
    const formData = new FormData();
    formData.append('file', file);
    this.service.PostUpload(formData, this.requisitionData.id).subscribe((response: any) => {
      file['id'] = response.id;
      this.area_list.controls[this.activeTabIndexArea].get(this.fieldNameUpload).setValue(file['id']);
    });
    return false;
  }

  handlePreviewPeopleAttachment = (file: any) => {
    this.viewArchive(file);
  }

  handlePreview = (file: any) => {
    this.viewArchive(file);
  }

  viewArchive(file) {
    this.service.downloadFile(file.filename, file.uuid_file).subscribe((response: any) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    }, error => {
      this.notification.error("erro ao baixar arquivo", '');
    });
  }
  */
/*
  getContractAreaFields(areaId, field){
    let response = null
    if (this.requisitionData) {
      this.requisitionData.new_contract_areas.forEach(contract => {
        if(contract.area_id == areaId) {
          response = contract[field]
        }
      });
    }
    return response
  }
*/

  nullResponseArea(): any {
    return {
      area_id: null,
      id: null,
      contract_hectare: null,
      code: null,
      name: null,
      project_id: null,
      attachment_image: null,
      type_document_id: null,
      situation_id: null,
      number_property: null,
      registration: null,
      date_document: null,
      registry: null,
      gravame: false,
      gravame_value: null,
      squatter: false,
      owner_document: false,
      pendencies_regularize: null,
      time_regularize: null,
      hectare_document: null,
      code_incra: null,
      hectare_incra: null,
      state_area_id: null,
      city_area_id: null,
      nirf: null,
      hectare_receita_federal: null,
      //attachment_last_decl: null,
      ccir: null,
      hectare_ccir: null,
      //attachment_last_issue: null,
      attachment_kmz: null,
      observation: null,
      has_competitor: false,
      competing_company: null,
      type_competing_company: null,
      project_name: null,
      inactive_contract_list: [],
      is_pre_approved: false,
      link_maps: null,
      name_property: null,
      situation: null,
      //attachment_property_id: null,
      number_document: null,
      pending_issues: null,
      regularization_time: null,
      has_competition: null,
      competition_type: null,
      bloquearTudo: false,
      amount_wind_turbines: 0,
    };
  }

  mapResponseArea(data: any): any { // TODO: Edicao


     return {
      id: data.area_id,
      code: data.code,
      inactive_contract_list: data.inative_contracts ? data.inative_contracts.map(this.mapInactiveContract) : [],
      is_pre_approved: data.status ? data.status.id : false,
      link_maps: data.link_maps || null,
      name: data.name.trim(),
      observation: data.observation,
      situation_id: data.situation_id,
      number_property: data.number_property,
      registration: data.registration,
      date_document: data.date_document,
      registry: data.registry,
      gravame: data.gravame,
      gravame_value: data.gravame_value,
      squatter: data.squatter,
      owner_document: data.owner_document,
      pendencies_regularize: data.pendencies_regularize,
      time_regularize: data.time_regularize,
      hectare_document: this.formatNumberBR(data.hectare_document),
      code_incra: data.code_incra,
      hectare_incra: this.formatNumberBR(data.hectare_incra),
      state_area_id: data.state_area_id,
      city_area_id: data.city_area_id,
      nirf: data.nirf,
      hectare_receita_federal: this.formatNumberBR(data.hectare_receita_federal),
      ccir: data.ccir,
      hectare_ccir: this.formatNumberBR(data.hectare_ccir),
      has_competitor: data.has_competitor,
      type_competing_company: data.competitor ? data.competitor.id : null,
      competing_company: data.competing_company,
      type_document_id: data.type_document_id,
      //contract_hectare: this.formatNumberBR(data.contract_hectare),
      project_id: data.project_id,
      attachment_image_id: data.attachment_image,
      attachment_kmz_id: data.attachment_kmz ? data.attachment_kmz.id : null,

    };

  }

  mapInactiveContract(data) {
    return {
      id: data.id,
      area: data.area_id ? {
        code: data.area_id
      } : null
    };
  }

  mapResponsePeople(data: any): any {
    data.address['issuing_agency'] = data.issuing_agency
    return {
      person: data ? this.mapResponsePerson(data) : null,
      address: data && data.address ? this.mapResponseAddress(data.address) : null,
      bank: data && data.bank_data ? this.mapResponseBank(data.bank_data) : null,
      partner: null
    };
  }

  mapResponsePerson(data: any): any {
    return {
      id: data.id,
      name: data.name.trim(),
      cpf: data.cpf,
      rg: data.rg,
      issuing_agency: data.issuing_agency,
      birth_date: data.birth_date,
      nationality: data.nationality,
      telephone: data.telephone,
      cellphone: data.cellphone,
      gender_id: data.gender.id,
      marital_status_id: data.marital_status.id,
      job: data.job,
      spouse_id: data.spouse_id,
      deceased: data.deceased,
      deceased_date: data.deceased_date
    };
  }

  mapResponseAddress(data: any): any {
    return {
      zip_code: data.zip_code,
      neighborhood: data.neighborhood,
      state_id: data.state.id,
      city_id: data.city.id,
      number: data.number,
      complement: data.complement,
      public_place: data.public_place,
      issuing_agency: data.issuing_agency
    };
  }

  mapResponseBank(data: any): any {
    return {
      bank_id: data.bank.id,
      account_type: data.checking_account ? AccountType.Corrente : AccountType.Poupança,
      account: data.account,
      agency: data.agency,
      checking_account: data.checking_account,
      savings_account: data.savings_account
    };
  }
  deleteOwner(index: number): void {
    this.owner_list.removeAt(index);
  }

  mapPayloadOwner = (owner_list) => {
    var ret_owner_list = [];

    owner_list.forEach(data => {
      const response: any = {
        id: data.person.id,
        is_heir: data.person.is_heir,
        owner: data.person.owner,
        main_contact: data.person.main_contact,
        beneficiary: data.person.beneficiary,
        consenting: data.person.consenting,
        bank_id: data.bank.bank_id,
        checking_account: data.bank.account_type == 1 ? true : false,
        savings_account: data.bank.account_type == 2 ? true : false,
        account: data.bank.account,
        agency: data.bank.agency,
        name: data.person.name.trim(),
        cpf: data.person.cpf,
        rg: data.person.rg,
        issuing_agency: data.person.issuing_agency,
        birth_date: data.person.birth_date,
        nationality: data.person.nationality,
        marital_status_id: data.person.marital_status_id,
        gender_id: data.person.gender_id,
        telephone: data.person.telephone,
        cellphone: data.person.cellphone,
        deceased:  data.person.deceased,
        deceased_date: data.person.deceased_date,
        job: data.person.job,
        //spouse_id: data.person.spouse_id,
        zip_code: data.address.zip_code,
        neighborhood: data.address.neighborhood,
        state_id: data.address.state_id,
        city_id: data.address.city_id,
        number: data.address.number,
        public_place: data.address.public_place,
        complement: data.address.complement,
        apportionment_percent: this.toFloat(data.person.apportionment_percent),

        cpf_file_front: this.createFilePayload(data.attachments.owner_cpf_frente_text, data.attachments.owner_cpf_frente),
        cpf_file_back: this.createFilePayload(data.attachments.owner_cpf_verso_text, data.attachments.owner_cpf_verso),
        rg_file_front: this.createFilePayload(data.attachments.owner_rg_frente_text, data.attachments.owner_rg_frente),
        rg_file_back: this.createFilePayload(data.attachments.owner_rg_verso_text, data.attachments.owner_rg_verso),
        residence_file: this.createFilePayload(data.attachments.proof_address_text, data.attachments.proof_address),
        death_file: this.createFilePayload(data.attachments.death_text, data.attachments.death_certificate),
        marriage_file: this.createFilePayload(data.attachments.marriage_text, data.attachments.marriage_certificate),
      };

      ret_owner_list.push(response);

    });

    return ret_owner_list;
  }


  mapPayloadArea(area_list) {
    area_list.forEach(area => {
      area.gravame = area.gravame
      area.gravame_value = area.gravame_value ? this.toFloat(area.gravame_value) : null;

      area.contract_hectare = area.contract_hectare ? this.toFloat(area.contract_hectare) : null;
      area.hectare_ccir = area.hectare_ccir ? this.toFloat(area.hectare_ccir) : null;
      area.hectare_document = area.hectare_document ? this.toFloat(area.hectare_document) : null;
      area.hectare_incra = area.hectare_incra ? this.toFloat(area.hectare_incra) : null;
      area.hectare_receita_federal = area.hectare_receita_federal ? this.toFloat(area.hectare_receita_federal) : null;


      // if (area.date_document) {
      //     area.date_document = moment(area.date_document, 'DD/MM/YYYY');
      // }
      area.attachment_propertys =  this.createFilePayload(area.attachment_property_text, area.attachment_propertys);
      area.attachment_last_issue =  this.createFilePayload(area.attachment_last_issue_text, area.attachment_last_issue);
      area.attachment_last_decl =  this.createFilePayload(area.attachment_last_decl_text, area.attachment_last_decl);

      // delete area.attachment_property_id;
      // delete area.attachment_last_issue_id;
      // delete area.attachment_last_decl_id;
    });

    return area_list;
  }

  createFilePayload(base64 : string | null, dadosArquivo: any) : any {
    if (base64 == null)
      return null;

    var temp = base64.split(",");

    var tempExt = temp[0].replace("data:","").replace(";base64","");
    var ext = "";
    var file_id = null

    if (tempExt.indexOf("png") >= 0)
      ext = ".png"
    else if (tempExt.indexOf("jpeg") >= 0)
      ext = ".jpeg"
    else if (tempExt.indexOf("jpg") >= 0)
      ext = ".jpg"
    else if (tempExt.indexOf("pdf") >= 0)
      ext = ".pdf"

      if (dadosArquivo != null)
        file_id = dadosArquivo.id

    return {
      file : temp[1],
      extension : ext,
      id: file_id
    }
  }


  mapPayloadCreateRequisition(data) {
    try {
      const response = {
        project_id: data.general.project,
        type_contract_id: data.polygon_view.contract_type,
        reason_new_contract_id: data.polygon_view.reason_new_contract,
        reason: data.polygon_view.reason,
        observation: data.polygon_view.observation,
        areas: this.mapPayloadArea(data.property_information.area_list),
        people: this.mapPayloadOwner(data.owners.general.owner_list),
        espolio: data.owners.general.heritage,

        contract_remuneration_type_id: data.business_conditions.pre_operation.remuneration_type,
        deadline_years: Number(data.business_conditions.pre_operation.contract_deadline),
        frequency_id: data.business_conditions.pre_operation.frequency,
        is_remuneration_by_anemometric_tower: data.business_conditions.pre_operation.remuneration_anemometric_tower,
        value_per_anemometric_tower: data.business_conditions.pre_operation.value_per_anemometric_tower != null ? Number(data.business_conditions.pre_operation.value_per_anemometric_tower) : null,
        anemometric_tower_frequency_id: data.business_conditions.pre_operation.frequency_anemometric_tower,
        advance: data.business_conditions.pre_operation.advance,
        advance_value: Number(data.business_conditions.pre_operation.value_advance),
        signal: data.business_conditions.pre_operation.signal,
        signal_value: Number(data.business_conditions.pre_operation.value_signal),
        pre_operation_observation: data.business_conditions.pre_operation.observation,
        pre_operation_value: Number(data.business_conditions.pre_operation.value_per_period),
        value_per_hectare_pre_operation_value: Number(data.business_conditions.pre_operation.value_per_hectare),

        wind_turbines_remuneration_type_id: data.business_conditions.commercial_operation.remuneration_type,
        value_per_wind_turbine: Number(data.business_conditions.commercial_operation.value_per_wind_generator),
        wind_turbine_frequency_id: data.business_conditions.commercial_operation.frequency,
        percentage_over_generation: (this.toFloat(data.business_conditions.commercial_operation.percentage_over_generation)),
        value_per_hectare_commercial_operation: Number(data.business_conditions.commercial_operation.value_per_hectare),
        commercial_observation: data.business_conditions.commercial_operation.observation,
        inflationIndex_id: data.business_conditions.inflationIndex,
        equal_apportionment: data.payment_informations.equal_apportionment,
        motivo_resilicao: data.motivo_resilicao,
        data_assinatura: null,
        property_signature_file: data.aprovacao.arquivo_contrato
      };


      if (this.contract_load_flow) {
        response.data_assinatura = this.form.get("aprovacao").get("data_assinatura").value;
      }


      return response;
    } catch (error) {
      this.notification.error('Um erro ocorreu', 'Verifique todos os campos do formulario.');
    }
  }


/*
  mapOwnerData(data) {
    const response = {
      approved: data.EngineeringApproval.approved,
      justification: data.EngineeringApproval.justification,
      attachment_file_id: data.EngineeringApproval.attachment_file_id,

      property_owners: data.owners.general.owner_list.map(this.mapPayloadOwner),

      primary_contact: {
        contact_person_id: data.owners.primary_contact.owner_heir,
        is_contact_owner_or_heir: data.owners.primary_contact.is_owner_heir,
        contact_name: data.owners.primary_contact.name,
        contact_phone: data.owners.primary_contact.telephone,
        contact_cel: data.owners.primary_contact.cellphone,
        contact_public_place: data.owners.primary_contact.public_place,
        contact_number: data.owners.primary_contact.number,
        contact_complement: data.owners.primary_contact.complement,
        contact_neighborhood: data.owners.primary_contact.neighborhood,
        city_id: data.owners.primary_contact.city_id,
        state_id: data.owners.primary_contact.state_id,
        contact_zip_code: data.owners.primary_contact.zip_code
      },

    };


    return response;
  }
*/


  validateFormGroup(formGroup: AbstractControl): void {
    for (const fieldName in formGroup['controls']) {
      //if (formGroup.get(fieldName).value == null) {
        formGroup.get(fieldName).markAsDirty();
        formGroup.get(fieldName).updateValueAndValidity();
      //}
    }

    this.listInvalidControls(formGroup);

  }

  invalid: string[] = [];
  listInvalidControls(form: AbstractControl): void {
    Object.keys(form['controls']).forEach((key: string) => {
      if (form.get(key).invalid) {

        this.invalid.push(key);

      }
    });
  }

/*
  ownerDataIsValid(): boolean {

    if (this.owners.invalid) {
      this.validateFormGroup(this.primary_contact);

      this.owner_list.controls.forEach(formGroupOwner => {
        this.validateFormGroup(formGroupOwner.get('attachments'));
      });


      return false;
    } else {
      return true;
    }
  }
*/

  formIsValid(): boolean {

    this.form.updateValueAndValidity();

    this.general.updateValueAndValidity();
    this.polygon_view.updateValueAndValidity();
    this.property_information.updateValueAndValidity();
    this.pre_operation.updateValueAndValidity();
    this.commercial_operation.updateValueAndValidity();
    this.owner_list.updateValueAndValidity();
    this.area_list.updateValueAndValidity();




    let beneficiarios = [];
    let contatoprincipal = [];

    this.invalid = [];

    this.validateFormGroup(this.general);
    this.validateFormGroup(this.property_information);
    this.validateFormGroup(this.polygon_view);
    this.validateFormGroup(this.pre_operation);
    this.validateFormGroup(this.commercial_operation);
    this.validateFormGroup(this.owners_general);
    this.validateFormGroup(this.business_conditions);



    this.area_list.controls.forEach(formGroupArea => {
      formGroupArea.updateValueAndValidity();

      this.validateFormGroup(formGroupArea);
    });

    this.owner_list.controls.forEach(owners =>
    {

      if(owners.get('person').get('owner').value)
      {
        owners.get('person').get('consenting').setValue(false);

        owners.get('address').get('zip_code').setValidators(Validators.required);
        owners.get('address').get('zip_code').updateValueAndValidity();
        owners.get('address').get('neighborhood').setValidators(Validators.required);
        owners.get('address').get('neighborhood').updateValueAndValidity();
        owners.get('address').get('state_id').setValidators(Validators.required);
        owners.get('address').get('state_id').updateValueAndValidity();
        owners.get('address').get('city_id').setValidators(Validators.required);
        owners.get('address').get('city_id').updateValueAndValidity();
        owners.get('address').get('number').setValidators(Validators.required);
        owners.get('address').get('number').updateValueAndValidity();
        owners.get('address').get('public_place').setValidators(Validators.required);
        owners.get('address').get('public_place').updateValueAndValidity();

      } else {

        owners.get('address').get('zip_code').setValidators(null);
        owners.get('address').get('zip_code').updateValueAndValidity();
        owners.get('address').get('neighborhood').setValidators(null);
        owners.get('address').get('neighborhood').updateValueAndValidity();
        owners.get('address').get('state_id').setValidators(null);
        owners.get('address').get('state_id').updateValueAndValidity();
        owners.get('address').get('city_id').setValidators(null);
        owners.get('address').get('city_id').updateValueAndValidity();
        owners.get('address').get('number').setValidators(null);
        owners.get('address').get('number').updateValueAndValidity();
        owners.get('address').get('public_place').setValidators(null);
        owners.get('address').get('public_place').updateValueAndValidity();

      }



      if(owners.get('person').get('deceased').value)
      {
        owners.get('person').get('deceased_date').setValidators(Validators.required);
        owners.get('person').get('deceased_date').updateValueAndValidity();

        if (!this.contract_load_flow) {
          owners.get('attachments').get('death_text').setValidators(Validators.required);
          owners.get('attachments').get('death_text').updateValueAndValidity();
        }
        owners.get('person').get('marital_status_id').setValue(null);
        owners.get('person').get('marital_status_id').setValidators(null);
        owners.get('person').get('marital_status_id').updateValueAndValidity();

      }
      else
      {
        owners.get('person').get('deceased_date').setValidators(null);
        owners.get('person').get('deceased_date').updateValueAndValidity();

        if (!this.contract_load_flow) {
          owners.get('attachments').get('death_text').setValidators(null);
          owners.get('attachments').get('death_text').updateValueAndValidity();
        }

        owners.get('person').get('marital_status_id').setValidators(Validators.required);
        owners.get('person').get('marital_status_id').updateValueAndValidity();

      }

      if (!this.contract_load_flow) {
        if(owners.get('person').get('marital_status_id').value == 2)
        {
          owners.get('attachments').get('marriage_text').setValidators(Validators.required);
          owners.get('attachments').get('marriage_text').updateValueAndValidity();

        }
        else
        {
          owners.get('attachments').get('marriage_text').setValidators(null);
          owners.get('attachments').get('marriage_text').updateValueAndValidity();

        }
      }



      if(owners.get('person').get('is_heir').value)
      {
        owners.get('person').get('consenting').setValue(false);
      }

      if(owners.get('person').get('beneficiary').value)
      {
        owners.get('bank').get('account').setValidators(Validators.required);
        owners.get('bank').get('account').updateValueAndValidity();
        owners.get('bank').get('agency').setValidators(Validators.required);
        owners.get('bank').get('agency').updateValueAndValidity();
        owners.get('bank').get('bank_id').setValidators(Validators.required);
        owners.get('bank').get('bank_id').updateValueAndValidity();
      }
      else
      {
        owners.get('bank').get('account').setValidators(null);
        owners.get('bank').get('account').updateValueAndValidity();
        owners.get('bank').get('agency').setValidators(null);
        owners.get('bank').get('agency').updateValueAndValidity();
        owners.get('bank').get('bank_id').setValidators(null);
        owners.get('bank').get('bank_id').updateValueAndValidity();
      }


      owners.updateValueAndValidity();
      

      this.validateFormGroup(owners.get('person'));
      this.validateFormGroup(owners.get('address'));
      this.validateFormGroup(owners.get('bank'));
      this.validateFormGroup(owners.get('attachments'));

      beneficiarios.push({beneficiario:owners.get('person').get('beneficiary').value});
      contatoprincipal.push({contato: owners.get('person').get('main_contact').value})
    });


    let tem_beneficiario = beneficiarios.filter((f) => f.beneficiario === true);
    let tem_contato_principal = contatoprincipal.filter((f) => f.contato === true);

    if(this.owner_list.length === 0)
    {
      this.notification.error('Contrato sem proprietários. Por favor, inclua um proprietário', '');
      return false
    }


    if(tem_beneficiario.length === 0)
    {
      this.notification.error('Contrato sem beneficiário. Por favor, assinale um beneficiário', '');
      return false
    }

    if(this.area_list.length === 0)
    {
      this.notification.error('Contrato sem área. Por favor, inclua ao menos uma área', '');
      return false
    }


    if(tem_contato_principal.length === 0)
    {
      this.notification.error('Contrato sem contato principal. Por favor, assinale um contato', '');
      return false
    }

    if(this.totalRateio != 100)
    {
      this.notification.error('O valor total do rateio deve ser 100%', '');
      return false
    }

    
    if (this.contract_load_flow) {
      if (this.form.get("aprovacao").get("data_assinatura").value == null) {
        this.notification.error('Preencha a data de assinatura do contrato.', '');
        return false;
      }
    }


    if (this.general.invalid ||
      this.polygon_view.invalid ||
      this.property_information.invalid ||
      this.pre_operation.invalid ||
      this.commercial_operation.invalid ||
      this.owner_list.invalid ||
      this.area_list.invalid
      ) {

        this.notification.error('Campos obrigatórios pendentes de preenchimento.', '');
        return false;
      } 
      return true;

  }

  getAtachmentName(fileName) {
    return this[fileName].filename
  }

  removeNullInForm(data) {
    Object.keys(data).forEach(key => {
      if (data[key] == null) {
        delete data[key];
      }

      if (key === 'area_ids') {
        const temp = [];
        data[key].forEach(element => {
          temp.push(element.id);
        });

        data[key] = temp;
      }
    });

    if (data.areas.length > 0) {
      data.areas.forEach((area) => {
        Object.keys(area).forEach((key) => {
          if (area[key] == null) {
            delete area[key];
          }
          if (area[key] === ""){
            delete area[key];
          }
        });
      });
    }

    if (data.areas.length == 0) {
      delete data.areas
    }
  }

  save(data, is_draft): void {
    /*
    this.removeNullInForm(data);
    */
    if(!this.aditivo && !this.resilicao)
    {
        this.service.createContractRequisition(data, is_draft).subscribe((response: any) => {
          this.back();
        }, (responseError) => {
          this.notification.error(responseError.error.data, '');
        });
    }
    if(this.aditivo)
    {
      this.service.addAditivo(data, is_draft).subscribe((response: any) => {
        this.back();
      }, (responseError) => {
        this.notification.error(responseError.error.data, '');
      });
    }

    if(this.resilicao)
    {
      let resilicao_text = this.form.get('motivo_resilicao').value;

      if(!resilicao_text)
      {
        this.form.get('motivo_resilicao').setValidators([Validators.required]);
        this.form.get('motivo_resilicao').updateValueAndValidity();
        this.notification.error('Por favor, informe o motivo da resilição', '');
        return
      }

      let data = {
        id: this.idContratoAntigo,
        motivo_resilicao: this.form.get('motivo_resilicao').value
      }
      this.service.addResilicao(data, is_draft).subscribe((response: any) => {
        this.back();
      }, (responseError) => {
        this.notification.error(responseError.error.data, '');
      });
    }

  }

  update(data, is_draft): void {

    this.removeNullInForm(data);
    this.service.updateContract(this.id, data, is_draft).subscribe((response: any) => {

      this.back();

    });

  }


  back(): void {
    setTimeout(() => {
      if(this.mostrar_area_aprovacao)
      {
        this.router.navigate(['/pendencia/pendencias']);
      }
      else
      {
        this.router.navigate(['/cadastro/listagem-contratos']);
      }

    });
  }

  backListContract() {
    if (this.previousPage != "") {
      this.router.navigate([this.previousPage]);
    } else {
      this.router.navigate(['/cadastro/listagem-contratos']);
    }
  }


  cancel(): void {
    this.dialogService.confirm('Descartar dados', 'Deseja realmente cancelar? Todas alterações serão perdidas!', () => {
      this.service.cancelRequisition(this.id).subscribe(() => {
        setTimeout(() => {
          this.notification.success('Solicitação cancelada com sucesso.', '');
          this.back();
        });
      }, (responseError) => {
        this.notification.error(responseError.error.data, '');
      });
    });
  }

  /*
  handleOk(): void {
    setTimeout(() => {
      this.isVisible = false;
    }, 3000);
  }

  handleCancel() {
    this.isVisible = false;
  }
  */


  reasonNewContractRequiredValidator(control: AbstractControl) {
    /*
    if (!control.parent) {
        return null;
    }

    if (this.has_prev_contract && !control.value) {
        return { required: true };
    }
    */
    return null;
  }

  saveDraft(): void {
    this.dialogService.confirm('Salvar Rascunho', 'Deseja salvar solicitação como rascunho?', () => {
      const data = this.mapPayloadCreateRequisition(this.form.getRawValue());

      console.log(data);

      if(this.aditivo || this.resilicao)
        data['id'] = this.idContratoAntigo;

      if (this.id && !this.idContratoAntigo) {
        this.update(data, true);
      } else {
        this.save(data, true);
      }
    });
  }




  onSubmit(): void
  {
    this.hasSubmitted = true;
    
    if (this.formIsValid())
    {

      const data = this.mapPayloadCreateRequisition(this.form.getRawValue());

      if(this.aditivo || this.resilicao)
        data['id'] = this.idContratoAntigo;


      if (this.id && !this.idContratoAntigo)
      {
        this.update(data, false);
      }
      else
      {
        this.save(data, false);
      }

    }
  }

  reprovacao(): void {
    this.hasSubmitted = true;

    const payload = {
      approved: false,
      justification: this.form.get("aprovacao").get("justificativa").value,
      contract_file: this.createFilePayload(this.form.get("aprovacao").get("arquivo_contrato").value, null),
      property_signature_file: this.createFilePayload(this.form.get("aprovacao").get("assinatura_proprietario").value, null),
      data_assinatura: this.form.get("aprovacao").get("data_assinatura").value,
      data_recebimento: this.form.get("aprovacao").get("data_recebimento").value,
      localidade: this.form.get("aprovacao").get("localidade").value,
      armario: this.form.get("aprovacao").get("armario").value,
      caixa: this.form.get("aprovacao").get("caixa").value,
      numero_contrato_totvs: this.form.get("aprovacao").get("numero_contrato_totvs").value,
    }

    this.service.aprovacao(this.id, payload).subscribe((response: any) => {

      this.router.navigate(['/pendencia/pendencias']);

    });
  }

  aprovacao(): void {

    this.listInvalidControls(this.form.get("aprovacao"));
    
    if(this.form.get("aprovacao").valid)
    {
      this.hasSubmitted = true;
      const payload = {
        approved: true,
        justification: this.form.get("aprovacao").get("justificativa").value,
        contract_file: this.createFilePayload(this.form.get("aprovacao").get("arquivo_contrato").value, null),
        default_draft: this.form.get("aprovacao").get("default_draft").value,
        property_signature_file: this.createFilePayload(this.form.get("aprovacao").get("assinatura_proprietario").value, null),
        data_assinatura: this.form.get("aprovacao").get("data_assinatura").value,
        data_recebimento: this.form.get("aprovacao").get("data_recebimento").value,
        localidade: this.form.get("aprovacao").get("localidade").value,
        armario: this.form.get("aprovacao").get("armario").value,
        caixa: this.form.get("aprovacao").get("caixa").value,
        numero_contrato_totvs: this.form.get("aprovacao").get("numero_contrato_totvs").value
      }

      this.service.aprovacao(this.id, payload).subscribe((response: any) => {
        this.router.navigate(['/pendencia/pendencias']);
      });
    }
    else
    {
      this.notification.error('Campos obrigatórios na aprovação pendentes de preenchimento.', '');
      Object.values(this.form.controls).forEach((control) =>
      {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
      });
    }
  }


  getProjectName(projec_id) {
    let name = "-"
    this.listProject.forEach(project => {
      if (project.id == projec_id) {
        name = project.desc
      }
    })
    return name
  }



  isJson(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
  }

  beforeUploadArquivoContrato = (file: any): boolean => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.controls['aprovacao'].get('arquivo_contrato').setValue(reader.result.toString());
    };

   return false;
  }

  beforeUploadAssinaturaProprietario = (file: any): boolean => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.controls['aprovacao'].get('assinatura_proprietario').setValue(reader.result.toString());
    };

   return false;
  }


  getAreaListLength = () : number => {
    return (<FormArray>this.form.get('property_information').get('area_list')).length;
  }


  downloadContrato = () => {
    const downloadLink = document.createElement('a');
    const fileName = "contrato";
    downloadLink.href = this.requisitionData.signed_contract_text;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  downloadTemplate = () => {
    console.log("Entrou aqui")
    this.service.getTemplate(this.id).subscribe( (data:any) => {
      console.log("Recebeu resposta")
      console.log(data)
      const downloadLink = document.createElement('a');
      downloadLink.href = data.content
      downloadLink.download = data.name
      downloadLink.click();
    });

  }


}
