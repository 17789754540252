import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectAuthToken } from '../auth.selectors';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Logout } from '../auth.actions';
import { NzNotificationService } from 'ng-zorro-antd';
import { SharedState } from '../../shared/shared.reducer';
import { ShowLoading, HideLoading } from '../../shared/shared.actions';
import { isArray } from 'util';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  authToken: string;

  constructor(private store: Store<AppState>, private sharedStore: Store<SharedState>, private notification: NzNotificationService) {
    store.pipe(select(selectAuthToken)).subscribe(token => {
      this.authToken = token;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('auth/')) {
      setTimeout(() => this.sharedStore.dispatch(new ShowLoading()), 0);
      const method = req.method;

      let request: Observable<HttpEvent<any>>;
      if (this.authToken) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authToken}`,
            
          }
        });
      }

      request = next.handle(req).pipe(timeout(600000));

      return request.pipe(
        catchError((errorResponse: any) => {

          if (errorResponse.status === 401) {
            this.store.dispatch(new Logout({ error: '' }));
          }

          if (errorResponse.status === 400) {
            if (errorResponse.error.data.errors) {
              if (typeof errorResponse.error.data.errors == 'string') {
                this.notification.error(errorResponse.error.data.errors, '');
              } else {
                Object.keys(errorResponse.error.data.errors).forEach(key => {
                  this.notification.error(key +' '+errorResponse.error.data.errors[key], '');
                })
              }
            } else {
              this.notification.error(errorResponse.error.data, '');
            }
          }

          if (errorResponse.status === 500) {
            this.notification.error(errorResponse.error.data, '');
          }

          return throwError(errorResponse);
        }),
        tap((response: HttpResponse<any>) => {
          if (response.status === 200 && (method === 'POST' || method === 'PUT')) {
            if (!response.body.message) {
              this.notification.success('Informações enviadas com sucesso.', '');
            } else {
              this.notification.success(response.body.message, '');
            }
          }
        }),
        finalize(() => {
          this.sharedStore.dispatch(new HideLoading());
        })
      );
    }

    return next.handle(req);
  }

}
