import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastroUsuarioComponent } from './pages/cadastro-usuario/cadastro-usuario.component';
import { FormCadastroUsuarioComponent } from './pages/form-cadastro-usuario/form-cadastro-usuario.component';
import { MasterPageComponent } from '../shared/pages/master-page/master-page.component';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ListagemSolicitacaoComponent } from './pages/listagem-solicitacao/listagem-solicitacao.component';
import Permissions from '../shared/enums/permissions.enum';

const routes: Routes = [
  {
    path: 'admin',
    component: MasterPageComponent,
    children: [
      {
        path: 'listagem-usuarios',
        component: CadastroUsuarioComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Usuários',
          permissions: [Permissions.ADMIM_USUARIOS_DO_SISTEMA]
        }
      },
      {
        path: 'form-cadastro-usuario',
        component: FormCadastroUsuarioComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro Usuário',
          permissions: [Permissions.ADMIM_USUARIOS_DO_SISTEMA]
        }
      },
      {
        path: 'form-cadastro-usuario/:id',
        component: FormCadastroUsuarioComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro Usuário',
          permissions: [Permissions.ADMIM_USUARIOS_DO_SISTEMA]
        }
      },
      {
        path: 'form-cadastro-usuario/view/:id',
        component: FormCadastroUsuarioComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Cadastro Usuário',
          permissions: [Permissions.ADMIM_USUARIOS_DO_SISTEMA]
        }
      },
      {
        path: 'listagem-solicitacao',
        component: ListagemSolicitacaoComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Solicitação',
          permissions: [Permissions.ADMIM_SOLICITACOES_VISUALIZACAO]
        }
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrativoRoutingModule { }
