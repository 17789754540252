import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-beneficiary-payments',
  templateUrl: './beneficiary-payments.component.html',
  styleUrls: ['./beneficiary-payments.component.scss']
})
export class BeneficiaryPaymentsComponent implements OnInit {
  @Input() listData = [];
  constructor() { }

  ngOnInit(): void {
  }

}
