import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import * as moment from 'moment';
import { AccountingEntryService } from '../../services/accounting-entry.service';
import { ReplanningSolcitationService } from '../../services/replanning-requisition.service';
import { NzNotificationService } from 'ng-zorro-antd';
import Permissions from '../../../../app/shared/enums/permissions.enum';
import { PlanningService } from '../../services/planning.service';
import { PlanningPeriodService } from '../../services/planning-period.service';
import { RealizedBudgetService } from '../../services/realized-budget.service';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'src/app/auth/auth.reducer';
import { PermissionService } from '../../../shared/services/permission.service';
const FileSaver = require('file-saver');
@Component({
  selector: 'app-listagem-de-custos',
  templateUrl: './listagem-de-custos.component.html',
  styleUrls: ['./listagem-de-custos.component.scss']
})
export class ListagemDeCustosComponent implements OnInit, OnChanges {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @Input() list;
  @Input() tab;
  @Input() users = [];
  @Input() page;
  @Input() synchronizedYearFilter;
  @Output() year = new EventEmitter();
  showUser$ = this.state.pipe(select(selectAuthUser));
  permissionsForPlanning= [
    Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO
  ];
  loggedUserId: number;
  showPlanningButton = false;
  requisitionId
  formData
  fileId
  attachment: any[];
  runningYear

  showJustificationInInvoiceControl = false;
  showInInvoiceControl = false
  listInvoices = []

  expandSet = new Set<number>();
  expandUser = new Set<number>();
  filterMonthsSet = new Set<string>();
  filterLinesSet = new Set<string>();
  yearsSet = new Set<string>();
  panels = [
    { active: true, name: 'Periodo', id: 1 },
    { active: false, name: 'Exibição', id: 2 },
    { active: false, name: 'Ano', id: 3 }
  ];

  months = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  lines = [
  { type: 'P', desc: 'Previsto' },
  { type: 'R', desc: 'Realizado' },
  { type: 'D', desc: 'Desvio Previsto x Realizado' },
  { type: 'A', desc: 'Assertividade' },
  { type: 'PR', desc: 'Projetado' },
  { type: 'DPR', desc: 'Desvio Projetado x Realizado' },
  { type: 'DPP', desc: 'Desvio Previsto x Projetado' },
  ]
  years = []

  active = false;
  atualMonth = parseInt(moment(new Date(), 'YYYY/MM/DD').format('M'));
  atualYear = parseInt(moment(new Date(), 'YYYY/MM/DD').format('Y'));
  previousMonth = this.atualMonth - 1;
  nextMonth = this.atualMonth + 1;
  group_nofs = []

  constructor(private accountingEntryService: AccountingEntryService,
    private replanningSolcitationService: ReplanningSolcitationService,
    private planningPeriodService: PlanningPeriodService,
    private planningService: PlanningService,
    private notification: NzNotificationService,
    private realizedBudgetService: RealizedBudgetService,
    private permissionService: PermissionService,
    private state: Store<AuthState>) { }

  ngOnInit(): void {
    this.accountingEntryService.getExceptionNofs().subscribe( (response: any) => {
      this.group_nofs = response.data.map(x => x.desc)
    })


    this.planningPeriodService.GetPlaningPeriods().subscribe((response: any) => {
      var atual_year = moment().format('YYYY');
      var lastYear = "0"
      response.data.forEach(item => {
        if(item.status_id != 1 && item.year <= atual_year) {
          this.years.push(item.year)
          if (parseInt(item.year) > parseInt(lastYear)) {
            lastYear = item.year
          }
        }
      });
      this.yearsSet.add(lastYear)
    })

    this.showUser$.subscribe((user: any) => {
      this.loggedUserId = user.id;
    });

    this.runningYear = moment().format('YYYY')
    this.yearsSet.add(this.runningYear);

    if (this.permissionService.canAny(Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO)){
      this.planningService.getBussinesDay().subscribe( (response: any) => {
        this.showPlanningButton = response.show_button;
      })
    }


    this.months.forEach((month, index) => {
      if (this.previousMonth == index + 1 || this.atualMonth == index + 1 || this.nextMonth == index + 1) {
        this.filterMonthsSet.add(month);
      }
    })

    this.lines.forEach(line => {
      if (line.type != 'DPR' && line.type != 'DPP'){
        this.filterLinesSet.add(line.type);
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.synchronizedYearFilter) {
      this.yearsSet = new Set<string>();
      this.yearsSet.add(changes.synchronizedYearFilter.currentValue);
    }
  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  getWidthOfTable() {
    return (this.filterMonthsSet.size * 115 + 430) + 'px';
  }


  onExpandUser(id: number, checked: boolean): void {
    if (!this.expandUser.has(id)) {
      this.expandUser.add(id);
    } else {
      this.expandUser.delete(id);
    }
  }



  onExpandChange(id: number, checked: boolean): void {
    if (!this.expandSet.has(id)) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  filterMonths(month: string, checked: boolean): void {
    if (!this.filterMonthsSet.has(month)) {
      this.filterMonthsSet.add(month);
    } else {
      this.filterMonthsSet.delete(month);
    }
  }

  filterLines(line: string): void {
    if (!this.filterLinesSet.has(line)) {
      this.filterLinesSet.add(line);
    } else {
      this.filterLinesSet.delete(line);
    }
  }

  filterYears(year: string): void {
    this.yearsSet = new Set<string>();
    this.yearsSet.add(year);
    this.year.emit(year)
  }

  showInvoices(line, hierarchy_list, month=null) {
    if (line == "R") {
      this.modal.showModal();

      var fluxo_medicao = 'Competência'

      if (this.tab == 1) {
        fluxo_medicao = 'Caixa'
      }

      this.getListInvoices(hierarchy_list, month, fluxo_medicao)
    }
  }

  hideModal() {
    this.modal.hideModal();
  }

  getListInvoices(hierarchy_list, month, fluxo_medicao) {
    this.showInInvoiceControl = false
    var filters = ''
    if (month != null) {
      filters = '&month='+ month
    }

    filters += '&fluxo_medicao='+ fluxo_medicao;


    this.accountingEntryService.getAccountingEntryByHierarchyId(0, hierarchy_list, filters).subscribe((response: any) => {
      this.listInvoices = response.data;
      this.showInInvoiceControl = true
    })
  }

  uploadReplanningArchive = (file: any): boolean => {
    if (!file.name.includes('.xlsx')) {
      this.notification.warning("Arquivo não possui formato valido","")
      return false;
    } else {
      this.formData = new FormData();
      this.formData.append('file', file);
      this.formData.append('column', 'file');
      this.formData.append('year', this.runningYear);
      this.formData.append('column', 'year');

      this.replanningSolcitationService.ValidateArchive(this.formData).subscribe( () => {
        this.createRequisition()
      }, error => {
        this.notification.error('Erro ao validar Anexo', '');
      })
      return false;
    }
  }

  createRequisition() {
    this.replanningSolcitationService.createReplanningRequisition({}).subscribe( (response: any) => {
      this.requisitionId = response.id
      this.uploadArchive()
    })
  }

  uploadArchive() {
    this.replanningSolcitationService.uploadReplanningArchive(this.requisitionId, this.formData).subscribe( (response : any) => {
      this.fileId = response.id
      this.updateRequisition(false)
    }, error => {
      this.updateRequisition(true)
    })
  }

  updateRequisition(deleted) {
    this.replanningSolcitationService.updateReplanningRequisition(this.requisitionId, {deleted: deleted, file_id: this.fileId}).subscribe( () => {
    })
  }

  DownloadReplanningArchive() {
    this.replanningSolcitationService.DownloadReplanningArchive(this.runningYear, this.tab == 1 ? 'financeiro': 'contabil').subscribe( (response : any) => {
      const csvName = 'Relatorio_solicitacao.xlsx';
      FileSaver.saveAs(response, csvName);
    });
  }

  DownloadReport() {
    var title = 'contabil'
    if (this.tab == 1) {
      title = 'financeiro'
    }
    this.realizedBudgetService.Download(this.synchronizedYearFilter, title).subscribe( (response: any) => {
      const csvName = 'Relatorio.csv';
      const blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(blob, csvName);
    });
  }

  RealizedValidation() {
    this.planningService.sendProjectedEmail().subscribe( (response: any) => {
    })
  }


  formatFloatNumber(value) {
    if (value == 0)
      return "-";
    let valor = Math.trunc(value);
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 0});
    return formatter.format(valor);
  }

  formatFloatAssertivenessNumber(value) {
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 3});
    return formatter.format(value);
  }

  imOnTheUserList() {
    return this.users.includes(this.loggedUserId)
  }

  group_nofs_includes(item, line) {
    return this.group_nofs.includes(item.title.toUpperCase()) && line == "A"
  }

  getValue(item , month, line) {
    var response = this.formatFloatNumber(item[month][line])

    if (line == 'A') {
      return this.formatFloatAssertivenessNumber(item[month][line]) + '%'
    }

    if (line == 'D') {
      return this.formatFloatNumber(this.deviation_calc(item[month]['P'], item[month]['R']))
    }

    response = this.formatFloatNumber(item[month][line])
    return response ? response : 0

  }

  deviationColor(line, type, month){
      var porcentagemDeDesvio = Math.abs((line[month]['R'] / line[month]['P']) - 100)
      if(line[month]['P'] > line[month]['R'] && porcentagemDeDesvio < 3){
        return 'color-yellow'
      }
      else if(line[month]['P'] > line[month]['R'] && porcentagemDeDesvio > 3){
        return 'color-red'
      }
      else {
        return 'color-green'
      }
  }


  deviation_calc(planned, realized) {


    if (!planned) {
      planned = 0
    }

    if (!realized) {
      realized = 0
    }


    if (planned > 0) {
      return Math.abs(planned - realized)
    }

    if (planned <= 0) {
      return Math.abs(realized - planned)
    }

    return 0
  }
}
