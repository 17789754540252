import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/interceptors/auth-interceptor';
import { AuthEffects } from './auth/auth.effects';
import { HomeModule } from './home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { NgZorroAntdModule, NZ_I18N, pt_BR } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { SentryErrorHandler } from './shared/services/error.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AdministrativoModule } from './administrativo/administrativo.module';
import { AdministrativoRoutingModule } from './administrativo/administrativo-routing.module';
import { CadastroModule } from './cadastro/cadastro.module';
import { SolicitacaoModule } from './solicitacao/solicitacao.module';
import { OrcamentoModule } from './orcamento/orcamento.module'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PendenciaModule } from './pendencia/pendencia.module';
import { NoCacheHeadersInterceptor } from './auth/interceptors/no-cache-headers.interceptor';

registerLocaleData(pt);
const maskConfigFunction: Partial<IConfig> = {
  thousandSeparator: '.',
  decimalMarker: ',',
};

const currencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    SharedModule,
    HomeModule,
    AdministrativoModule,
    CadastroModule,
    SolicitacaoModule,
    OrcamentoModule,
    PendenciaModule,
    AdministrativoRoutingModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxCurrencyModule.forRoot(currencyMaskConfig),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([ AuthEffects ]),
    NgZorroAntdModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
