import { Injectable } from '@angular/core';
import { UrlSerializer, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private serializer: UrlSerializer, private router: Router, private http: HttpClient) { }

  getQueryString(params) {
    const tree = this.router.createUrlTree([''], { queryParams: params });
    return this.serializer.serialize(tree).slice(1);
  }

  encrypt(data: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'zhTwn8fzMLHw97Qj').toString();
  }

  decrypt(key: string) {
    if (key) {
      const resp = CryptoJS.AES.decrypt(key, 'zhTwn8fzMLHw97Qj');
      return JSON.parse(resp.toString(CryptoJS.enc.Utf8));
    }
    return '';
  }

  Requisitions() {
    return this.http.get(environment.baseApiUrl + 'requisition/my-requisitions-per-status');
  }

  Pendencies() {
    return this.http.get(environment.baseApiUrl + 'requisition/my-pendencies-per-status');
  }


  OneRequisition(id) {
    return this.http.get(environment.baseApiUrl + 'requisition/' + id);
  }

  GetDocumento(id, tipo) {
    if (tipo == 'cpf-frente') {
      return this.http.get(environment.baseApiUrl + 'contract/download-cpf-frente/' + id)
    }

    if (tipo == 'cpf-verso') {
      return this.http.get(environment.baseApiUrl + 'contract/download-cpf-verso/' + id)
    }

    if (tipo == 'rg-frente') {
      return this.http.get(environment.baseApiUrl + 'contract/download-rg-frente/' + id)
    }

    if (tipo == 'rg-verso') {
      return this.http.get(environment.baseApiUrl + 'contract/download-rg-verso/' + id)
    }

    if (tipo == 'comprovante-residencia') {
      return this.http.get(environment.baseApiUrl + 'contract/download-comprovante-residencia/' + id)
    }

    if (tipo == 'documento-area') {
      return this.http.get(environment.baseApiUrl + 'contract/download-documento-area/' + id)
    }

    if (tipo == 'ultima-declaracao-area') {
      return this.http.get(environment.baseApiUrl + 'contract/download-ultima-declaracao-area/' + id)
    }

    if (tipo == 'ultima-emissao-area') {
      return this.http.get(environment.baseApiUrl + 'contract/download-ultima-emissao-area/' + id)
    }

    if (tipo == 'certidao-obito') {
      return this.http.get(environment.baseApiUrl + 'contract/download-certidao-obito/' + id)
    }
  }

  VerificaContratoPorCPF(cpf:string) {
    return this.http.get(environment.baseApiUrl + 'contract-person/cpf/' + cpf);
  }

}
