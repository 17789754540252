import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { id_ID } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class ReplanningRequisitionService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  ReplanningAprove(id, actual_step, data) {
      return this.http.post(environment.baseApiUrl + '/replanning-requisition/approve/'+id+'?step='+actual_step, data)
  }

  getReplanningRequisition(id) {
      return this.http.get(environment.baseApiUrl + 'replanning-requisition/'+id);
  }

  DownloadReplanningArchive(filename, data) {
    return this.http.post(environment.baseApiUrl + 'replanning-requisition/download/'+filename, data, { responseType: 'blob' })
  }
}