import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import Permissions from '../../../../app/shared/enums/permissions.enum';
import { PlanningPeriodService } from '../../services/planning-period.service';
import { RealizedBudgetService } from '../../services/realized-budget.service';
import { selectAuthUser } from 'src/app/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AuthState } from 'src/app/auth/auth.reducer';
import { PermissionService } from '../../../shared/services/permission.service';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-listagem-de-custos-hierarquia',
  templateUrl: './listagem-de-custos-hierarquia.component.html',
  styleUrls: ['./listagem-de-custos-hierarquia.component.scss']
})
export class ListagemDeCustosHierarquiaComponent implements OnInit, OnChanges {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @Input() list;
  @Input() tab;
  @Input() users = [];
  @Input() page;
  @Input() synchronizedYearFilter;
  @Output() year = new EventEmitter();
  showUser$ = this.state.pipe(select(selectAuthUser));
  permissionsForPlanning= [
    Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO
  ];
  loggedUserId: number;
  showPlanningButton = false;
  requisitionId
  formData
  fileId
  attachment: any[];
  runningYear

  showJustificationInInvoiceControl = false;
  showInInvoiceControl = false
  listInvoices = []

  expandSet = new Set<string>();
  expandUser = new Set<string>();
  filterMonthsSet = new Set<string>();
  filterLinesSet = new Set<string>();
  yearsSet = new Set<string>();
  panels = [
    { active: true, name: 'Periodo', id: 1 },
    { active: false, name: 'Exibição', id: 2 },
    { active: false, name: 'Ano', id: 3 }
  ];

  monthspt = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

  months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

  lines = [
  { type: 'P', desc: 'Previsto' },
  { type: 'R', desc: 'Realizado' },
  { type: 'D', desc: 'Desvio Previsto x Realizado' },
  { type: 'A', desc: 'Assertividade' },
  { type: 'PR', desc: 'Projetado' },
  { type: 'DPR', desc: 'Desvio Projetado x Realizado' },
  { type: 'DPP', desc: 'Desvio Previsto x Projetado' },
  ]
  years = []

  active = false;
  atualMonth = parseInt(moment(new Date(), 'YYYY/MM/DD').format('M'));
  atualYear = parseInt(moment(new Date(), 'YYYY/MM/DD').format('Y'));
  previousMonth = this.atualMonth - 1;
  nextMonth = this.atualMonth + 1;
  group_nofs = []

  constructor(
    private planningPeriodService: PlanningPeriodService,
    private notification: NzNotificationService,
    private realizedBudgetService: RealizedBudgetService,
    private permissionService: PermissionService,
    private state: Store<AuthState>) { }

  ngOnInit(): void {

    this.planningPeriodService.GetPlaningPeriods().subscribe((response: any) => {
      var atual_year = moment().format('YYYY');
      var lastYear = "0"
      response.data.forEach(item => {
        if(item.status_id != 1 && item.year <= atual_year) {
          this.years.push(item.year)
          if (parseInt(item.year) > parseInt(lastYear)) {
            lastYear = item.year
          }
        }
      });
      this.yearsSet.add(lastYear)
    })

    this.showUser$.subscribe((user: any) => {
      this.loggedUserId = user.id;
    });

    this.runningYear = moment().format('YYYY')
    this.yearsSet.add(this.runningYear);



    this.monthspt.forEach((month, index) => {
      if (this.previousMonth == index + 1 || this.atualMonth == index + 1 || this.nextMonth == index + 1) {
        this.filterMonthsSet.add(month);
      }
    })

    this.lines.forEach(line => {
      if (line.type != 'DPR' && line.type != 'DPP'){
        this.filterLinesSet.add(line.type);
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.synchronizedYearFilter) {
      this.yearsSet = new Set<string>();
      this.yearsSet.add(changes.synchronizedYearFilter.currentValue);
    }
  }

  public get Permissions(): typeof Permissions {
    return Permissions;
  }

  getWidthOfTable() {
    return (this.filterMonthsSet.size * 115 + 430) + 'px';
  }


  onExpandUser(id: string, checked: boolean): void {
    if (!this.expandUser.has(id)) {
      this.expandUser.add(id);
    } else {
      this.expandUser.delete(id);
    }
  }



  onExpandChange(id: string, checked: boolean): void {
    if (!this.expandSet.has(id)) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  filterMonths(month: string, checked: boolean): void {
    if (!this.filterMonthsSet.has(month)) {
      this.filterMonthsSet.add(month);
    } else {
      this.filterMonthsSet.delete(month);
    }
  }

  filterLines(line: string): void {
    if (!this.filterLinesSet.has(line)) {
      this.filterLinesSet.add(line);
    } else {
      this.filterLinesSet.delete(line);
    }
  }

  filterYears(year: string): void {
    this.yearsSet = new Set<string>();
    this.yearsSet.add(year);
    this.year.emit(year)
  }




  DownloadReport() {
    var title = 'contabil'
    if (this.tab == 1) {
      title = 'financeiro'
    }
    this.realizedBudgetService.Download(this.synchronizedYearFilter, title).subscribe( (response: any) => {
      const csvName = 'Relatorio.csv';
      const blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(blob, csvName);
    });
  }


  formatFloatNumber(value) {
    if (value == 0)
      return "-";
    let valor = Math.trunc(value);
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 0});
    return formatter.format(valor);
  }

  formatFloatAssertivenessNumber(value) {
    var formatter = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 3});
    return formatter.format(value);
  }


  getValue(item , month, line) {
    var response = this.formatFloatNumber(item[line][month]);

    if (line == 'A') {
      return this.formatFloatAssertivenessNumber(item[line][month]) + '%';
    }

    if (line == 'D') {
      return this.formatFloatNumber(this.deviation_calc(item['P'][month], item['R'][month]));
    }

    response = this.formatFloatNumber(item[line][month]);
    return response ? response : 0

  }

  deviationColor(line, type, month){
      var porcentagemDeDesvio = Math.abs((line['R'][month] / line['P'][month]) - 100)
      if(line['P'][month] > line['R'][month] && porcentagemDeDesvio < 3){
        return 'color-yellow'
      }
      else if(line['P'][month] > line['R'][month] && porcentagemDeDesvio > 3){
        return 'color-red'
      }
      else {
        return 'color-green'
      }
  }


  deviation_calc(planned, realized) {


    if (!planned) {
      planned = 0
    }

    if (!realized) {
      realized = 0
    }


    if (planned > 0) {
      return Math.abs(planned - realized)
    }

    if (planned <= 0) {
      return Math.abs(realized - planned)
    }

    return 0
  }
}
