import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HistoricoSolicitacaoService } from '../../services/historico-solicitacao.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-historico-aprovacao',
  templateUrl: './historico-aprovacao.component.html',
  styleUrls: ['./historico-aprovacao.component.scss']
})
export class HistoricoAprovacaoComponent implements OnInit, OnChanges {

  isVisibleMiddle: boolean = true;

  @Input() valor: boolean = false;
  @Input() id: any;
  @Input() historic;
  listHistoric: any;
  statusResponse: any;
  department: any;
  idStatus: any;

  ngOnChanges() {
    if (this.valor === false) {
      this.valor = !this.valor;
    }

    if (this.historic) {
      this.service.GetHistoric(this.historic).subscribe((response: any) => {
        this.listHistoric = response.data;
      });

      this.serviceUtils.OneRequisition(this.historic).subscribe((response: any) => {
        this.statusResponse = response.data.status ? response.data.status.description :  response.data.state.description;
        this.department = response.data.current_department;
        this.idStatus = response.data.status ? response.data.status.id : response.data.state.id;
      });
    }
  }

  constructor(private service: HistoricoSolicitacaoService, private serviceUtils: UtilsService) { }

  ngOnInit(): void {
    if (this.historic) {
      this.service.GetHistoric(this.historic).subscribe((response: any) => {
        this.listHistoric = response.data;
      });
    }
  }

  handleCancelMiddle(): void {
    this.valor = !this.valor;
  }
}
