import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MinhaSolicitacaoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  GetMyRequisition(params, filters) {
    return this.http.get(environment.baseApiUrl + 'requisition/my-requisitions' + this.utils.getQueryString({ ...params, ...filters }));
  }

  FileUpload(data) {
    return this.http.post(environment.baseApiUrl + 'file/upload', data)
  }

  downloadFile(name, uuid) {
    return this.http.get(environment.baseApiUrl + 'file/download/'+name+'?uuid='+uuid, { responseType: 'blob' });
  }

  getPerson(id) {
    return this.http.get(environment.baseApiUrl + 'contract-person/'+id);
  }
}
