import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  GetAllRequisition(params, filters) {
    return this.http.get(environment.baseApiUrl + 'requisition/' + this.utils.getQueryString({ ...params, ...filters }));
  }

  GetTotalRequisition() {
    return this.http.get(environment.baseApiUrl + 'requisition/total-per-status');
  }

  GetAreasAvaliable() {
    return this.http.get(environment.baseApiUrl + 'area/total_available');
  }

  GetReport() {
    return this.http.get(environment.baseApiUrl + 'requisition/report', { responseType: 'blob' });
  }
}
