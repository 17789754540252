import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from '../shared/pages/master-page/master-page.component';
import { ControleDeNotasComponent } from './pages/controle-de-notas/controle-de-notas.component';
import { GestaoDeOrcamentoComponent } from './pages/gestao-de-orcamento/gestao-de-orcamento.component';
import { PlanejamentoAnualComponent } from './pages/planejamento-anual/planejamento-anual.component'
import { OrcamentoCorrenteComponent } from './pages/orcamento-corrente/orcamento-corrente.component'
import { OrcamentoCorrenteHierarquiaComponent } from './pages/orcamento-corrente-hierarquia/orcamento-corrente-hierarquia.component'
import { PermissionGuard } from '../shared/guards/permission.guard';
import Permissions from '../shared/enums/permissions.enum';
import { from } from 'rxjs';
import { AnyPermissionsGuard } from '../shared/guards/any-permissions.guard';

const routes: Routes = [
  {
    path: 'orcamento',
    component: MasterPageComponent,
    children: [
      {
        path: 'controle-de-notas',
        component: ControleDeNotasComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Controle de Notas',
          permissions: [
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO,
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO,
            Permissions.ORCAMENTO_CONTROLE_ORCAMENTARIO
          ]
        }
      },
      {
        path: 'gestao-de-orcamento',
        component: GestaoDeOrcamentoComponent,
        canActivate: [PermissionGuard],
        data: {
          customBreadcrumb: 'Gestão de Orçamentos',
          permissions: [Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO]
        }
      },
      {
        path: 'planejamento-anual',
        component: PlanejamentoAnualComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Planejamento Anual',
          permissions: [
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO,
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO,
            Permissions.ORCAMENTO_CONTROLE_ORCAMENTARIO
          ]
        }
      },
      {
        path: 'orcamento-corrente-hierarquia',
        component: OrcamentoCorrenteHierarquiaComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Orçamento Time',
          permissions: [
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO,
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO,
            Permissions.ORCAMENTO_CONTROLE_ORCAMENTARIO
          ]
        }
      },
      {
        path: 'orcamento-corrente',
        component: OrcamentoCorrenteComponent,
        canActivate: [AnyPermissionsGuard],
        data: {
          customBreadcrumb: 'Orçamento Corrente',
          permissions: [
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO,
            Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO,
            Permissions.ORCAMENTO_CONTROLE_ORCAMENTARIO
          ]
        }
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrcamentoRoutingModule { }
