import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovoContratoComponent } from './pages/novo-contrato/novo-contrato.component';
import { SolicitacaoRoutingModule } from './solicitacao-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NzNotificationModule, NzButtonModule, NzInputModule, NzTableModule, NzIconModule, NzFormModule, NzToolTipModule, NzRadioModule, NzSwitchModule, NzPopoverModule, NzSelectModule, NzModalModule, NzUploadModule, NzListModule, NzTabsModule, NzPaginationModule, NzResultModule, NzDatePickerModule } from 'ng-zorro-antd';
import { MinhaSolicitacaoComponent } from './pages/minha-solicitacao/minha-solicitacao.component';
import { HistoricoAprovacaoMinhaSolicitacaoComponent } from './components/historico-aprovacao-minha-solicitacao/historico-aprovacao-minha-solicitacao.component';
import { AditivoContratualComponent } from './pages/aditivo-contratual/aditivo-contratual.component';
import { AlteracaoDadosBancariosComponent } from './pages/alteracao-dados-bancarios/alteracao-dados-bancarios.component';
import { ResilicaoComponent } from './pages/resilicao/resilicao.component';
import { ReplanningComponent } from './pages/replanning/replanning.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import pt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(pt);
const maskConfigFunction: Partial<IConfig> = {
  thousandSeparator: '.',
  decimalMarker: ',',
};

const currencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [NovoContratoComponent, MinhaSolicitacaoComponent, HistoricoAprovacaoMinhaSolicitacaoComponent, AditivoContratualComponent, AlteracaoDadosBancariosComponent, ResilicaoComponent, ReplanningComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzNotificationModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    NzIconModule,
    NzFormModule,
    NzRadioModule,
    NzSwitchModule,
    NzToolTipModule,
    NzSelectModule,
    NzPopoverModule,
    NzModalModule,
    NzPaginationModule,
    NzUploadModule,
    NzListModule,
    NzTabsModule,
    NzResultModule,
    NzDatePickerModule,
    SolicitacaoRoutingModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxCurrencyModule.forRoot(currencyMaskConfig),
  ]
})
export class SolicitacaoModule { }
