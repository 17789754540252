import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PessoasService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  GetPerson(params, id?) {
    return this.http.get(environment.baseApiUrl + 'contract-person/' + this.utils.getQueryString({ ...params, ...id }));
  }

  GetOnlyPerson(id) {
    return this.http.get(environment.baseApiUrl + 'contract-person/' + id);
  }

  PutPerson(id, data) {
    return this.http.put(environment.baseApiUrl + 'contract-person/' + id, data);
  }

  PostPerson(data) {
    return this.http.post(environment.baseApiUrl + 'contract-person/', data);
  }

  DeletePerson(id) {
    return this.http.delete(environment.baseApiUrl + 'contract-person/' + id);
  }

  Download() {
    return this.http.get(environment.baseApiUrl + 'contract-person/report', { responseType: 'blob' })
  }
}
