import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { RealizedBudgetHierarchyService } from '../../services/realized-budget-hierarchy.service';

@Component({
  selector: 'app-orcamento-corrente-hierarquia',
  templateUrl: './orcamento-corrente-hierarquia.component.html',
  styleUrls: ['./orcamento-corrente-hierarquia.component.scss']
})
export class OrcamentoCorrenteHierarquiaComponent implements OnInit {
  items = [ {id:1, text: 'Contabil'},
   {id:2,text: 'Financeiro'}
  ]

  atualYear = parseInt(moment(new Date(), 'YYYY/MM/DD').format('Y'));
  atualMonth = parseInt(moment(new Date(), 'YYYY/MM/DD').format('M'));

  users = []

  synchronizedYearFilter

  //não mudar a ordem
  months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  //não mudar a ordem
  monthspt = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  //não mudar a ordem
  cols_contabil = ['planned', 'planned_realized_deviation', 'planned_replanning_deviation', 'replanning_realized_deviation', 'replanning', 'assertiveness', 'realized']
  //não mudar a ordem
  cols_financial = ['financial_planned', 'planned_realized_financial_deviation', 'planned_replanning_financial_deviation', 'replanning_realized_financial_deviation', 'financial_replanning', 'assertiveness_financial', 'financial_realized']
  //cols = []
  //não mudar a ordem
  siglas = ['P', 'D', 'DPP', 'DPR', 'PR', 'A', 'R']

  itemIndex = 0
  list = {'contabil': [], 'financeiro': []}

  constructor(
    private realizedBudgetHierarchyService: RealizedBudgetHierarchyService
  ) { }

  ngOnInit(): void {
    this.getRealizedBudgetHierarchy(this.atualYear)
  }

  getRealizedBudgetHierarchy(year) {
    this.realizedBudgetHierarchyService.getRealizedBudgetHierarchy(year).subscribe( (response: any) => {
      this.list = {'contabil': [], 'financeiro': []}
      response.data.forEach(hierarchy => {
        Object.keys(hierarchy).forEach(key => {
          if (hierarchy[key] == null) {
            hierarchy[key] = 0;
          }
        } );
      });

      this.mapeiaValores(response.data);

      this.synchronizedYearFilter = year;

    });


  }

  comparaID(id1, id2) {
    var strId2 = (id2 - 1000000000000).toString();
    var strId1 = id1.toString();

    if (id1 < 1000000000000) {
      strId2 = "0" + strId2;
      strId1 = "0" + strId1;
    }

    var grupos = parseInt(strId1.substring(0,1)) + 1;



    if (strId1.substring(0, 1 + (3 * grupos) )  == strId2.substring(0, 1 + (3 * grupos) ) ) {
      return true;
    }
    return false;
  }

  mapeiaValores(data) {
    Object.keys(this.list).forEach( aba => {
      data.forEach(hierarchy => {
        var cols = [];
        var col_assertiveness = ""
        var col_replanning = ""
        var col_realized = ""

        if (aba == 'financeiro') {
          cols = this.cols_financial
        } else {
          cols = this.cols_contabil
        }

        if ( !(("L" + hierarchy.level_id) in this.list[aba]) ) {
            this.list[aba]["L" + hierarchy.level_id] = [];
        }


        var item = [];
        cols.forEach( (coluna,c) => {
          var chave = this.siglas[c];
          item[chave] = [];
          item[chave]['ACMes'] = 0;
          item[chave]['ACAno'] = 0;
          this.months.forEach(month => {
            item[chave][month] = hierarchy[coluna + '_' + month];
          });
        });

        /*
        item[col_assertiveness] = [];
        item[col_assertiveness]['ACMes'] = 0;
        item[col_assertiveness]['ACAno'] = 0;
        */

        this.months.forEach((month, m) => {
          item[this.siglas[5]][month] = this.assertiveness_calc(item[this.siglas[4]][month],  item[this.siglas[6]][month]);
        })

        this.siglas.forEach( col => {
          this.months.forEach( (month,m) => {
            if (m <= this.atualMonth - 2) {
              item[col]['ACMes'] += item[col][month];
            }

            item[col]['ACAno'] += item[col][month];
          });
        });

        item["username"] = hierarchy.user_name_owner;
        item["user_id_owner"] = hierarchy.user_id_owner;
        item["id"] = hierarchy.id;
        item["id_reportto"] = hierarchy.user_id_report_to;
        item["L" + (hierarchy.level_id+1)] = [];

        if (hierarchy.level_id == 0) {
          //item["id"] = item["id"];
          this.list[aba]["L0"].push(item);

        } else  if (hierarchy.level_id == 1) {
          this.list[aba]["L0"].forEach( level0 => {
            if (this.comparaID(level0["id"], item["id"])) {
              item["id_reportto"] = level0["id"];
              level0["L1"].push(item);
            }
          });
        } else  if (hierarchy.level_id == 2) {
          this.list[aba]["L0"].forEach( level0 => {
            level0["L1"].forEach( level1 => {
              if (this.comparaID(level1["id"], item["id"])) {
                item["id_reportto"] = level1["id"];
                level1["L2"].push(item);
              }
            });
          });
        } else  if (hierarchy.level_id == 3) {
          this.list[aba]["L0"].forEach( level0 => {
            level0["L1"].forEach( level1 => {
              level1["L2"].forEach( level2 => {
                if (this.comparaID(level2["id"], item["id"])) {
                  item["id_reportto"] = level2["id"];
                  level2["L3"].push(item);
                }
              });
            });
          });
        }

      });
    });

  }



  assertiveness_calc(replanning, realized){
    var abs_realized = Math.abs(realized)
    var abs_replanning = Math.abs(replanning)

    if (!replanning) {
      replanning = 0;
    }

    if (!realized) {
      realized = 0;
    }

    if (replanning > 0 && realized < 0) {
      return 0;
    }

    if (replanning < 0 && realized > 0) {
      return 0;
    }

    if (abs_replanning < abs_realized ) {
      return ( abs_replanning / abs_realized) * 100  ;
    }

    if (abs_replanning > abs_realized ) {
      return (abs_realized / abs_replanning) * 100;
    }

    if (abs_replanning == abs_realized && abs_replanning != 0) {
      return 100;
    }
    else {
      return 0;
    }
  }


  getList(index: number) {
    if(index === 0) {
      return this.list['contabil'];

    } else if (index === 1) {
      return this.list['financeiro'];

    }
  }

}
