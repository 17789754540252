enum Permissions {
    SOLICITACOES_CRIAR_SOLICITACOES = 1,
    CADASTROS_AREAS_APROVACAO = 2,
    CADASTROS_AREAS = 3,
    CADASTROS_AREAS_VISUALIZACAO = 4,
    CADASTROS_PESSOAS = 5,
    CADASTROS_PESSOAS_VISUALIZACAO = 6,
    CADASTROS_CONTRATO_VISUALIZACAO = 7,
    CADASTROS_CONTRATO = 8,
    ADMIM_USUARIOS_DO_SISTEMA = 9,
    ADMIM_SOLICITACOES_VISUALIZACAO = 10,
    APROVACAO_ENGENHARIA_CONTRATO = 11,
    APROVACAO_ENGENHARIA_RESILICAO = 12,
    APROVACAO_DIRETORIA_COMERCIAL_CONTRATO = 13,
    APROVACAO_JURIDICO_CONTRATO = 14,
    APROVACAO_JURIDICO_ADTIVO_CONTRATUAL = 15,
    APROVACAO_JURIDICO_DADOS_BANCARIOS = 16,
    APROVACAO_JURIDICO_RESILICAO = 17,
    CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO = 18,
    CONTROLADORIA_GESTAO_ORCAMENTO_VISUALIZACAO = 19,
    ORCAMENTO_CONTROLE_ORCAMENTARIO = 20,
    CADASTROS_PROJETOS = 21,
    CADASTROS_CONTRATO_CARGA = 23,
}

export default Permissions;
