import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReplanningSolcitationService {

  constructor(private http: HttpClient, private utils: UtilsService) { }

  ValidateArchive(data) {
      return this.http.post(environment.baseApiUrl + 'replanning-requisition/validate',data);
  }

  createReplanningRequisition(data){
      return this.http.post(environment.baseApiUrl + 'replanning-requisition/', data);
  }

  uploadReplanningArchive(requisitionId, data){
      return this.http.post(environment.baseApiUrl + 'replanning-requisition/upload/'+requisitionId, data);
  }

  DownloadReplanningArchive(year, tab) {
      return this.http.get(environment.baseApiUrl + 'replanning-requisition/report/'+year+'/'+tab,  { responseType: 'blob' })
  }

  updateReplanningRequisition(id, data) {
    return this.http.put(environment.baseApiUrl + 'replanning-requisition/'+id, data)
}
}
