import { Component, OnInit } from '@angular/core';
import { PendenciaService } from '../../services/pendencia.service';
import { ComboService } from 'src/app/shared/services/combo.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { RequisitionType } from 'src/app/shared/enums/requisition-type.enum';
@Component({
  selector: 'app-listagem-pendencia',
  templateUrl: './listagem-pendencia.component.html',
  styleUrls: ['./listagem-pendencia.component.scss']
})
export class ListagemPendenciaComponent implements OnInit {
  type;
  status;
  situation;
  displayPopover = true;

  dataRow: any;
  valor: boolean;
  history: any;
  filter: string;
  totalItens: any;
  page: number = 1;

  listPendences: any;
  listSituation: any;
  listTypeRequisition: any;
  listStatus: any;

  contract: any;
  number: any;

  constructor(private service: PendenciaService,
              private serviceShared: ComboService,
              private router: Router,
              private notification: NzNotificationService) { }


  ngOnInit(): void {
    let filter = this.GetFilter();
    this.service.GetPendencies(1, filter).subscribe((response: any) => {
      this.listPendences = response.data;
      this.totalItens = response.total;
    });

    this.serviceShared.ComboTypeRequisition().subscribe((response: any) => {
      this.listTypeRequisition = response.data;
    });

    this.serviceShared.ComboRequisitionSituation().subscribe((response: any) => {
      this.listSituation = response.data;
    });

    this.serviceShared.ComboRequisitionState().subscribe((response: any) => {
      this.listStatus = response.data;
    });
  }

  SearchPendencies() {
    const page = this.GetPage();
    const filter = this.GetFilter();

    this.service.GetPendencies(page, filter).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listPendences = response.data;
        this.totalItens = response.total;
      } else {
        this.notification.blank('Busca não encontrada', '');
      }
    });
  }

  getDataRow(data) {
    this.dataRow = data;
  }

  GetPage() {
    return {
      page: this.page
    };
  }

  GetFilter() {
    return {
      number: this.number,
      contract: this.contract,
      type: this.type,
      status: 1,
      situation: this.situation
    };
  }

  chooseDisplayPopover() {
    this.displayPopover = false;
    setTimeout(() => {
      this.displayPopover = true;
    });
  }

  GetHistoric()
  {
    this.valor = !this.valor;
    this.history = this.dataRow.id;
    this.chooseDisplayPopover();
  }


  RequisitionDetail() {
    this.chooseDisplayPopover();
    if (this.dataRow.type.id === RequisitionType.REPLANEJAMENTO) {
      this.router.navigate(['/solicitacao/replanning/' + this.dataRow.id]);
    } else {
      this.router.navigate(['/solicitacao/aprovar-contrato/' + this.dataRow.id]);
    }
  }

  setPage(page) {
    this.page = page
    this.SearchPendencies();
  }

  getPasso(data) {
    if (data.type.id === RequisitionType.REPLANEJAMENTO) {
      return data.actual_step+"/"+data.type.total_step
    } else {
      if (data.actual_step == 1) return "Comercial: Aprovação do líder";
      if (data.actual_step == 2) return "Jurídico: Elaboração do contrato e validação de documentos";
      if (data.actual_step == 3) return "Fundiário: Resgate das assinaturas dos proprietários";
      if (data.actual_step == 4) return "Jurídico: Aprovação final";
      if (data.actual_step == 5) return "Jurídico - Arquivamento";
      if (data.actual_step == 6) return "Finalizado";

    }
  }
}
