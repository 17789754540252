import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AreasService } from 'src/app/cadastro/services/areas.service.js';
import { environment } from 'src/environments/environment';
declare var geoXML3: any;

@Component({
  selector: 'app-area-maps',
  templateUrl: './area-maps.component.html',
  styleUrls: ['./area-maps.component.scss']
})
export class AreaMapsComponent implements OnInit, OnChanges {

  @Input() file: any;
  @Input() codeArea: any;
  @Input() filterCodeArea: any;
  @Input() project_id: any;
  //@Output() areas = new EventEmitter();
  map: any;
  geoXml: any;
  geoXmlDoc: any;
  fileMaps: any;
  cont: number;
  token: string;
  areas_names = []

  @Input() codeAreas = []

  constructor(private AreaService: AreasService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.codeArea) {
      this.applyFilter();
    }

    if (changes.filterCodeArea) {
      this.applyFilter();
    }

    if (changes.codeAreas) {
      this.applyFilter();
    }
  }

  initialize(map): void {


    this.map = map;

    this.geoXml = new geoXML3.parser({
      map,
      singleInfoWindow: true,
      afterParse: (doc) => {
        this.geoXmlDoc = doc[0];
        this.applyFilter();
      }
    });
    this.token = localStorage.getItem("auth.token");

    this.geoXml.parse(environment.baseApiUrl + 'project/geofile/download/'+ this.project_id + '?token=' + this.token);''
  }

  applyFilter(): void {

    if (this.geoXmlDoc) {
      if (this.codeAreas.length == 0) {
        this.geoXml.docs[0].gpolygons.forEach((item) => {
          this.areas_names.push(item.title)
          let find = this.codeArea != undefined ? this.codeArea : this.filterCodeArea
          if (item.title.toLowerCase().includes(find.toLowerCase())) {
            item.setMap(this.map);
          } else {
            item.setMap(null);
          }
        });
      } else {
        this.geoXml.docs[0].gpolygons.forEach((item) => {
          this.areas_names.push(item.title)
          if (this.filterCodeArea != "") {
            if (item.title.toLowerCase().includes(this.filterCodeArea.toLowerCase())) {
              item.fillColor="#FF0000";
              item.setMap(this.map);
            } else {
              if (item.title.toLowerCase().includes(this.filterCodeArea.toLowerCase().substring(0,10))) {
                item.fillColor="#AAAAAA";
                item.setMap(this.map);
              } else {
                item.setMap(null);
              }
            }
          } else {
          if (this.findListAreas(item)) {
            item.setMap(this.map);
          } else {
            item.setMap(null);
          }
          }
        });
      }
      /*
      if(this.areas_names.length > 0) {
        this.areas.emit(this.areas_names)
      }
      */
    }
  }

  findListAreas(item) {

    let response = false
    this.codeAreas.forEach( code => {
      if (item.title.toLowerCase().includes(code.toLowerCase())) {
        response = true
      }
    })
    return response
  }
}
