import { Component, OnInit } from '@angular/core';
import { MinhaSolicitacaoService } from '../../services/minha-solicitacao.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { ComboService } from 'src/app/shared/services/combo.service';
import { Router } from '@angular/router';
import { RequisitionType } from 'src/app/shared/enums/requisition-type.enum';

@Component({
  selector: 'app-minha-solicitacao',
  templateUrl: './minha-solicitacao.component.html',
  styleUrls: ['./minha-solicitacao.component.scss']
})
export class MinhaSolicitacaoComponent implements OnInit {
  dataRow: any;
  valor: boolean;
  myRequisition: any;
  displayPopover = true;

  type;
  status;
  number: any;
  totalItens: any;
  page = 1;

  type_clone;
  status_clone;
  number_clone: any;

  listMyRequisition: any;
  listTypeRequisition: any;
  listStatus: any;

  constructor(private service: MinhaSolicitacaoService,
              private serviceShared: ComboService,
              private notification: NzNotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.service.GetMyRequisition(this.page, this.GetFilter).subscribe((response: any) => {
      this.listMyRequisition = response.data;
      this.totalItens = response.total;
    });

    this.serviceShared.ComboTypeRequisition().subscribe((response: any) => {
      this.listTypeRequisition = response.data;
    });

    this.serviceShared.comboContractStatus().subscribe((response: any) => {
      this.listStatus = response.data;
    });
  }

  SearchRequisition() {

    const filter = this.GetFilter();
    const filter_clone = this.GetFilterClone();


    if(filter.status !== filter_clone.status || filter.number !== filter_clone.number || filter.type !== filter_clone.type)
    {
      this.page = 1;
      this.number_clone = this.number;
      this.type_clone = this.type;
      this.status_clone = this.status;
    }

    const page = this.GetPage();


    this.service.GetMyRequisition(page, filter).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listMyRequisition = response.data;
        this.totalItens = response.total;
      } else {
        this.notification.blank('Solicitação não encontrada', '');
        this.listMyRequisition = [];
        this.totalItens = 0;
      }
    });
  }

  GetPage() {
    return {
      page: this.page
    };
  }

  GetFilter() {
    return {
      number: this.number,
      type: this.type,
      status: this.status
    };
  }

  GetFilterClone() {
    return {
      number: this.number_clone,
      type: this.type_clone,
      status: this.status_clone
    };
  }

  getDataRow(data) {
    this.dataRow = data;
  }

  chooseDisplayPopover() {
    this.displayPopover = false;
    setTimeout(() => {
      this.displayPopover = true;
    });
  }

  GetHistoric() {
    this.valor = !this.valor;
    this.myRequisition = this.dataRow.id;
    this.chooseDisplayPopover();
  }

  RequisitionDetail() {
    this.chooseDisplayPopover();
    if (this.dataRow.type.id === RequisitionType.NOVO_CONTRATO) {
      this.router.navigate(['/solicitacao/novo-contrato/' + this.dataRow.id]);
    }
    if (this.dataRow.type.id === RequisitionType.ADITIVO_CONTRATUAL) {
      this.router.navigate(['/solicitacao/aditivo-contratual/' + this.dataRow.id]);
    }
    if (this.dataRow.type.id === RequisitionType.ALTERACAO_DE_DADOS_BANCARIOS) {
      this.router.navigate(['/solicitacao/alteracao-dados-bancarios/' + this.dataRow.id]);
    }
    if (this.dataRow.type.id === RequisitionType.RESILICAO) {
      this.router.navigate(['/solicitacao/resilicao/' + this.dataRow.id]);
    }
    if (this.dataRow.type.id === RequisitionType.REPLANEJAMENTO) {
      this.router.navigate(['/solicitacao/replanning/' + this.dataRow.id]);
    }
  }

  setPage(page) {
    this.page = page;
    this.SearchRequisition();
  }

}
