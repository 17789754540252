import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from '../shared/pages/master-page/master-page.component';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { NovoContratoComponent } from './pages/novo-contrato/novo-contrato.component';
import { MinhaSolicitacaoComponent } from './pages/minha-solicitacao/minha-solicitacao.component';
import { AditivoContratualComponent } from './pages/aditivo-contratual/aditivo-contratual.component';
import { AlteracaoDadosBancariosComponent } from './pages/alteracao-dados-bancarios/alteracao-dados-bancarios.component';
import { ResilicaoComponent } from './pages/resilicao/resilicao.component';
import { ReplanningComponent } from './pages/replanning/replanning.component';
import { AnyPermissionsGuard } from '../shared/guards/any-permissions.guard';
import Permissions from '../shared/enums/permissions.enum';

const routes: Routes = [
    {
        path: 'solicitacao',
        component: MasterPageComponent,
        children: [
            {
                path: 'novo-contrato',
                component: NovoContratoComponent,
                canActivate: [PermissionGuard],
                data: {
                    customBreadcrumb: 'Novo Contrato',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES, 
                        Permissions.CADASTROS_CONTRATO_CARGA
                    ]
                }
            },
            {
                path: 'novo-contrato/:id',
                component: NovoContratoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Novo Contrato',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.CADASTROS_CONTRATO_CARGA
                    ]
                }
            },
            {
                path: 'aprovar-contrato/:id',
                component: NovoContratoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Aprovar Contrato',
                    permissions: [
                        Permissions.APROVACAO_JURIDICO_CONTRATO,
                        Permissions.APROVACAO_ENGENHARIA_CONTRATO,
                        Permissions.APROVACAO_DIRETORIA_COMERCIAL_CONTRATO,
                        Permissions.APROVACAO_JURIDICO_ADTIVO_CONTRATUAL,
                        Permissions.APROVACAO_JURIDICO_RESILICAO,
                        Permissions.APROVACAO_JURIDICO_DADOS_BANCARIOS,
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES
                    ]
                }
            },
            {
                path: 'novo-contrato/view/:id',
                component: NovoContratoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Visualizar Contrato',
                    permissions: [
                        Permissions.ADMIM_SOLICITACOES_VISUALIZACAO,
                        Permissions.CADASTROS_CONTRATO_VISUALIZACAO,
                        Permissions.CADASTROS_CONTRATO_CARGA,
                    ]
                }
            },
            {
                path: 'novo-contrato/view-contract/:id',
                component: NovoContratoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Visualizar Contrato',
                    permissions: [
                        Permissions.ADMIM_SOLICITACOES_VISUALIZACAO,
                        Permissions.CADASTROS_CONTRATO_VISUALIZACAO,
                        Permissions.CADASTROS_CONTRATO_CARGA
                    ]
                }
            },
            {
                path: 'minhas-solicitacoes',
                component: MinhaSolicitacaoComponent,
                canActivate: [PermissionGuard],
                data: {
                    customBreadcrumb: 'Minhas Solicitações',
                    permissions: [Permissions.SOLICITACOES_CRIAR_SOLICITACOES]
                }
            },
            {
                path: 'aditivo-contratual',
                component: AditivoContratualComponent,
                canActivate: [PermissionGuard],
                data: {
                    customBreadcrumb: 'Aditivo Contratual',
                    permissions: [Permissions.SOLICITACOES_CRIAR_SOLICITACOES]
                }
            },
            {
                path: 'aditivo-contratual/:id',
                component: AditivoContratualComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Aditivo Contratual',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.APROVACAO_JURIDICO_ADTIVO_CONTRATUAL
                    ]
                }
            },
            {
                path: 'aditivo-contratual/view/:id',
                component: AditivoContratualComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Aditivo Contratual',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.ADMIM_SOLICITACOES_VISUALIZACAO,
                    ]
                }
            },
            {
                path: 'alteracao-dados-bancarios',
                component: AlteracaoDadosBancariosComponent,
                canActivate: [PermissionGuard],
                data: {
                    customBreadcrumb: 'Alteração Dados Bancarios',
                    permissions: [Permissions.SOLICITACOES_CRIAR_SOLICITACOES]
                }
            },
            {
                path: 'alteracao-dados-bancarios/:id',
                component: AlteracaoDadosBancariosComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Alteração Dados Bancarios',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.APROVACAO_JURIDICO_DADOS_BANCARIOS
                    ]
                }
            },
            {
                path: 'alteracao-dados-bancarios/view/:id',
                component: AlteracaoDadosBancariosComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Alteração Dados Bancarios',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.ADMIM_SOLICITACOES_VISUALIZACAO,
                    ]
                }
            },
            {
                path: 'resilicao',
                component: ResilicaoComponent,
                canActivate: [PermissionGuard],
                data: {
                    customBreadcrumb: 'Resilição',
                    permissions: [Permissions.SOLICITACOES_CRIAR_SOLICITACOES]
                }
            },
            {
                path: 'resilicao/:id',
                component: ResilicaoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Resilição',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.APROVACAO_JURIDICO_RESILICAO
                    ]
                }
            },
            {
                path: 'resilicao/view/:id',
                component: ResilicaoComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Resilição',
                    permissions: [
                        Permissions.SOLICITACOES_CRIAR_SOLICITACOES,
                        Permissions.ADMIM_SOLICITACOES_VISUALIZACAO,
                    ]
                }
            },
            {
                path: 'replanning/:id',
                component: ReplanningComponent,
                canActivate: [AnyPermissionsGuard],
                data: {
                    customBreadcrumb: 'Replanejamento',
                    permissions: [
                        Permissions.CONTROLADORIA_GESTAO_ORCAMENTO_CONFIGURACAO,
                    ]
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class SolicitacaoRoutingModule { }