import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AreasService } from '../../services/areas.service';
import { ComboService } from 'src/app/shared/services/combo.service';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { PreApprovedType } from 'src/app/shared/enums/area.enum';
declare var require: any
const FileSaver = require('file-saver');

// tslint:disable-next-line: no-unused-expression

@Component({
  selector: 'app-listagem-areas',
  templateUrl: './listagem-areas.component.html',
  styleUrls: ['./listagem-areas.component.scss']
})
export class ListagemAreasComponent implements OnInit {
  status;
  status_clone;
  switchValue = false;

  visible: boolean;
  listArea: any;
  testando;
  id: any;
  listAreaCombo: any;
  area: any;
  area_clone: any;
  page = 1;
  totalitens: any;
  blob: Blob;

  statusList = [];
  filter: string;


  constructor(private router: Router,
              private service: AreasService,
              private serviceShared: ComboService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.service.GetArea(this.page).subscribe((response: any) => {
      this.listArea = response.data;
      this.listArea.forEach(area => {
        area['contract_id'] = area.contract_area ? "CONTRATO " + area.contract_area.contract_id : null
      });
      this.totalitens = response.total;
    });

    this.getCombos();
  }

  getCombos() {
    this.statusList = [
      {id: 0, desc: 'Selecione...'},
      {id: 1, desc: 'Pré Aprovado Engenharia'},
      {id: 2, desc: 'Não Aprovado'}
    ];

    this.serviceShared.ComboArea().subscribe((response: any) => {
      this.listAreaCombo = response.data;
    });
  }

  setPage(page) {
    this.page = page;
    this.SearchArea();
  }

  checkPermission(operation) {
    return this.permissionService.checkPermission('area', operation)
  }

  SearchArea() {

    const filter: any = this.GetFilter();
    const filter_clone: any = this.GetFilterClone()

    if(filter.status !== filter_clone.status || filter.area !== filter_clone.area)
    {
      this.page = 1;
      this.area_clone = this.area;
      this.status_clone = this.status;
    }

    const page = this.GetPage();

    if (filter.status !== 0) {
      this.service.GetArea(page, filter).subscribe((response: any) => {
        if (response.data.length > 0) {
          this.listArea = response.data;
          this.listArea.forEach(area => {
            area['contract_id'] = area.contract_area ? "CONTRATO " + area.contract_area.contract_id : null
          });
          this.totalitens = response.total;
        } else {
          this.listArea = [];
          this.totalitens = 0;
          this.page = 1;
          this.notification.blank('Area não encontrada', '');
        }
      });
    } else {
      this.service.GetArea(this.page).subscribe((response: any) => {
        this.listArea = response.data;
        this.totalitens = response.total;
        this.page = response.pages;
      });
    }

  }

  downloadCSV() {
    this.service.Download().subscribe( (response: any) => {
      const csvName = 'Relatorio_areas.csv';
      const blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(blob, csvName);
    });
  }

  GetPage() {
    return {
      page: this.page
    };
  }

  GetFilter() {
    return {
      area: this.area,
      status: this.status
    };
  }

  GetFilterClone() {
    return {
      area: this.area_clone,
      status: this.status_clone
    };
  }

  clearSearch() {
    this.filter = '';
  }

  DownloadArea() {
    this.service.GetReport().subscribe((response: any) => {
      this.blob = new Blob([response], { type: 'application/csv' });

      const downloadURL = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'Relatorio.csv';
      link.click();
    });
  }

  Aprovation(data, index) {
    this.service.PermissionArea(data.id, { status_id: data.status_id }).subscribe((response: any) => {
      this.updateStatusId(index);
    });
  }

  getAreaStatus(area) {
    if (PreApprovedType.Aprovada === area.status_id) return "Pré-Aprovada Engenharia";
    if (PreApprovedType.NaoAprovada === area.status_id) return "Não Aprovada";
    if (PreApprovedType.ParcialmenteAprovada === area.status_id) return "Parcialmente Aprovada";
  }

  updateStatusId(index) {
    this.listArea[index].status_id = PreApprovedType.Aprovada;
  }

  GetId(id) {
    this.id = id;
  }

  View() {
    this.router.navigateByUrl('cadastro/form-cadastro-areas/view/' + this.id);
  }

  Editar(): void {
    this.visible = false;
    this.router.navigateByUrl('cadastro/form-cadastro-areas/' + this.id);
  }

  Deletar() {
    this.modal.confirm({
      nzTitle: 'Deseja excluir esta área?',
      nzOkText: 'Sim',
      nzOkType: 'danger',
      nzOnOk: () => this.service.DeleteArea(this.id).subscribe((response: any) => {
        if (response.success === true) {
          this.notification.success('Área deletada com sucesso', '');
          window.location.reload();
        }
      }),
      nzCancelText: 'Não',
      nzOnCancel: () => console.log('Cancel')
    });
  }
}
