import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HistoricoMinhaSolicitacaoService } from '../../services/historico-minha-solicitacao.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-historico-aprovacao-minha-solicitacao',
  templateUrl: './historico-aprovacao-minha-solicitacao.component.html',
  styleUrls: ['./historico-aprovacao-minha-solicitacao.component.scss']
})
export class HistoricoAprovacaoMinhaSolicitacaoComponent implements OnInit, OnChanges {

  @Input() valor: boolean;
  @Input() myRequisition: any;

  listData = [];
  listHistoric: any;
  statusResponse: any;
  department: any;
  idStatus: any;

  constructor(private service: HistoricoMinhaSolicitacaoService,  private serviceUtils: UtilsService) { }

  ngOnChanges() {
    if(this.valor === false) {
      this.valor = !this.valor;
    }

    if(this.myRequisition) {

      this.service.Historic(this.myRequisition).subscribe((response: any) => {
        this.listHistoric = response.data;
      });

      this.serviceUtils.OneRequisition(this.myRequisition).subscribe((response: any) => {
        this.statusResponse = (response.data.state) ? response.data.state.description : response.data.status.description;
        this.department = response.data.current_department;
        this.idStatus = (response.data.state) ? response.data.state.id : response.data.status.id;
      });
    }
  }

  ngOnInit(): void {
  }

  SetValues() {}

  CloseModal() {
    this.valor = !this.valor;
  }

}
