import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { ContractService } from 'src/app/cadastro/services/contract.service';
import { PessoasService } from 'src/app/cadastro/services/pessoas.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { RegisterPersonComponent } from 'src/app/shared/components/register-person/register-person.component';
import { ComboApportionment } from 'src/app/shared/models/combo.model';
import { ComboService } from 'src/app/shared/services/combo.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ApportionmentType } from '../../enums/contract.enum';
import { AlterationBankdataService } from '../../services/alteration-bankdata.service';
import { AccountType } from 'src/app/shared/enums/person.enum';
import * as moment from 'moment';
import { beneficiaryListValidator } from '../../validators/customvalidator.validator';
import { StatusIntegracao } from 'src/app/shared/enums/status.enum';
moment.locale('pt-br');
const FileSaver = require('file-saver');


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-alteracao-dados-bancarios',
  templateUrl: './alteracao-dados-bancarios.component.html',
  styleUrls: ['./alteracao-dados-bancarios.component.scss']
})
export class AlteracaoDadosBancariosComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @ViewChild('registerPersonComponent', {static: false}) registerPersonComponent: RegisterPersonComponent;

  ApportionmentType = ApportionmentType;
  form: FormGroup;
  fieldNameUpload: any;
  formJuridicalApproval: FormGroup;
  formEngineeringApproval: FormGroup;
  formRequesterApproval: FormGroup;
  formFinalApproval: FormGroup;
  contractData: any = { };
  requisitionData: any = { };
  showPopover = true;
  sumOfPercentages: any = 0;
  TotalApportionmentValue: any;

  formPerson: FormGroup;
  personDetails: any;
  beneficiaryEdit: any = null;

  blockFields = false;
  isVisible = false;

  attachment_file = null;
  attachment_file_2 = null;
  attachment_file_3 = null;
  attachment_file_4 = null;
  engineering_file: any;
  contract_file: any;
  final_contract_file: any;
  property_signature_file: any;
  previousPage = '';
  contractList = [];
  personsList = [];
  personsSelected = [];
  areasList = [];
  bankList = [];
  accountTypeList = [];
  areasDetails = [];
  contract_code
  contract_number

  listApportionmentType: Array<ComboApportionment> = [];

  StatusIntegracao = StatusIntegracao;

  constructor(private comboService: ComboService,
              private service: AlterationBankdataService,
              private contractService: ContractService,
              private personService: PessoasService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private notification: NzNotificationService,
              private alteracaoDadosBancariosService: AlterationBankdataService,
            ) {
    this.form = this.fb.group({
      contract_id: this.fb.control(null, [Validators.required]),
      areas: this.fb.control([], [Validators.nullValidator]).disable(),
      newBeneficiary: this.fb.control([], [Validators.nullValidator]),
      beneficiary_equal_apportionment: this.fb.control(false, [Validators.required]),
      beneficiary_apportionment_type: this.fb.control(1, [Validators.nullValidator]),
      total_value: this.fb.control(0, [Validators.required]),
      contract_beneficiaries: this.fb.array([], [Validators.required, beneficiaryListValidator]),
    });

    this.formEngineeringApproval = this.fb.group({
      approved: this.fb.control(true, [Validators.required]),
      justification: this.fb.control('', [Validators.required]),
    });

    this.formJuridicalApproval = this.fb.group({
      approved: this.fb.control(true, [Validators.required]),
      justification: this.fb.control('', [Validators.required]),
      is_digital_signature: this.fb.control(false, [Validators.required]),
      contract_file_id: this.fb.control(false, [Validators.required]),
    });

    this.formRequesterApproval = this.fb.group({
      approved: this.fb.control('', [Validators.required]),
      justification: this.fb.control('', [Validators.required]),
      signature_date: this.fb.control('', [Validators.required]),
      property_signature_file_id: this.fb.control(null, [Validators.required]),
      date: this.fb.control('', [Validators.required]),
      number_document: this.fb.control('', [Validators.required]),
    });

    this.formFinalApproval = this.fb.group({
      approved: this.fb.control('', [Validators.required]),
      justification: this.fb.control('', [Validators.required]),
      final_contract_file_id: this.fb.control(null, [Validators.required]),
    });

    const urlParams = this.getParamsUrl(this.route);
    this.requisitionData.id = urlParams.id;
    if (urlParams.urlSegment && urlParams.urlSegment.path === 'view') {
      this.blockFields = true;
      this.isVisible = true;
    }
  }

  ngOnInit(): void {
    this.getCombos();
    this.getRequisitionAlterationDetails();
  }

  private getParamsUrl(route: ActivatedRoute) {
    let id;

    route.params.subscribe(params => {
      if (history.state.previousPage) {
        this.previousPage = history.state.previousPage
      }
      id = params['id'];
    });

    const urlSegment = route.url['_value'][1];

    return { id, urlSegment };
  }

  getRequisitionAlterationDetails() {
    if (this.requisitionData.id) {
      this.service.requisitionDetails(this.requisitionData.id).subscribe((responseRequisition: any) => {
        this.requisitionData = {...this.requisitionData, ...responseRequisition.data};
        
        if(this.isJson(this.requisitionData.totvs_integration_message)) {
          this.requisitionData.totvs_integration_message = JSON.parse(this.requisitionData.totvs_integration_message);
        }

        this.contract_file = responseRequisition.data.contract_file;
        this.final_contract_file = responseRequisition.data.final_contract_file;
        this.property_signature_file = responseRequisition.data.property_signature_file;
        this.blockFields = !!this.requisitionData.actual_step;
        this.service.alterationDetails(this.requisitionData.id).subscribe((response: any) => {
          this.contractData = response.data.contract;
          this.requisitionData.can_approve = response.data.can_approve;
          this.requisitionData.isDraft = response.data.draft;
          if (response.data.draft) {
            this.blockFields = false
            this.requisitionData.actual_step = 0
          }
          const { id, contract_code } = this.contractData;
          this.contractList.push({id, contract_code});
          this.setValuesInForm(this.mapAlterationDetails(response.data), false);
        });
      }, (responseError) => {
        this.notification.error('Um erro ocorreu', responseError.error.data);
      });
    }
  }

  getCombos() {
    if (this.contractList.length > 0) {
      return;
    }
    this.comboService.comboContractBankData().subscribe((response: any) => this.contractList = response.data);
    this.getComboPeoples();
    this.comboService.ComboArea().subscribe((response: any) => this.areasList = response.data);
    this.comboService.ComboBank().subscribe((response: any) => this.bankList = response.data);
    this.listApportionmentType = [
      { id: ApportionmentType.Porcentagem, desc: 'Porcentagem' },
      { id: ApportionmentType.Valor, desc: 'Valor' }
    ];
    this.accountTypeList = [
      { id: AccountType.Corrente, desc: 'Corrente' },
      { id: AccountType.Poupança, desc: 'Poupança' }
    ];

  }

  getComboPeoples() {
    this.comboService.ComboPerson().subscribe((response: any) => this.personsList = response.data);
  }

  getDetailsContract(event) {
    const id = this.form.value.contract_id;
    if (!event || !id) {
      return;
    }

    this.contract_beneficiaries.clear()
    
    this.contractService.getDetails(id).subscribe((response: any) => {
      this.contract_code = response.data.contract_code
      this.contract_number = response.data.contract_number
      this.contractData = response.data;
      this.contractData.total_value = response.data.pre_operation_value;
      this.setValuesInForm(response.data);
    });
  }

  getPersonDetails(newBeneficiaryId, apportionment_value) {
    this.personService.GetOnlyPerson(newBeneficiaryId).subscribe((response: any) => {
      this.addNewBeneficiaryByResponsePersonDetails(response, apportionment_value);
    });
  }

  isStep(stepNumber) {
    return this.requisitionData.actual_step === stepNumber;
  }

//#endregion upload/download Files

//#endregion

  removeNullInObj() {
    this.contract_beneficiaries.value.forEach((ben, i) => {
      Object.keys(ben).forEach(key => {
        if (!ben[key]) {
          delete this.contract_beneficiaries.value[i][key];
        }
      });
    });

    Object.keys(this.form.value).forEach(key => {
      if (this.form.value[key] == null || this.form.value[key].length == 0) {
        delete this.form.value[key];
      }
    });
  }

  back() {
    setTimeout(() => {
      if (this.previousPage != "") {
        this.router.navigate([this.previousPage]);
      } else {
        this.router.navigate(['/solicitacao/minhas-solicitacoes']);
      }
    });
  }

  cancel() {
    this.dialogService.confirm('Descartar dados', 'Deseja realmente cancelar? Todas alterações serão perdidas!', () => {
        this.service.cancelRequisition(this.requisitionData.id).subscribe(() => {
          this.notification.success('Solicitação cancelada com sucesso.', '');
          setTimeout(() => {
            this.back();
          });
      }, (responseError) => {
        this.notification.success(responseError.error.data, '');
      });
    });
  }

  save() {
    if (!this.form.invalid &&  this.sumOfPercentages == 100 || this.sumOfPercentages == 99.99 ) {
      this.removeNullInObj();
      this.requestSave(false);
    } else {
      Object.keys(this.form.controls).forEach((key: any) => {
        try {
          this.form.controls[key].markAsDirty();
          this.form.controls[key].updateValueAndValidity();
        } catch (error) {
          throw Error('Um erro Ocorreu.');
        }
      });
      this.notification.error('Confira os campos inválidos', '');
    }
  }

  saveDraft() {
    this.removeNullInObj();
    this.requestSave(true);
  }

  getValuesOfForm(isCreate = true) {
    const value = this.form.getRawValue();
    value.total_value = parseFloat(value.total_value);
    delete value.areas;
    delete value.newBeneficiary;

    if (isCreate) {
      delete value.justification;
    }

    value.contract_beneficiaries = value.contract_beneficiaries.map(this.mayPayloadContractBeneficiaries);

    return value;
  }

  resetBankDataInForm(result) {
    delete result.bank;
  }

  showErrorsInForm(form) {
    this.notification.error('Algo deu errado. Verifique os Campos Obrigatorios.', '');
    Object.keys(this[form].controls).forEach(key => {
      this[form].controls[key].markAsDirty();
      this[form].controls[key].updateValueAndValidity();
    });
  }

  submitStep(approved) {
    if (this.isStep(1)) {
      this.formEngineeringApproval.controls.approved.setValue(approved);
      if (!this.formEngineeringApproval.invalid) {
        const data = this.formEngineeringApproval.value;
        this.approval(data);
      } else {
        this.showErrorsInForm('formEngineeringApproval');
      }
    }
    if (this.isStep(2)) {
      this.formJuridicalApproval.controls.approved.setValue(approved);
      if (!this.formJuridicalApproval.invalid) {
        const data = this.formJuridicalApproval.value;
        this.approval(data);
      } else {
        this.showErrorsInForm('formJuridicalApproval');
      }
    }
    if (this.isStep(3)) {
      this.formRequesterApproval.controls.approved.setValue(approved);
      const date = new Date().toISOString();
      this.formRequesterApproval.controls.date.setValue(date);
      
      if (!this.formRequesterApproval.invalid) {
        const requesterApproval = this.formRequesterApproval.value;

        const data = requesterApproval.signature_date.split('/');

        requesterApproval.signature_date = new Date(data[2], data[1]-1, data[0]).toISOString();
        
        this.approval(requesterApproval);
      } else {
        this.showErrorsInForm('formRequesterApproval');
      }
    }
    if (this.isStep(4)) {
      this.formFinalApproval.controls.approved.setValue(approved);
      if (!this.formFinalApproval.invalid) {
        const data = this.formFinalApproval.value;
        this.approval(data);
      } else {
        this.showErrorsInForm('formFinalApproval');
      }
    }
  }

//#region save and approval
  approval(data) {
    this.service.approvalAlteration(data, this.requisitionData.id, this.requisitionData.actual_step).subscribe((response: any) => {
      this.notification.success('Passo ' + this.requisitionData.actual_step, response.message);
      this.router.navigateByUrl('/solicitacao/minhas-solicitacoes');
    });
  }

  requestSave(draft) {
    var value: any = this.getValuesOfForm();
    Object.keys(value).forEach( key => {
      if (value[key] == null) {
        delete value[key]
      }
    })

    value.contract_beneficiaries.forEach(element => {
      delete element.contracts
      delete element.person
    });

    if (value.contract_id) {
      if (this.requisitionData.id) {
        this.service.updateAlteration(value, this.requisitionData.id, draft).subscribe((response) => {
          setTimeout(() => {
            this.router.navigate(['/solicitacao/minhas-solicitacoes']);
          });
        }, (responseError) => {
          this.notification.error('Um erro ocorreu', responseError.error.data);
        });
      } else {
        this.service.save(value, draft).subscribe((response) => {
          setTimeout(() => {
            this.router.navigate(['/solicitacao/minhas-solicitacoes']);
          });
        }, (responseError) => {
          this.notification.error('Um erro ocorreu', responseError.error.data);
        });
      }
    } else {
      this.notification.warning("Selecione um contrato", "")
    }
  }

  savePerson() {
    /*
    if (!this.formPerson.invalid && this.registerPersonComponent.validateConjunge()) {
      const result = this.formPerson.getRawValue();
      this.mapResultPerson(result);
      if (this.registerPersonComponent.id) {
        const copyBank = { ...result.bank };
        this.resetBankDataInForm(result);
        this.personService.PutPerson(this.registerPersonComponent.id, result).subscribe((response: any) => {
          if (response.success === true) {
            this.updateRowBeneficiary(Object.assign({}, result.person, result.address, copyBank));
            this.getComboPeoples();
            this.hideRegisterPerson();
            this.formPerson.reset();
            this.beneficiaryEdit = null;
          } else {
            this.notification.blank(response.data, '');
          }
        }, (responseError) => {
          this.notification.error('Um erro ocorreu', responseError.error.data);
        });
      } else {
        this.personService.PostPerson(result).subscribe((response: any) => {
          if (response.success === true) {
            this.getComboPeoples();
            this.addNewBeneficiaryByResponsePersonDetails(response);
            this.hideRegisterPerson();
            this.formPerson.reset();
          } else {
            this.notification.blank(response.data, '');
          }
        }, (responseError) => {
          this.notification.error('Um erro ocorreu', responseError.error.data);
        });
      }
    } else {
      if (this.formPerson.invalid) {
        this.notification.error('Confira os campos obrigatórios', '');
      }
      Object.keys(this.formPerson.controls).forEach(key => {
        this.registerPersonComponent.validateForms(key);
      });
    }
    */
  }
//#endregion

  hideRegisterPerson() {
    this.registerPersonComponent.resetForm();
    this.modal.hideModal();
  }

  createNewPerson() {
    this.modal.showModal();
  }

//#region : function of table beneficiaries

  updateRowBeneficiary(form) {
    const formPerson = this.beneficiaryEdit.controls.person;
    const formBank = this.beneficiaryEdit.controls.bank;

    const personSelected = this.personsSelected.filter((personSelect) => personSelect.id === formPerson.value.id)[0];

    Object.keys(personSelected).forEach((key) => {
      if (form[key]) {
        personSelected[key] = form[key];
      }
    });

    Object.keys(personSelected.bank_data).forEach((key) => {
      if (form[key]) {
        personSelected.bank_data[key] = form[key];
      }
    });

    Object.keys(personSelected.address).forEach((key) => {
      if (form[key]) {
        personSelected.address[key] = form[key];
      }
    });

    Object.keys(formPerson.controls).forEach((key) => {
      if (form[key]) {
        formPerson.controls[key].setValue(form[key]);
      }
    });

    Object.keys(formBank.controls).forEach((key) => {
      if (form[key]) {
        formBank.controls[key].setValue(form[key]);
      }
    });
  }

  addNewBeneficiaryByResponsePersonDetails(response, apportionment_value = null) {
    response.data.apportionment_value = apportionment_value;
    this.personsSelected.push(response.data);
    response.data = this.mapPersonInBeneficiary(response.data);
    const beneficary = this.createBeneficiariesFormGroup(response.data);

    if (apportionment_value) {
      this.onChangeApportionmentValue(beneficary);
    }
    this.pushBeneficiaryInList(beneficary);
  }

  addNewBeneficiaryByResponseAlterationDetails(benefited) {
    benefited.gender = { id: benefited.gender_id };
    benefited.marital_status = { id: benefited.marital_status_id };
    benefited.state = { id: benefited.state_id };
    benefited.city = { id: benefited.city_id };
    benefited.bank = { id: benefited.bank_id };

    this.personsSelected.push(benefited);
    const newBenefited = this.mapPersonResponseAlterationInBeneficiary(benefited);

    const beneficary = this.createBeneficiariesFormGroup(newBenefited);

    if (newBenefited.apportionment_value) {
      this.onChangeApportionmentValue(beneficary);
    }
    this.pushBeneficiaryInList(beneficary);
  }

  enableFieldTable(ApportionmentTypeId) {
    return this.form.value.beneficiary_apportionment_type === ApportionmentTypeId;
  }

  getTextToBeneficiary(beneficiary, prop) {
    const personSelected = this.personsSelected.filter((person) => person.id === beneficiary.value.person_id)[0];

    switch (prop) {
      case 'name':
        return personSelected.name;
      case 'bank':
        return this.bankList.filter((bank) => bank.id === beneficiary.value.bank_id)[0].desc;
      case 'cpf':
        return personSelected.cpf;
      case 'type_person':
        return personSelected.job;
      default:
        break;
    }
  }

  startEdit(beneficiaryGroup, index): void {
    this.beneficiaryEdit = beneficiaryGroup;
    this.personDetails = this.personsSelected[index];
    //this.registerPersonComponent.setValues({data: this.personDetails });
    this.createNewPerson();
    this.chooseShowPopover();
  }

  removeRow(index): void {
    this.contract_beneficiaries.removeAt(index);
    this.beneficiaryEdit = null;
    this.chooseShowPopover();
  }

  getBankById(id: number) {
    if (!id || this.bankList.length === 0) {
      return { des: ' - ' };
    }
    return this.bankList.find((bankItem) => {
      return bankItem.id === id;
    });
  }

  getAccountById(id: AccountType) {
    return this.accountTypeList.find((accountItem) => {
      return accountItem.id === id;
    }) || { desc: ' - '};
  }

  chooseShowPopover() {
    this.showPopover = false;
    setTimeout(() => {
      this.showPopover = true;
    });
  }

//#endregion

//#region apportiment of beneficiaries
  getTotalApportionmentValue(): number {
    return this.contract_beneficiaries.getRawValue().reduce((sum, item) => {
      return sum + parseFloat(item.apportionment_value || 0);
    }, 0);
  }

  getTotalApportionmentPercent() {
    let total = 0
    this.contract_beneficiaries.value.forEach( item => {
      total += parseFloat(item.apportionment_percent)
    });
    return total.toFixed(2)
  }

  changeEqualApportionment() {
    if (this.beneficiary_equal_apportionment.value) {
      this.updateBeneficiaryApportionmentList();
    } else {
      this.contract_beneficiaries.enable();
    }
    this.sumOfPercentages = this.getTotalApportionmentPercent()
  }

  changeApportionmentType() {
    if (this.beneficiary_equal_apportionment.value) {
      return;
    }

    let valueField = 'enable';
    let percentField = 'disable';

    if (this.beneficiary_apportionment_type.value === ApportionmentType.Porcentagem) {
      valueField = 'disable';
      percentField = 'enable';
    }

    this.contract_beneficiaries.controls.forEach((beneficiaryControl) => {
      beneficiaryControl.get('apportionment_value')[valueField]();
      beneficiaryControl.get('apportionment_percent')[percentField]();
    });
  }

  updateBeneficiaryApportionmentList(): void {
    if (this.beneficiary_equal_apportionment.value) {
      this.contract_beneficiaries.controls.forEach((item) => {
        item.get('apportionment_value').setValue((this.total_value.value / this.contract_beneficiaries.controls.length).toFixed(5).replace('.', ','));
        item.get('apportionment_percent').setValue((100 / this.contract_beneficiaries.controls.length).toFixed(5));
        this.sumOfPercentages = this.getTotalApportionmentPercent()
      });
    } else {
      this.contract_beneficiaries.controls.forEach((item) => {
        item.get('apportionment_value').setValue(0);
        item.get('apportionment_percent').setValue(0);
        this.sumOfPercentages = this.getTotalApportionmentPercent()
      });
    }

  }

  onChangeApportionmentValue(beneficiaryApportionment: AbstractControl): void {
    var total_value = this.form.controls.total_value.value
    var apportionmentvalue =  beneficiaryApportionment.get('apportionment_value').value
    var apportionmentpercent = (total_value / 100) * apportionmentvalue
    const percent = apportionmentpercent;
    if (percent > 100) {
      beneficiaryApportionment.get('apportionment_percent').setValue(Math.round(percent * 10));
    } else {
      beneficiaryApportionment.get('apportionment_percent').setValue(Math.round(percent));
    }
  }

  onChangeApportionmentPercent(field, beneficiaryApportionment: AbstractControl): void {
    this.sumOfPercentages = this.getTotalApportionmentPercent()

    if (field == 'percent') {
      if (this.sumOfPercentages > 100) {
        beneficiaryApportionment.get('apportionment_value').setValue(0)
        beneficiaryApportionment.get('apportionment_percent').setValue(0)
        this.sumOfPercentages = this.getTotalApportionmentPercent()
        this.notification.warning("","A soma dos valores ou prorcentagens de rateio não podem ultrapassar o valor total de rateio ou 100%")
      }
      const value = beneficiaryApportionment.get("apportionment_percent").value * (this.total_value.value / 100)
      beneficiaryApportionment.get('apportionment_value').setValue(value.toFixed(5).replace('.', ','));

    } else {
      const value = parseFloat(beneficiaryApportionment.get("apportionment_value").value) / (this.total_value.value / 100)
      beneficiaryApportionment.get('apportionment_percent').setValue(value.toFixed(5).replace('.', ','));
    }

  }

  calcHectareTotalContract(): number {
    let hectare_total_contract = 0;

    this.areasDetails.forEach(area => {
      const contract_hectare = parseFloat(area.get('contract_hectare').value || 0);
      hectare_total_contract += contract_hectare;
    });

    return hectare_total_contract;
  }
//#endregion

//#region form controls and maps
  get contract_beneficiaries(): FormArray {
    return this.form.get('contract_beneficiaries') as FormArray;
  }

  get total_value(): AbstractControl {
    return this.form.get('total_value');
  }

  get beneficiary_equal_apportionment(): AbstractControl {
    return this.form.get('beneficiary_equal_apportionment');
  }

  get beneficiary_apportionment_type(): AbstractControl {
    return this.form.get('beneficiary_apportionment_type');
  }

  get areas(): FormArray {
    return this.form.get('areas') as FormArray;
  }

  addBeneficiary() {
    this.form.controls.beneficiary_equal_apportionment.setValue(false)
    this.form.controls.beneficiary_equal_apportionment.setValue(true)
    const newBeneficiaryId = this.form.value.newBeneficiary;

    if (newBeneficiaryId && this.contract_beneficiaries.value.every((beneficiary) => beneficiary.person_id !== newBeneficiaryId)) {
      this.getPersonDetails(newBeneficiaryId, null);
    }
  }

  pushBeneficiaryInList(beneficary) {
    this.contract_beneficiaries.push(beneficary);
    this.form.controls.newBeneficiary.setValue(null);
  }

  createBeneficiariesFormGroup(data) {
    return this.fb.group({
      person: this.fb.group({
        id: this.fb.control(data.person.id, [Validators.nullValidator]),
        name: this.fb.control(data.person.name, [Validators.nullValidator]),
        cpf: this.fb.control(data.person.cpf, [Validators.nullValidator]),
        rg: this.fb.control(data.person.rg, [Validators.nullValidator])
      }),
      bank: this.fb.group({
        bank_id: this.fb.control(data.bank.bank_id, [Validators.nullValidator]),
        account_type: this.fb.control(data.bank.account_type, [Validators.nullValidator]),
        account: this.fb.control(data.bank.account, [Validators.nullValidator]),
        agency: this.fb.control(data.bank.agency, [Validators.nullValidator])
      }),
      apportionment_value: this.fb.control(data.apportionment_value, [Validators.required]),
      apportionment_percent: this.fb.control(null, [Validators.required]),
    });
  }

  mayPayloadContractBeneficiaries = (data) => {
    let response: any = {
      person_id: data.person.id,
      contract_person_type: 2,
    };

    if (data.apportionment_value !== '') {
      response['apportionment_value'] = parseFloat(data.apportionment_value);
    } else {
      response['apportionment_value'] = parseFloat(data.apportionment_percent);
    }

    let personDetails
    this.personsSelected.forEach( person => {
      let personid = person.person_id ? person.person_id : person.id
      if (personid === response.person_id) {
        personDetails = person;
      }
    })

    personDetails = { ...personDetails.bank_data, ...personDetails.address, ...personDetails};
    delete personDetails.address;
    delete personDetails.bank_data;
    delete personDetails.marital_status;
    delete personDetails.bank;
    delete personDetails.gender;
    delete personDetails.spouse;
    delete personDetails.city;
    delete personDetails.state;

    response = { ...personDetails, ...response };

    var existsInContract = this.thisInThiscontract(response.contracts)
    if (existsInContract.contract_code != null) {
      response = { ...response, ...existsInContract}
    }


    delete response.id;

    Object.keys(response).forEach( key => {
      if (response[key] == null || key === 'apportionment_percent') {
        delete response[key];
      }
    });

    return response;
  }

  mapResultPerson(result) {
    if (result.address.complement == null) {
      delete result.address.complement;
    }
    delete result.person.isalive;
    delete result.person.isdeath;

    if (result.person.deceased === '1') {
      result.person.deceased = true;
    } else {
      result.person.deceased = false;
    }
    const dateTypeCorrect = moment(result.person.birth_date.replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD');
    if (result.person.deceased_date != null && result.person.deceased === true) {
      result.person.deceased_date = moment(result.person.deceased_date.replace(/-/g, ''), 'DDMMYYYY').format('YYYY-MM-DD');
    } else {
      delete result.person.deceased_date;
    }
    result.person.birth_date = dateTypeCorrect;

    if (result.person.marital_status_id === 3) {
      result.person.spouse_id = null;
    }

    if (result.person.spouse_id == null) {
      delete result.person.spouse_id;
    }
  }

  mapResponsePerson(data: any): any {
    return {
      id: data.person_id ? data.person_id : data.id,
      name: data.name,
      cpf: data.cpf,
      rg: data.rg,
      issuing_agency: data.issuing_agency,
      birth_date: data.birth_date,
      nationality: data.nationality,
      telephone: data.telephone,
      cellphone: data.cellphone,
      gender_id: data.gender.id,
      marital_status_id: data.marital_status.id,
      job: data.job,
      spouse_id: data.spouse_id
    };
  }

  mapResponseAddress(data: any): any {
    return {
      zip_code: data.zip_code,
      neighborhood: data.neighborhood,
      state_id: data.state.id,
      city_id: data.city.id,
      number: data.number,
      complement: data.complement,
      public_place: data.public_place
    };
  }

  mapResponseBank(data: any): any {
    return {
      bank_id: data.bank.id,
      account_type: data.AccountType ? AccountType.Corrente : AccountType.Poupança,
      account: data.account,
      agency: data.agency
    };
  }

  mapPersonInBeneficiary(data) {
    return {
      person: data ? this.mapResponsePerson(data) : null,
      address: data && data.address ? this.mapResponseAddress(data.address) : null,
      bank: data && data.bank_data ? this.mapResponseBank(data.bank_data) : null,
    };
  }

  mapPersonResponseAlterationInBeneficiary(data) {
    return {
      person: this.mapResponsePerson(data),
      address: this.mapResponseAddress(data),
      bank: this.mapResponseBank(data),
      apportionment_value: data.apportionment_value
    };
  }

  mapAlterationDetails(data) {
    return {
      beneficiary_apportionment_type: data.beneficiary_apportionment_type,
      beneficiary_equal_apportionment: data.beneficiary_equal_apportionment,
      total_value: data.total_value,
      contract_id: data.contract_id,
      beneficiaries: data.alteration_person_bank_data,
      contract_areas: data.contract.contract_areas
    };
  }

  thisInThiscontract(contracts) {
    var existsinContract = false
    contracts.forEach(contract => {
      if (contract.contract_code == this.contract_code) {
        existsinContract = true
      }
    });

    if (existsinContract) {
      return {'contract_number': this.contract_number, 'contract_code': this.contract_code}
    }
    
    return {'contract_number': null, 'contract_code': null}
  }


  setBeneficiaries(beneficiaries) {
    beneficiaries.forEach((benefited) => {
      this.addNewBeneficiaryByResponseAlterationDetails(benefited);
    });
  }

  setBeneficiariesByPersonDetails(beneficiaries) {
    beneficiaries.forEach((benefited) => {
      this.getPersonDetails(benefited.person_id, benefited.apportionment_value);
    });
  }

  getAreasIdByCode(areas) {
    return this.areasList.filter((area, i) => {
      if (areas.map((areaSelected) => areaSelected.area_id).includes(area.id)) {
        return area.id;
      }
    }).map((area) => area.id);
  }

  setValuesInForm(data, isCreate = true) {
    Object.keys(this.form.controls).forEach((key) => {
      if (key && key !== 'contract_beneficiaries' &&  key !== 'contract_id' &&  key !== 'areas') {
        this.form.get('beneficiary_equal_apportionment').valueChanges.subscribe( value => {
          setTimeout(() => {
            this.changeEqualApportionment();
          }, 3000);
        })
        this.form.controls[key].setValue(data[key]);
      }
    });
    if (data.contract_areas.length > 0) {
      this.areasDetails = data.areas;
      this.form.controls.areas.setValue(this.getAreasIdByCode(data.contract_areas));
    }

    if (isCreate) {
      this.setBeneficiariesByPersonDetails(data.beneficiaries);
    } else {
      this.form.controls.contract_id.setValue(data.contract_id);
      this.setBeneficiaries(data.beneficiaries);
    }
  }

  setFieldNameUpload(fieldName: string): void {
    this.fieldNameUpload = fieldName;
  }

  beforeUploadAnexo = (file: any): boolean => {
    const formData = new FormData();

    if(this.fieldNameUpload == 'contract_file' || this.fieldNameUpload == 'property_signature_file' || this.fieldNameUpload == 'final_contract_file') {
      formData.append('file', file);
      formData.append('column', this.fieldNameUpload);

      this.service.uploadAnexo(formData, this.requisitionData.id).subscribe((response: any) => {
        if(this.fieldNameUpload == 'contract_file') {
          file['uuid_file'] = response.uuid;
          this.attachment_file_2 = [file];
          this.formJuridicalApproval.controls.contract_file_id.setValue(response.id);
        }
        if(this.fieldNameUpload == 'property_signature_file') {
          file['uuid_file'] = response.uuid;
          this.attachment_file_3 = [file];
          this.formRequesterApproval.controls.property_signature_file_id.setValue(response.id);
        }
        if(this.fieldNameUpload == 'final_contract_file') {
          file['uuid_file'] = response.uuid;
          this.attachment_file_4 = [file];
          this.formFinalApproval.controls.final_contract_file_id.setValue(response.id);
        }
      });
    }
    return false;
  }

  getAtachmentName(fileName) {
    return this[fileName].filename
  }

  handlePreview = (file: any) => {
    this.viewArchive(file);
  }

  viewArchive(file) {
    this.service.downloadFile(file.filename, file.uuid_file).subscribe((response: any) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    }, error => {
      this.notification.error("erro ao baixar arquivo", '');
    });
  }


  viewAttachment(fileName) {
    this.service.downloadFile(this[fileName].filename, this[fileName].uuid_file).subscribe(response => {
      const file = new Blob([response], {type: 'application/pdf'});
      const fileurl = URL.createObjectURL(file);
      window.open(fileurl);
    });
  }
//#endregion

  reenviarDados() {
      this.alteracaoDadosBancariosService.reintegrate(this.requisitionData.id).subscribe((res: any) => {
        if(res.success) {
          this.notification.success(res.message, '');
        }
      })
  }

  isJson(string) {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
  }
}
