import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { AccountingEntryService } from 'src/app/orcamento/services/accounting-entry.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ComboService } from 'src/app/shared/services/combo.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ContractService } from '../../services/contract.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NovoContratoService } from 'src/app/solicitacao/services/novo-contrato.service';
declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-listagem-contratos',
  templateUrl: './listagem-contratos.component.html',
  styleUrls: ['./listagem-contratos.component.scss']
})
export class ListagemContratosComponent implements OnInit {
  @ViewChild('modal', { static: true }) modalComponent: ModalComponent;
  dataRow: any = { };
  pageSelected = 1;
  totalItens = null;

  contractsList = [];
  typeStatusList = [];
  listPayments = []

  filters = {
    contract_code: '',
    status_id: null
  };

  filters_clone = {
    contract_code: '',
    status_id: null
  };

  constructor(private router: Router,
              private service: ContractService,
              private contractService: NovoContratoService,
              private comboService: ComboService,
              private dialogService: DialogService,
              private notification: NzNotificationService,
              private permissionService: PermissionService,
              private accountingEntryService: AccountingEntryService) { }

  ngOnInit(): void {
    this.comboService.comboContractStatus().subscribe((response: any) => this.typeStatusList = response.data);
    this.searchContracts();
  }

  searchContracts() {
    this.removeNullInFilter();
    if(this.filters_clone.contract_code !== this.filters.contract_code || this.filters_clone.status_id !== this.filters.status_id)
    {
      this.pageSelected = 1;
      this.filters_clone.contract_code = this.filters.contract_code;
      this.filters_clone.status_id = this.filters.status_id
    }

    this.service.getAllContracts({ page: this.pageSelected }, this.filters).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.contractsList = response.data;
        this.totalItens = response.total;
      } else {
        this.contractsList = [];
        this.totalItens = 0;
        this.pageSelected = 1;
        this.notification.blank('Contrato não encontrado', '');
      }
    });
  }

  getCodeAreas(areas) {
    return areas.map(area => area.code).toString().replaceAll(',', ' ');
  }

  setPage(page) {
    this.pageSelected = page;
    this.searchContracts();
  }

  getDataRow(data) {
    this.dataRow = data;
  }

  removeNullInFilter() {
    Object.keys(this.filters).forEach((key) => {
      if (!this.filters[key]) {
        delete this.filters[key];
      }
    });
  }

  downloadCSV() {
    this.service.dowloadCSV().subscribe( (response: any) => {
      const csvName = 'Relatorio_contratos.csv';
      const blob = new Blob(["\ufeff", response]);
      FileSaver.saveAs(blob, csvName);
    });
  }

  checkPermission(operation) {
    return this.permissionService.checkPermission('contract', operation);
  }

  viewContract() {
    this.router.navigate(['/solicitacao/novo-contrato/view-contract/' + this.dataRow.id]);
  }

  startBilling(contract) {

    this.service.startBilling(contract).subscribe( (response: any) => {
      //this.notification.info(response.message, '');
    });
  }

  endBilling(contract) {
    this.service.endBilling(contract).subscribe( (response: any) => {
      //this.notification.info(response.message, '');
    });
  }

  addContract() {
    this.router.navigate(['/solicitacao/novo-contrato']);
  }

  addAditivo() {
    this.router.navigate(['/solicitacao/aditivo-contratual/' + this.dataRow.id]);
  }

  addResilicao() {
    this.router.navigate(['/solicitacao/resilicao/' + this.dataRow.id]);
  }

  irParaAprovacao()
  {
    this.router.navigate(['/solicitacao/novo-contrato/' + this.dataRow.id]);
  }

  hideModal() {
    this.modalComponent.hideModal();
  }

  getPasso(data) {
    if (data.actual_step == 1) return "Comercial: Aprovação do líder";
    if (data.actual_step == 2) return "Jurídico: Elaboração do contrato e validação de documentos";
    if (data.actual_step == 3) return "Fundiário: Resgate das assinaturas dos proprietários";
    if (data.actual_step == 4) return "Jurídico: Aprovação final";
    if (data.actual_step == 5) return "Jurídico - Arquivamento";
    if (data.actual_step == 6) return "Finalizado";
  }


  getListInvoices() {
    this.listPayments = [];

    //TODO
    //alterar para atributo people?
    //dentro do atributo people, não há id de contrato. Método abaixo requer isso.

    this.dataRow.beneficiaries.forEach((beneficiary) => {
      this.accountingEntryService.getAccountingEntryByContractId(beneficiary._totvs_contract_id).subscribe((payments: any) => {
        this.listPayments = this.listPayments.concat({...payments.data, name: beneficiary.name});
      })
    })

  }

  cancel(): void {
    this.dialogService.confirm('Descartar dados', 'Deseja realmente apagar o rascunho? Todas alterações serão perdidos!', () => {
      this.contractService.cancelRequisition(this.dataRow.id).subscribe(() => {
        this.searchContracts();
      }, (responseError) => {
        this.notification.error(responseError.error.data, '');
      });
    });
  }

  viewPayments() {
    this.modalComponent.showModal();
    this.getListInvoices()
  }
}
