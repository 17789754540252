import { Component, OnInit } from '@angular/core';
import { ComboService } from 'src/app/shared/services/combo.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { SolicitacaoService } from '../../services/solicitacao.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { RequisitionType } from 'src/app/shared/enums/requisition-type.enum';
import { StatusIntegracao } from 'src/app/shared/enums/status.enum';
const FileSaver = require('file-saver');

moment.locale('pt-br');

@Component({
  selector: 'app-listagem-solicitacao',
  templateUrl: './listagem-solicitacao.component.html',
  styleUrls: ['./listagem-solicitacao.component.scss']
})
export class ListagemSolicitacaoComponent implements OnInit {
  dataRow: any;
  valor: boolean;

  typedata:any;
  iddata:any;

  showHistoric = false;

  page = 1;
  totalItens: number;
  historic: any;
  totalPerStatusPendent: any;
  totalPerStatusApproved: any;
  totalAreas: any;
  number: any;
  contract: any;
  showpopupoptions = false;
  type;
  status;
  situation;

  number_clone: any;
  contract_clone: any;
  type_clone;
  status_clone;
  situation_clone;

  listRequisition: any;
  listSituation: any;
  listStatus: any;
  listTypeRequisition: any;

  StatusIntegracao = StatusIntegracao;


  constructor(private serviceShared: ComboService,
              private service: SolicitacaoService,
              private notification: NzNotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.service.GetAllRequisition(1, this.GetFilter).subscribe((response: any) => {
      this.listRequisition = response.data;
      this.totalItens = response.total;
    });

    this.service.GetAreasAvaliable().subscribe((response: any) => {
      this.totalAreas = response.data.total_areas;
    });

    this.service.GetTotalRequisition().subscribe((response: any) => {
      this.totalPerStatusApproved = response.data.approved,
      this.totalPerStatusPendent = response.data.pendent;
    });

    this.serviceShared.ComboTypeRequisition().subscribe((response: any) => {
      this.listTypeRequisition = response.data;
    });

    this.serviceShared.ComboRequisitionSituation().subscribe((response: any) => {
      this.listSituation = response.data;
    });

    this.serviceShared.ComboRequisitionState().subscribe((response: any) => {
      this.listStatus = response.data;
    });

  }

  SearchRequisition() {

    const filter = this.GetFilter();
    const filter_clone = this.GetFilterClone();

    if(filter_clone.number !== filter.number
      || filter_clone.contract !== filter.contract
      || filter_clone.type !== filter.type
      || filter_clone.status !== filter.status
      || filter_clone.situation !== filter.situation
    )
    {
      this.page = 1;
      this.number_clone = this.number;
      this.contract_clone = this.contract;
      this.type_clone = this.type;
      this.status_clone = this.status;
      this.situation_clone = this.situation;
    }

    const page = this.GetPage();

    this.service.GetAllRequisition(page, filter).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listRequisition = response.data;
        this.totalItens = response.total;
      } else {
        this.listRequisition = [];
        this.totalItens = 0;
        this.page = 1;
        this.notification.blank('Solicitação não encontrada', '');
      }
    });
  }

  GetPage() {
    return {
      page: this.page
    };
  }

  GetFilter() {
    return {
      number: this.number,
      contract: this.contract,
      type: this.type,
      status: this.status,
      situation: this.situation
    };
  }

  GetFilterClone() {
    return {
      number: this.number_clone,
      contract: this.contract_clone,
      type: this.type_clone,
      status: this.status_clone,
      situation: this.situation_clone
    };
  }

  getDataRow(data) {
    this.dataRow = data;
  }

  downloadCSV() {
    this.service.GetReport().subscribe((response: any) => {
      const csvName = 'Relatorio_solicitacoes.csv';
      const blob = new Blob(['\ufeff', response]);
      FileSaver.saveAs(blob, csvName);
    });
  }

  GetHistoric(id) {
    this.valor = !this.valor;
    this.historic = id;
    this.getDataRow([])
  }

  setPage(page) {
    this.page = page
    this.SearchRequisition();
  }

  RequisitionDetail(id, type) {
    if (type === RequisitionType.NOVO_CONTRATO) {
      this.router.navigate(['/solicitacao/novo-contrato/view/' + id], {state: { previousPage: '/admin/listagem-solicitacao' }});
    }
    if (type === RequisitionType.ADITIVO_CONTRATUAL) {
      this.router.navigate(['/solicitacao/aditivo-contratual/view/' + id], {state: { previousPage: '/admin/listagem-solicitacao' }});
    }
    if (type === RequisitionType.ALTERACAO_DE_DADOS_BANCARIOS) {
      this.router.navigate(['/solicitacao/alteracao-dados-bancarios/view/' + id], {state: { previousPage: '/admin/listagem-solicitacao' }});
    }
    if (type === RequisitionType.RESILICAO) {
      this.router.navigate(['/solicitacao/resilicao/view/' + id], {state: { previousPage: '/admin/listagem-solicitacao' }});
    }
    if (type === RequisitionType.REPLANEJAMENTO) {
      this.router.navigate(['/solicitacao/replanning/' + id], {state: { previousPage: '/admin/listagem-solicitacao' }});
    }
  }
}
